import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgVideoSlashBold: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M14.782 6.309a.678.678 0 0 1 0 .183c0-.058-.009-.117-.009-.175l.009-.008Z"
        fill="#fff"
      />
      <path
        d="M14.4 5.466 3.19 16.675c-1.166-.742-1.625-2.067-1.625-3.342V6.666c0-2.85 1.109-3.958 3.959-3.958h5c2.408 0 3.566.792 3.875 2.758Z"
        fill="#fff"
      />
      <path
        d="M17.835 1.858a.644.644 0 0 0-.909 0L1.543 17.242a.644.644 0 0 0 0 .908.675.675 0 0 0 .45.183.632.632 0 0 0 .45-.191L17.835 2.758c.25-.25.25-.65 0-.9ZM18.65 6.983v6.034c0 1.191-.583 1.666-.916 1.841a1.554 1.554 0 0 1-.717.175c-.358 0-.8-.116-1.316-.483l-1.234-.867c-.058 1.842-.641 2.892-1.966 3.334-.534.191-1.192.275-1.984.275h-5c-.2 0-.391-.009-.591-.034L12.5 9.692l4.708-4.709c.217.017.392.084.525.159.333.175.917.65.917 1.841Z"
        fill="#fff"
      />
    </svg>
  );
};

const Memo = memo(SvgVideoSlashBold);
export default Memo;
