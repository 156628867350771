import { NotificationsResponseDto } from '@app/api';
import { createAction } from '@reduxjs/toolkit';
import { ActionType } from './types';

export const setNotifications = createAction<NotificationsResponseDto>(
  ActionType.setNotifications,
);

export const addTherapistsNote = createAction<void>(
  ActionType.addTherapistsNote,
);
