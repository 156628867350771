import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import {
  BookSessionByTherapistRequestDto,
  BookSessionRequestDto,
  MessageSuccessResponseDto,
  UpcomingSessionPaymentInfoResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

interface GetUpcomingSessionPaymentInfoParams {
  availabilityId: string;
}

interface BookSessionV2Params {
  availabilityId: string;
  promocode?: string;
  withNewCard: boolean;
  saveCard: boolean;
  foreigner: boolean;
}

export const sessionPaymentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpcomingSessionPaymentInfo: builder.query<
      UpcomingSessionPaymentInfoResponseDto,
      GetUpcomingSessionPaymentInfoParams
    >({
      query: ({ availabilityId }) => ({
        url: API_URL.getUpcomingSessionPaymentInfo(availabilityId),
        method: HttpMethods.get,
      }),
      providesTags: ['sessionPayment'],
    }),
    bookSession: builder.mutation<
      MessageSuccessResponseDto,
      BookSessionRequestDto
    >({
      query: (payload) => ({
        url: API_URL.bookSession,
        method: HttpMethods.post,
        body: payload,
      }),
      invalidatesTags: ['clientActiveSessions', 'therapistActiveSessions'],
    }),
    bookSessionV2: builder.mutation<
      MessageSuccessResponseDto,
      BookSessionV2Params
    >({
      query: (payload) => ({
        url: API_URL.bookSessionV2,
        method: HttpMethods.post,
        body: payload,
      }),
    invalidatesTags: ['clientActiveSessions', 'therapistActiveSessions', 'therapistAvailabilities'],
    }),
    bookSessionByTherapist: builder.mutation<
      MessageSuccessResponseDto,
      BookSessionByTherapistRequestDto
    >({
      query: (payload) => ({
        url: API_URL.bookSessionByTherapist,
        method: HttpMethods.post,
        body: payload,
      }),
      // invalidatesTags: ['clientActiveSessions', 'therapistActiveSessions'],//??
    }),
  }),
});

export const {
  useGetUpcomingSessionPaymentInfoQuery,
  useBookSessionMutation,
  useBookSessionByTherapistMutation,
  useBookSessionV2Mutation,
} = sessionPaymentApi;
