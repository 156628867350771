export const ERROR_TEXTS = {
  required: 'Обязательное поле',
  email: 'Неверный формат e-mail',
  phone: 'Неверный формат телефона',
  confirmationPassword: 'Пароли не совпадают',
  checkPassword: 'Пароль указан неверно',
  invalidCode: 'Введён неверный код',
  onlyNumbers: 'Поле может содержать только положительные цифры',
  invalidTime: 'Вы неправильно ввели время. К примеру, "09:00"',
  sessionsInterval: 'Интервал между сессиями не может быть меньше 5 минут',
  manyYears: 'Вам так много лет?',
  onlyRussianLettersWithDash: 'Может содержать русские буквы и дефис',
  under18: 'Вам должно исполниться 18 лет',
  emailIsNotAvailable: 'Такой email уже занят',
  phoneIsNotAvailable: 'Такой телефон уже занят',
  phoneIsAvailable: 'Пользователя с таким номером не существует',
  maxNumber: (value: number) => `Максимальное значение - ${value}`,
  maxPriceSession: (value: number) =>
    `Максимальная стоимость консультации - ${value} руб`,
  minCount: (value: number) => `Выберите хотя бы ${value} пункт`,
  minPriceForTherapist: 'Стоимость консультации не может быть меньше 2000 руб',
  maxFileSize: 'Превышен максимальный размер файла',
  quantityPromoCodes: 'Количество промокодов не может быть меньше 1',
  integer: 'Только целые числа',
  promocodeNotFound: 'Промокод не найден',
  promocodeExpired: 'Срок действия промокода истек',
  promocodeLength: 'Длина промкода не может превышать 20 символов',
  phoneFormat: 'Неверный или неполный формат телефона',
  usernameLength: 'Длина имени не должна превышать 40 символов',
  videoFormat:
    'Неверный формат видео. Если не получается загрузить видео, обратитесь, пожалуйста, в поддержку',
  videoSize:
    'Неверный размер файла. Если не получается загрузить видео, обратитесь, пожалуйста, в поддержку',
};
