import { UserSignUpDtoRoleEnum } from '@app/api';

export enum ActionType {
  signOut = 'auth/signOut',
  setToken = 'auth/setToken',
  setUser = 'auth/setUser',
  setUserWithMetadata = 'auth/setUserWithMetadata',
  setUserWithToken = 'auth/setUserWithToken',
  setUserWithTokenAndMetadata = 'auth/setUserWithTokenAndMetadata',
  setSignUpContext = 'auth/setSignUpContext',
  setPasswordRecoveryContext = 'auth/setPasswordRecoveryContext',
  logout = 'auth/logout',
  unauthorized = 'auth/unauthorized',

  postSignIn = 'auth/postSignIn',
  postSignUp = 'auth/postSignUp',
  postPhoneVerification = 'auth/postPhoneVerification',
  postPhoneCheckVerification = 'auth/postPhoneCheckVerification',
  postEmailSendConfirmation = 'auth/postEmailSendConfirmation',

  postCheckEmail = 'auth/postCheckEmail',
  postCheckPhone = 'auth/postCheckPhone',

  loginRole = 'auth/loginRole',
  setModalActiveTab = 'auth/setModalActiveTab',
  setAuthModalState = 'auth/setAuthModalState',
}

export interface SignUpContext {
  type: 'phone' | 'email';
  value: string;
  role: UserSignUpDtoRoleEnum;
  code?: string;
  mode?: 'normal' | 'modal';
  secondsToSendNextVerificationCode?: number;
  hasPassword?: boolean;
}
export interface Modals {
  authModalActiveView: string;
  isAuthModalOpen: boolean;
}

export interface PasswordRecoveryContext {
  type: 'phone' | 'email';
  value: string;
  code?: string;
}
