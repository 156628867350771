import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgLightning: FC<IconComponent> = ({
  color = COLORS.gray1,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={17}
      viewBox="0 0 13 17"
      fill="none"
      {...props}
    >
      <path
        d="M7.79238 0.0855591C7.48441 -0.021782 7.15127 -0.0282825 6.83951 0.0669653C6.52774 0.162213 6.25292 0.354452 6.05343 0.616827L0.326338 8.05458C0.148596 8.28912 0.038491 8.5696 0.00839967 8.86449C-0.0216917 9.15938 0.0294242 9.457 0.156001 9.72387C0.282577 9.99074 0.479596 10.2163 0.72491 10.3752C0.970224 10.5341 1.25411 10.62 1.54465 10.6233H4.14787V15.4047C4.14756 15.7363 4.24865 16.0598 4.43706 16.3301C4.62548 16.6003 4.89182 16.8039 5.19897 16.9124C5.50613 17.0208 5.83879 17.0288 6.15061 16.9352C6.46243 16.8416 6.73786 16.6511 6.93852 16.3902L12.6656 8.95242C12.8469 8.71726 12.9597 8.43476 12.9911 8.13726C13.0224 7.83975 12.971 7.53925 12.8428 7.27014C12.7146 7.00103 12.5148 6.77417 12.2661 6.61554C12.0175 6.4569 11.7301 6.37289 11.4369 6.37312H8.83367V1.5917C8.83438 1.26152 8.73457 0.939259 8.54804 0.669454C8.36151 0.39965 8.09746 0.19562 7.79238 0.0855591ZM11.4369 7.96692L5.7098 15.4047V9.02946H1.54465L7.27174 1.5917V7.96692H11.4369Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgLightning);
export default Memo;
