import { VARS } from '@app/constants';
import { setCookie, parseCookies, destroyCookie } from 'nookies';

export const setValueToCookie = (
  name: string,
  value: string,
  options?: { path?: string; domain?: string },
): void => {
  setCookie(null, name, value, options);
};

export const getValueFromCookie = (name: string): string | undefined => {
  const cookie = parseCookies();

  return cookie[name];
};

export const deleteValueFromCookie = (name: string) => {
  destroyCookie(null, name, {
    path: '/',
    domain: VARS.domain,
  });
};
