import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import {
  CreateTherapistProfileRequestDto,
  PublicTherapistResponseDto,
  TherapistProfileUpdateResponseDto,
  TherapistProfileUpdatesResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

export const therapistProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTherapistProfile: builder.query<PublicTherapistResponseDto, void>({
      query: () => ({
        url: API_URL.getTherapistProfile,
        method: HttpMethods.get,
      }),
      providesTags: ['profile'],
    }),
    getTherapistProfileUpdates: builder.query<
      TherapistProfileUpdatesResponseDto,
      void
    >({
      query: () => ({
        url: API_URL.getTherapistProfileUpdate,
      }),
      providesTags: ['updates'],
    }),
    deleteTherapistProfileUpdate: builder.mutation<
      TherapistProfileUpdateResponseDto,
      string
    >({
      query: (updateId: string) => ({
        url: API_URL.deleteTherapistProfileUpdate(updateId),
        method: HttpMethods.delete,
      }),
      invalidatesTags: ['updates'],
    }),
    updateTherapistProfile: builder.mutation<
      TherapistProfileUpdateResponseDto,
      any
    >({
      query: (body: any) => ({
        url: API_URL.postTherapistProfile,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['updates'],
    }),

    createTherapistProfile: builder.mutation<
      PublicTherapistResponseDto,
      CreateTherapistProfileRequestDto
    >({
      query: (body: CreateTherapistProfileRequestDto) => ({
        url: API_URL.postCreateProfile,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['users'],
    }),
    deleteVideo: builder.mutation<void, void>({
      query: () => ({
        url: API_URL.deleteVideo,
        method: HttpMethods.delete,
      }),
      invalidatesTags: ['profile'],
    }),
  }),
});

export const {
  useGetTherapistProfileQuery,
  useUpdateTherapistProfileMutation,
  useCreateTherapistProfileMutation,
  useGetTherapistProfileUpdatesQuery,
  useDeleteVideoMutation,
  useDeleteTherapistProfileUpdateMutation,
} = therapistProfileApi;
