import { useCallback, useState } from 'react';

interface useToggleReturn {
  state: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
}

function useToggle(initial = false): useToggleReturn {
  const [state, setState] = useState(initial);

  const open = useCallback(() => setState(true), [setState]);
  const close = useCallback(() => setState(false), [setState]);
  const toggle = useCallback(() => {
    setState((prevState) => !prevState);
  }, []);

  return { state, toggle, open, close };
}

export default useToggle;
