import { API_URL } from '@app/constants';
import { NotificationsResponseDto } from '@app/api';
import { HttpMethods } from '@app/types';
import { actions } from '@app/store/ducks';
import { baseApi } from '../baseApi';

export const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      NotificationsResponseDto,
      { limit: number; page: number }
    >({
      keepUnusedDataFor: 5,
      query: (params) => ({
        url: API_URL.getNotifications,
        params,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((res) =>
          dispatch(actions.notifications.setNotifications(res.data)),
        );
      },
      providesTags: ['notifications'],
    }),
    markAllNotificationsViewed: builder.mutation<number, void>({
      query: () => ({
        url: API_URL.markAllNotificationsViewed,
        method: HttpMethods.patch,
      }),
      invalidatesTags: ['notifications'],
    }),
    clearNotifications: builder.mutation<void, void>({
      query: () => ({
        url: API_URL.clearNotifications,
        method: HttpMethods.delete,
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useMarkAllNotificationsViewedMutation,
  useClearNotificationsMutation,
} = notificationsApi;
