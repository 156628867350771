import {
  UserWithTokenAndMetadataResponseDto,
  UserWithMetadataResponseDto,
  UserWithTokenResponseDto,
  UserResponseDto,
  UserResponseDtoRoleEnum,
} from '@app/api';

import { createAction } from '@reduxjs/toolkit';
import { ActionType, PasswordRecoveryContext, SignUpContext } from './types';

export const setUserWithToken = createAction<UserWithTokenResponseDto>(
  ActionType.setUserWithToken,
);

export const setSignUpContext = createAction<Partial<SignUpContext>>(
  ActionType.setSignUpContext,
);

export const setPasswordRecoveryContext = createAction<PasswordRecoveryContext>(
  ActionType.setPasswordRecoveryContext,
);

export const setToken = createAction<string>(ActionType.setToken);

export const setUserWithMetadata = createAction<UserWithMetadataResponseDto>(
  ActionType.setUserWithMetadata,
);

export const setUserWithTokenAndMetadata =
  createAction<UserWithTokenAndMetadataResponseDto>(
    ActionType.setUserWithTokenAndMetadata,
  );

export const setLoginRole = createAction<UserResponseDtoRoleEnum | null>(
  ActionType.loginRole,
);

export const setUser = createAction<UserResponseDto>(ActionType.setUser);
export const setAuthModalState = createAction<boolean>(
  ActionType.setAuthModalState,
);
export const setModalActiveTab = createAction<string>(
  ActionType.setModalActiveTab,
);

export const logout = createAction(ActionType.logout);

export const setUnauthorized = createAction<boolean>(ActionType.unauthorized);
