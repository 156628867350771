import { http } from '@app/services/http';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { actions } from '../ducks';
import { HYDRATE } from 'next-redux-wrapper';
import { baseApi } from '../api/baseApi';

export const authMiddleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction): AnyAction => {
    if (action.type === actions.auth.setUserWithToken.type) {
      action.payload.accessToken &&
        http.setAuthorizationHeader(action.payload.accessToken);
    }

    if (action.type === HYDRATE) {
      if (action.payload) {
        action.payload.auth?.accessToken &&
          http.setAuthorizationHeader(action.payload.auth?.accessToken);
      }
    }

    if (action.type === actions.auth.logout.type) {
      dispatch(baseApi.util.resetApiState());
    }

    return next(action);
  };
