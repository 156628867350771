import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgLogoOnlineClient: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={270}
      height={73}
      viewBox="0 0 270 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M47.349 27.178v31.783h-5.46V32.087H20.215V58.96H14.77V27.178H47.348ZM47.826 47.218c0-8.283 4.75-13.466 13.857-13.466 9.137 0 13.857 5.183 13.857 13.466 0 8.239-4.706 13.379-13.857 13.379-9.108.014-13.857-5.14-13.857-13.38Zm22.82 0c0-6.053-3.374-9.007-8.963-9.007-5.531 0-8.92 2.954-8.92 9.007 0 5.965 3.389 8.92 8.92 8.92 5.575.014 8.963-2.955 8.963-8.92ZM90.469 47.218c0-8.283 4.749-13.466 13.857-13.466 9.136 0 13.857 5.183 13.857 13.466 0 8.239-4.706 13.379-13.857 13.379-9.093.014-13.857-5.14-13.857-13.38Zm22.82 0c0-6.053-3.374-9.007-8.963-9.007-5.531 0-8.92 2.954-8.92 9.007 0 5.965 3.388 8.92 8.92 8.92 5.589.014 8.963-2.955 8.963-8.92ZM142.001 47.218c0-8.283 4.749-13.466 13.857-13.466 9.137 0 13.857 5.183 13.857 13.466 0 8.239-4.706 13.379-13.857 13.379-9.093.014-13.857-5.14-13.857-13.38Zm22.82 0c0-6.053-3.374-9.007-8.963-9.007-5.531 0-8.919 2.954-8.919 9.007 0 5.965 3.388 8.92 8.919 8.92 5.589.014 8.963-2.955 8.963-8.92ZM141.87 52.648c0 4.358-3.822 6.646-9.527 6.646h-13.466V35.07h12.452c5.329 0 9.151 2.186 9.151 6.153 0 2.766-1.954 5.256-5.516 5.502 3.779.247 6.906 1.984 6.906 5.923Zm-18.113-7.631h6.385c3.258 0 5.43-.463 5.43-3.128 0-2.664-2.172-2.823-5.43-2.823h-6.385v5.95Zm13.075 7.21c0-2.736-2.187-3.33-5.821-3.33h-7.254v6.675h7.254c3.634 0 5.821-.608 5.821-3.344Z"
          fill="#1E1E1C"
        />
        <path
          d="M76.163 59.294V35.07h16.13v3.981h-11.25V59.294h-4.88ZM175.463 54.704v13.118h-5.154V34.896h2.693l2.461 4.315c2.071-3.041 4.894-5.199 10.049-5.199 7.834 0 13.032 5.112 13.032 12.945 0 7.79-5.198 12.945-13.032 12.945-5.14 0-7.978-2.158-10.049-5.198Zm0-8.746v2.027c.551 5.546 4.721 7.5 8.731 7.5 5.285 0 9.34-3.04 9.34-8.542 0-5.503-4.431-8.587-9.34-8.587-4.01 0-8.094 2.592-8.731 7.602ZM226.316 35.17v24.124h-3.215l-1.94-4.663c-2.114 3.432-6.067 5.546-11.004 5.546-7.356 0-11.005-4.706-11.005-11.222v-13.77h5.126v13.423c0 4.75 2.418 7.312 7.211 7.312 4.141 0 7.616-1.94 9.687-5.893V35.17h5.14Z"
          fill="#1E1E1C"
        />
        <path
          d="M253.856 35.156v24.181h-4.662V42.092l-11.497 16.565h-.246L225.954 41.83v17.506h-2.592l-1.94-4.691v-19.49h5.01l11.221 16.464 11.222-16.464h4.981Z"
          fill="#1E1E1C"
        />
        <path
          d="M10.295 45.017a5.141 5.141 0 0 1-5.14 5.14C2.317 50.157 0 47.855 0 45.017a5.141 5.141 0 0 1 5.14-5.14 5.126 5.126 0 0 1 5.155 5.14Z"
          fill="#64AB8A"
        />
        <path
          d="M238 12.916h2.404v7.53h11.192v-7.53H254v18.316h-2.404v-8.716h-11.192v8.716H238V12.916ZM169.671 12.916h2.403v7.53h11.179v-7.53h2.403v18.316h-2.403v-8.716h-11.179v8.716h-2.403V12.916ZM223.753 31.059c-1.086.304-1.579.376-2.216.376-1.578 0-2.403-1.042-2.403-3.113-2.404 2.592-4.417 3.446-8.022 3.446-4.416 0-7.153-2.056-7.153-5.357 0-2.172.97-3.678 2.997-4.576.941-.405 2.216-.71 4.576-1.013l3.822-.522c2.882-.405 3.751-.97 3.751-2.505 0-2.143-1.912-3.258-5.43-3.258-4.272 0-6.226 1.275-6.516 4.315h-2.433c.304-1.983.681-2.968 1.579-3.938 1.535-1.796 3.75-2.505 7.5-2.505 3.33 0 5.473.753 6.675 2.404.825 1.085.97 1.867.97 4.72v8.253c0 1.086.377 1.607 1.202 1.607.261 0 .594-.072 1.129-.231v1.897h-.028Zm-4.619-8.47c0-.638.043-.899.072-1.58-.97.681-1.723.942-4.272 1.347l-3.417.522c-3.967.637-5.169 1.419-5.169 3.373 0 2.216 1.868 3.49 5.097 3.49 2.664 0 5.024-.97 6.632-2.78.781-.941 1.013-1.42 1.013-2.476l.044-1.897ZM168.947 22.24c0 5.474-4.156 9.528-9.817 9.528-5.777 0-9.788-3.938-9.788-9.6 0-5.806 4.054-9.788 9.889-9.788 5.734.014 9.716 4.025 9.716 9.86Zm-17.202-.188c0 4.417 3.114 7.573 7.501 7.573 4.155 0 7.269-3.185 7.269-7.457 0-4.46-3.041-7.645-7.313-7.645-4.315 0-7.457 3.186-7.457 7.53ZM196.458 12.916l8.398 18.33h-2.621l-5.328-11.771c-.406-.941-.753-1.723-1.767-4.315-.825 2.215-1.23 3.258-1.766 4.46l-5.169 11.627h-2.549l8.283-18.331h2.519Z"
          fill="#1E1E1C"
        />
        <path
          d="M238.188 31.247V27.38c-.869 1.317-1.419 1.911-2.36 2.548-1.506 1.043-3.634 1.65-5.777 1.65-4.692 0-7.052-2.171-7.052-6.486V12.916h2.433v11.656c0 3.417 1.607 4.952 5.169 4.952 3.04 0 5.661-1.347 6.82-3.49.376-.71.564-1.607.564-2.78V12.916h2.404v18.33h-2.201Z"
          fill="#1E1E1C"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h254v67.822H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgLogoOnlineClient);
export default Memo;
