import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgPopoverBackgroung: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={92}
      height={102}
      viewBox="0 0 92 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.2}>
        <path
          d="M56.995 52.591a6.873 6.873 0 1 0 11.668-7.267 6.873 6.873 0 0 0-11.668 7.267Z"
          fill="url(#a)"
        />
        <path
          d="M95.568 57.358c-10.144-3.96-20.694-8.58-30.844-16.361-10.15-7.781-30.76-27.206-30.76-27.206s19.634 22.795 35.544 33.377c15.91 10.583 28.547 13.927 34.09 18.161 2.409 1.846 6.394 4.582 10.406 7.114v-4.58c-7.094-4.569-14.293-8.875-18.436-10.505Z"
          fill="url(#b)"
        />
        <path
          d="M40.4 19.852S33.02 9.629 28.052 4.957c-4.967-4.673-19.22-16.25-19.22-16.25s4.09 15.595 8.428 19.882c4.34 4.286 23.142 11.263 23.142 11.263Z"
          fill="url(#c)"
        />
        <path
          d="M45.904 24.793c.31-5.78.148-11.576-.485-17.33-1.053-8.253-5.68-21.891-8.468-25.15-2.788-3.26-2.768.11-2.12 4.005.647 3.894 1.884 12.572 4.338 20.111 2.455 7.54 6.735 18.364 6.735 18.364Z"
          fill="url(#d)"
        />
        <path
          d="M66.772 43.733s-5.949-2.527-9.587 2.493c-3.639 5.02-12.297 8.456-16.146 8.665-3.848.21-9.816-2.389-9.816-2.389s11.871-3.665 14.345-5.942c2.474-2.278 17.12-7.173 21.204-2.827Z"
          fill="url(#e)"
        />
        <path
          d="M55.986 36.37s-8.181-.275-11.237 1.034c-3.057 1.309-7.854 1.898-12.612 2.48-4.758.583-10.726-.333-13.54-2.27-2.814-1.938-7.585-6.041-7.585-6.041s9.666 3.508 15.261 2.847c5.596-.661 8.901-5.085 17.945-2.82 9.045 2.264 11.767 4.77 11.767 4.77Z"
          fill="url(#f)"
        />
        <path
          d="M45.419 26.16s-3.043-2.42-9.162-.477c-6.12 1.944-12.644 4.228-20 2.297C8.9 26.05 0 16.855 0 16.855s15.406 3.442 21.466 4.057c6.06.615 20.752.354 23.953 5.249Z"
          fill="url(#g)"
        />
        <path
          d="M81.629 54.367s-9.163-2.546-15.655 1.44c-6.492 3.985-8.024 9.908-13.665 10.68a423.927 423.927 0 0 1-11.348 1.375s7.945-4.909 9.116-8.09c1.172-3.18 8.076-10.438 13.744-11.518 5.667-1.08 16.106 3.914 17.808 6.113Z"
          fill="url(#h)"
        />
        <path
          d="M66.773 43.733s-4.581-9.686-5.007-14.627c-.425-4.941 1.879-13.09 3.6-18.227a38.326 38.326 0 0 0 1.891-9.672s2.212 19.3 2.133 23.252c-.078 3.953-2.467 20.942-2.467 20.942l-.15-1.668Z"
          fill="url(#i)"
        />
        <path
          d="M84.536 52.725s-4.273-.884-5.995-8.404c-1.72-7.52-3.016-13.697-1.806-19.633 1.211-5.936 1.964-15 1.964-15s4.816 14.09 4.66 20.4c-.158 6.308-.989 16.36-.426 17.67.563 1.308 1.603 4.967 1.603 4.967Z"
          fill="url(#j)"
        />
        <path
          d="M103.213 62.143s-5.89-3.757-8.586-10.347c-2.696-6.59-7.67-18.488-7.742-23.757-.14-4.037-.52-8.063-1.139-12.055 0 0 8.508 13.783 11.263 17.952 2.755 4.169 4.941 11.479 5.02 15.353.078 3.875 1.184 12.854 1.184 12.854Z"
          fill="url(#k)"
        />
        <g opacity={0.4}>
          <path
            d="M79.566 70.008a5.9 5.9 0 1 0 10.016-6.238 5.9 5.9 0 0 0-10.016 6.238Z"
            fill="url(#l)"
          />
          <path
            d="M43.654 67.443a5.9 5.9 0 1 0 10.016-6.238 5.9 5.9 0 0 0-10.017 6.238Z"
            fill="url(#m)"
          />
          <path
            d="M139 85.485c-5.975-4.505-25.721-24.169-51.806-22.025-26.084 2.144-38.702 5.073-38.702 5.073l-13.708 4.8s-10.061 8.756-14.662 10.95C15.52 86.476.039 93.96.039 93.96s-1.12-3.94 8.693-9.91c9.814-5.968 17.781-9.972 26.002-14.011 8.222-4.04 22.131-6.835 34.871-8.258 12.74-1.424 35.489-7.052 62.297 12.47a878.649 878.649 0 0 0 6.698 4.832l.4 6.4Z"
            fill="url(#n)"
          />
          <path
            d="M53.856 67.83s-3.412 4.707-4.796 9.09c-1.384 4.383-1.96 8.558-6.227 12.932-4.267 4.373-10.143 7.66-10.143 7.66s4.495-12.357 7.398-18.118c2.904-5.76 9.655-11.465 13.768-11.564Z"
            fill="url(#o)"
          />
          <path
            d="M36.467 72.723s-2.537 4.624-3.511 8.974c-.975 4.35-1.322 8.496-4.539 12.773-3.217 4.278-7.673 7.436-7.673 7.436s5.014-12.14 7.135-17.837c2.12-5.698 5.404-11.33 8.588-11.346Z"
            fill="url(#p)"
          />
          <path
            d="M61.826 66.407s-2.537 4.624-3.51 8.974c-.975 4.35-1.322 8.5-4.54 12.777-3.217 4.277-7.673 7.432-7.673 7.432s5.015-12.136 7.135-17.837c2.12-5.701 5.417-11.326 8.588-11.346Z"
            fill="url(#q)"
          />
          <path
            d="M71.736 64.65s-2.907 5.532-4.014 10.734c-1.106 5.203-1.502 10.177-5.176 15.287-3.673 5.11-8.816 8.869-8.816 8.869s5.731-14.534 8.153-21.328c2.42-6.794 6.196-13.556 9.853-13.563Z"
            fill="url(#r)"
          />
          <path
            d="M92.854 62.876s-4.158 4.664-6.49 9.444c-2.332 4.78-3.924 9.51-8.727 13.579-4.803 4.069-10.705 6.474-10.705 6.474s9.076-12.7 13.077-18.716c4-6.015 9.308-11.663 12.845-10.781Z"
            fill="url(#s)"
          />
          <path
            d="M67.432 62.05s-1.321-1.262-10.13-5.81C48.491 51.69 38.896 45.7 38.896 45.7s3.93 5.407 6.43 7.237c2.501 1.83 15.456 7.927 15.456 7.927l6.649 1.186Z"
            fill="url(#t)"
          />
          <path
            d="M56.71 64.7a44.202 44.202 0 0 0-13.894-11.396c-8.792-4.549-14.642-4.955-14.642-4.955s3.93 5.407 6.43 7.237c2.501 1.83 15.453 7.928 15.453 7.928l6.652 1.185Z"
            fill="url(#u)"
          />
          <path
            d="M44.896 67.034a44.176 44.176 0 0 0-15.72-8.7c-9.47-2.887-15.293-2.216-15.293-2.216s4.839 4.608 7.63 5.945c2.791 1.338 16.628 4.995 16.628 4.995l6.755-.024Z"
            fill="url(#v)"
          />
          <path
            d="M78.372 61.042s-6.177-5.615-10.835-8.482c-4.657-2.867-9.301-7.115-12.707-8.314a53.917 53.917 0 0 1-7.025-3.198s5.285 3.693 6.682 5.457c1.397 1.764 1.222 1.377 3.802 5.53 2.58 4.151 7.435 6.216 7.435 6.216s7.049 2.768 12.648 2.791Z"
            fill="url(#w)"
          />
          <path
            d="M32.985 70.915s-11.64.44-16.615 3.092c-4.974 2.653-13.684 7.987-13.684 7.987s3.864.856 8.852-.812c4.988-1.669 12.248-5.659 14.761-6.712 2.514-1.054 6.686-3.555 6.686-3.555Z"
            fill="url(#x)"
          />
          <path
            d="M89.565 60.69s-7.644.502-13.117-5.285c-5.473-5.788-6.173-10.389-8.38-13.48-2.206-3.092-1.301-3.726.433-2.785 1.734.941 3.874.33 6.53 6.814s10.322 12.377 10.322 12.377l4.212 2.358Z"
            fill="url(#y)"
          />
          <path
            d="M98.063 60.894s-1.651-4.84-6.976-8.84C85.763 48.055 75.285 38 75.285 38a13.163 13.163 0 0 0 4.013 9.054c4.142 3.993 8.988 9.1 8.988 9.1l9.777 4.74Z"
            fill="url(#z)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="a"
          x1={59.196}
          y1={43.124}
          x2={66.463}
          y2={54.791}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA8542" />
          <stop offset={0.05} stopColor="#C49B4E" />
          <stop offset={0.11} stopColor="#DDB059" />
          <stop offset={0.18} stopColor="#EFC061" />
          <stop offset={0.24} stopColor="#FAC966" />
          <stop offset={0.32} stopColor="#FDCC68" />
          <stop offset={0.47} stopColor="#F9C967" />
          <stop offset={0.61} stopColor="#EDBF63" />
          <stop offset={0.75} stopColor="#DAB05D" />
          <stop offset={0.89} stopColor="#BE9A54" />
          <stop offset={1} stopColor="#A2834B" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={82.287}
          y1={3.812}
          x2={65.632}
          y2={114.77}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.16} stopColor="#1F2C1B" />
          <stop offset={0.45} stopColor="#262F1C" />
          <stop offset={1} stopColor="#2F341D" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={28.089}
          y1={4.375}
          x2={20.755}
          y2={11.593}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.32} stopColor="#24321C" />
          <stop offset={0.48} stopColor="#33431F" />
          <stop offset={0.65} stopColor="#4C5E24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={51.155}
          y1={-4.028}
          x2={34.33}
          y2={12.522}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.32} stopColor="#24321C" />
          <stop offset={0.48} stopColor="#33431F" />
          <stop offset={0.65} stopColor="#4C5E24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={64.634}
          y1={64.758}
          x2={43.406}
          y2={41.401}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={61.809}
          y1={64.538}
          x2={20.662}
          y2={19.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={25.92}
          y1={36.74}
          x2={24.442}
          y2={20.023}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={65.528}
          y1={68.168}
          x2={58.233}
          y2={50.292}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={81.572}
          y1={55.093}
          x2={58.648}
          y2={-1.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={95.331}
          y1={64.503}
          x2={71.672}
          y2={6.509}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={111.639}
          y1={77.319}
          x2={83.882}
          y2={9.283}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#1F2C1B" />
          <stop offset={0.4} stopColor="#24321D" />
          <stop offset={0.58} stopColor="#2E3D22" />
          <stop offset={0.64} stopColor="#3C4D24" />
          <stop offset={0.73} stopColor="#596D27" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={81.454}
          y1={61.881}
          x2={87.693}
          y2={71.897}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA8542" />
          <stop offset={0.05} stopColor="#C49B4E" />
          <stop offset={0.11} stopColor="#DDB059" />
          <stop offset={0.18} stopColor="#EFC061" />
          <stop offset={0.24} stopColor="#FAC966" />
          <stop offset={0.32} stopColor="#FDCC68" />
          <stop offset={0.47} stopColor="#F9C967" />
          <stop offset={0.61} stopColor="#EDBF63" />
          <stop offset={0.75} stopColor="#DAB05D" />
          <stop offset={0.89} stopColor="#BE9A54" />
          <stop offset={1} stopColor="#A2834B" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={45.542}
          y1={59.316}
          x2={51.781}
          y2={69.332}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA8542" />
          <stop offset={0.05} stopColor="#C49B4E" />
          <stop offset={0.11} stopColor="#DDB059" />
          <stop offset={0.18} stopColor="#EFC061" />
          <stop offset={0.24} stopColor="#FAC966" />
          <stop offset={0.32} stopColor="#FDCC68" />
          <stop offset={0.47} stopColor="#F9C967" />
          <stop offset={0.61} stopColor="#EDBF63" />
          <stop offset={0.75} stopColor="#DAB05D" />
          <stop offset={0.89} stopColor="#BE9A54" />
          <stop offset={1} stopColor="#A2834B" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={138.88}
          y1={71.502}
          x2={-0.471}
          y2={84.044}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#0B100E" />
          <stop offset={0.42} stopColor="#0F1410" />
          <stop offset={0.45} stopColor="#1F2C1B" />
          <stop offset={0.48} stopColor="#20281A" />
          <stop offset={0.58} stopColor="#211F16" />
          <stop offset={0.76} stopColor="#182017" />
          <stop offset={1} stopColor="#39461D" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={53.454}
          y1={87.915}
          x2={31.654}
          y2={78.149}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.42} stopColor="#32381D" />
          <stop offset={0.61} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={36.121}
          y1={91.394}
          x2={21.825}
          y2={84.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.33} stopColor="#32381D" />
          <stop offset={0.61} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={61.609}
          y1={85.104}
          x2={47.313}
          y2={78.698}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.33} stopColor="#32381D" />
          <stop offset={0.61} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={71.719}
          y1={86.808}
          x2={54.627}
          y2={79.152}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.33} stopColor="#32381D" />
          <stop offset={0.61} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={87.394}
          y1={82.856}
          x2={72.654}
          y2={71.383}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.33} stopColor="#32381D" />
          <stop offset={0.5} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={87.415}
          y1={82.178}
          x2={11.459}
          y2={22.514}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.26} stopColor="#2C311B" />
          <stop offset={0.33} stopColor="#32381D" />
          <stop offset={0.5} stopColor="#5B6824" />
        </linearGradient>
        <linearGradient
          id="u"
          x1={43.338}
          y1={84.591}
          x2={42.72}
          y2={-11.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.13} stopColor="#2C311B" />
          <stop offset={0.18} stopColor="#41491F" />
          <stop offset={0.23} stopColor="#546023" />
          <stop offset={0.27} stopColor="#5B6824" />
          <stop offset={0.31} stopColor="#748053" />
        </linearGradient>
        <linearGradient
          id="v"
          x1={33.786}
          y1={87.39}
          x2={15.592}
          y2={-6.804}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#2C311B" />
          <stop offset={0.23} stopColor="#41491F" />
          <stop offset={0.27} stopColor="#546023" />
          <stop offset={0.29} stopColor="#5B6824" />
          <stop offset={0.31} stopColor="#748053" />
        </linearGradient>
        <linearGradient
          id="w"
          x1={71.688}
          y1={92.867}
          x2={44.026}
          y2={-52.579}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#2C311B" />
          <stop offset={0.23} stopColor="#41491F" />
          <stop offset={0.27} stopColor="#546023" />
          <stop offset={0.29} stopColor="#5B6824" />
          <stop offset={0.31} stopColor="#748053" />
        </linearGradient>
        <linearGradient
          id="x"
          x1={20.597}
          y1={88.805}
          x2={12.947}
          y2={48.573}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#2C311B" />
          <stop offset={0.23} stopColor="#41491F" />
          <stop offset={0.27} stopColor="#546023" />
          <stop offset={0.29} stopColor="#5B6824" />
          <stop offset={0.31} stopColor="#748053" />
        </linearGradient>
        <linearGradient
          id="y"
          x1={84.646}
          y1={48.533}
          x2={68.127}
          y2={54.972}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#2C311B" />
          <stop offset={0.3} stopColor="#41491F" />
          <stop offset={0.42} stopColor="#546023" />
          <stop offset={0.49} stopColor="#5B6824" />
          <stop offset={0.88} stopColor="#748053" />
        </linearGradient>
        <linearGradient
          id="z"
          x1={92.991}
          y1={48.285}
          x2={76.346}
          y2={54.771}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.19} stopColor="#2C311B" />
          <stop offset={0.3} stopColor="#41491F" />
          <stop offset={0.42} stopColor="#546023" />
          <stop offset={0.49} stopColor="#5B6824" />
          <stop offset={0.88} stopColor="#748053" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgPopoverBackgroung);
export default Memo;
