import { VARS } from '@app/constants';
import { DefaultSeoProps } from 'next-seo';

export const defaultSeoProps: DefaultSeoProps = {
  canonical: VARS.appBaseUrl,
  description: 'Профессиональная психологическая помощь',
  openGraph: {
    type: 'website',
    locale: 'ru_RU',
    url: VARS.appBaseUrl,
    site_name: 'Поговорим Онлайн',
    images: [
      {
        url: `${VARS.appBaseUrl}/logo.jpg`,
        alt: 'Логотип Поговорим Онлайн',
        type: 'image/jpeg',
      },
    ],
    description: 'Профессиональная психологическая помощь',
  },
};
