import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './helper';

const tagTypes = [
  'clientActiveSessions',
  'clientHistorySessions',
  'clientTotalSessions',
  'clientSessionsPreviews',
  'sessionNotes',
  'therapistActiveSessions',
  'therapistHistorySessions',
  'therapistSessionsPreviews',
  'therapistAvailabilities',
  'users',
  'profile',
  'updates',
  'therapistClients',
  'sessionPayment',
  'schedule',
  'promocodes',
  'notifications',
  'patientNotes',
  'clientTherapists',
  'userData',
  'bankCardData',
  'allTherapists',
  'checkoutUrl',
  'medsiValidateToken',
] as const;

export const baseApi = createApi({
  baseQuery,
  tagTypes,
  endpoints: () => ({}),
});
