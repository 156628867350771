import { NotificationResponseDtoEventEnum } from '@app/api';
import { API_URL } from '@app/constants';
import { actions, selectors } from '@app/store';
import { notificationsApi } from '@app/store/api/notifications';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import Pusher from 'pusher-js';
import { useEffect } from 'react';
import { PUSHER_CLUSTER, PUSHER_KEY } from '../config';

export const usePusher = () => {
  const token = useAppSelector(selectors.auth.selectToken);
  const user = useAppSelector(selectors.auth.selectUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!token) return;

    const handleEvent = () => {
      dispatch(
        notificationsApi.endpoints.getNotifications.initiate(
          { limit: 1, page: 1 },
          { forceRefetch: true },
        ),
      );
    };

    const handleTherapistNoteEvent = () => {
      dispatch(actions.notifications.addTherapistsNote());
    };

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      userAuthentication: {
        endpoint: `${API_URL.baseUrl}/pusher/auth`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        transport: 'ajax',
      },
    });

    if (pusher && user) {
      const channel = pusher.subscribe(user.id);

      for (const [key, value] of Object.entries(
        NotificationResponseDtoEventEnum,
      )) {
        channel.bind(value, handleEvent);

        if (value === NotificationResponseDtoEventEnum.ConsultationNote) {
          channel.bind(value, handleTherapistNoteEvent);
        }
      }
    }
    return () => {
      if (pusher && user) {
        pusher.unsubscribe(user.id);
      }
    };
  }, [dispatch, token, user, user.id]);
};
