import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgUsers: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 1.667a3.962 3.962 0 0 0-3.958 3.958c0 2.142 1.675 3.875 3.858 3.95a.672.672 0 0 1 .184 0h.058a3.948 3.948 0 0 0 3.817-3.95A3.962 3.962 0 0 0 7.5 1.667ZM11.734 11.791c-2.325-1.55-6.117-1.55-8.459 0-1.058.709-1.641 1.667-1.641 2.692 0 1.025.583 1.975 1.633 2.675 1.167.783 2.7 1.175 4.233 1.175 1.534 0 3.067-.392 4.234-1.175 1.05-.708 1.633-1.658 1.633-2.692-.008-1.025-.583-1.975-1.633-2.675ZM16.659 6.117a2.983 2.983 0 0 1-2.608 3.225h-.042c-.05 0-.1 0-.142.017a2.99 2.99 0 0 1-2.108-.692 3.814 3.814 0 0 0 1.25-3.167 3.867 3.867 0 0 0-.642-1.816 2.994 2.994 0 0 1 4.292 2.433Z"
        fill="#fff"
      />
      <path
        d="M18.325 13.825c-.067.808-.584 1.508-1.45 1.983-.834.458-1.883.675-2.925.65.6-.542.95-1.217 1.017-1.933.083-1.034-.409-2.025-1.392-2.817a6.88 6.88 0 0 0-1.917-1.05c1.842-.533 4.159-.175 5.583.975.767.617 1.159 1.392 1.084 2.192Z"
        fill="#fff"
      />
    </svg>
  );
};

const Memo = memo(SvgUsers);
export default Memo;
