import { FC, memo } from 'react';
import { IconComponent } from '@app/types';

const SvgInfoCircle3: FC<IconComponent> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_641_11273)">
        <path
          d="M7.85596 0.564453C3.72046 0.564453 0.355957 3.92895 0.355957 8.06445C0.355957 12.2 3.72046 15.5645 7.85596 15.5645C11.9915 15.5645 15.356 12.2 15.356 8.06445C15.356 3.92895 11.9915 0.564453 7.85596 0.564453ZM7.85596 3.63945C8.04879 3.63945 8.2373 3.69664 8.39764 3.80377C8.55798 3.9109 8.68294 4.06318 8.75674 4.24134C8.83054 4.41949 8.84984 4.61553 8.81222 4.80467C8.7746 4.9938 8.68174 5.16753 8.54539 5.30388C8.40903 5.44024 8.2353 5.5331 8.04617 5.57072C7.85704 5.60834 7.661 5.58903 7.48284 5.51524C7.30468 5.44144 7.15241 5.31647 7.04527 5.15613C6.93814 4.9958 6.88096 4.80729 6.88096 4.61445C6.88096 4.35587 6.98368 4.10787 7.16653 3.92502C7.34938 3.74218 7.59737 3.63945 7.85596 3.63945ZM9.65596 12.1145H6.35596C6.19683 12.1145 6.04422 12.0512 5.93169 11.9387C5.81917 11.8262 5.75596 11.6736 5.75596 11.5145C5.75596 11.3553 5.81917 11.2027 5.93169 11.0902C6.04422 10.9777 6.19683 10.9145 6.35596 10.9145H7.40596V7.61445H6.80596C6.64683 7.61445 6.49422 7.55124 6.38169 7.43872C6.26917 7.3262 6.20596 7.17358 6.20596 7.01445C6.20596 6.85532 6.26917 6.70271 6.38169 6.59019C6.49422 6.47767 6.64683 6.41445 6.80596 6.41445H8.00596C8.16509 6.41445 8.3177 6.47767 8.43022 6.59019C8.54274 6.70271 8.60596 6.85532 8.60596 7.01445V10.9145H9.65596C9.81509 10.9145 9.9677 10.9777 10.0802 11.0902C10.1927 11.2027 10.256 11.3553 10.256 11.5145C10.256 11.6736 10.1927 11.8262 10.0802 11.9387C9.9677 12.0512 9.81509 12.1145 9.65596 12.1145Z"
          fill="#3DAE9F"
        />
      </g>
      <defs>
        <clipPath id="clip0_641_11273">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgInfoCircle3);
export default Memo;
