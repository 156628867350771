import {
  monthNames,
  monthNamesWithNum,
  weekdayNames,
  weekdayShortNames,
} from '@app/components/ScheduleCalendar/constants';

export const makeFriendlyDayObj = (date: Date) => {
  const requiredWeekIndex = date.getDay() === 0 ? 6 : date.getDay() - 1;
  const monthIndex = date.getMonth();

  return {
    date,
    hours: date.getHours(),
    minutes: date.getMinutes(),
    day: date.getDate(),
    dayOfWeek: requiredWeekIndex,
    dayOfWeekName: weekdayNames[requiredWeekIndex],
    dayOfWeekShortName: weekdayShortNames[requiredWeekIndex],
    month: monthIndex,
    monthName: [monthNames[monthIndex], monthNamesWithNum[monthIndex]],
    year: date.getFullYear(),
  };
};
