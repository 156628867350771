import { COLORS } from '@app/constants/colors';

export const STYLES = {
  scrollbar: `
    scrollbar-color: ${COLORS.primary300} transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: transparent;
      background-clip: content-box;
      border: 4px solid transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: ${COLORS.primary300};
    }
`,
};
