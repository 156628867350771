/* eslint-disable camelcase */
import { VARS } from '@app/constants';
import { setValueToCookie } from '@app/services/cookieService';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

type UTM = 'utm_source' | 'utm_campaign' | 'utm_medium';

type RawUtm = {
  [k in UTM]?: string | string[] | undefined;
};

export const useUtmSaveToCookie = () => {
  const router = useRouter();

  const validateUtm = (params: RawUtm) => {
    const { utm_source, utm_medium, utm_campaign } = params;

    if (
      Array.isArray(utm_source) ||
      Array.isArray(utm_medium) ||
      Array.isArray(utm_campaign)
    ) {
      return;
    }

    if (!utm_source && !utm_campaign && !utm_medium) {
      return;
    }

    return { utm_source, utm_medium, utm_campaign };
  };

  const handleUtmSave = () => {
    const { utm_source, utm_campaign, utm_medium } = router.query;
    const params = validateUtm({ utm_source, utm_campaign, utm_medium });

    if (!params) {
      return;
    }

    Object.entries(params).map(([name, value]) => {
      if (!value) {
        return;
      }

      setValueToCookie(name, value, { domain: VARS.domain });
    });
  };

  useEffect(() => {
    !VARS.isServer && router.isReady && handleUtmSave();
  }, [router]);
};
