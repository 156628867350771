import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgHeart: FC<IconComponent> = ({ color = COLORS.gray1, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={15}
      viewBox="0 0 17 15"
      fill="none"
      {...props}
    >
      <path
        d="M12.3386 0C11.5829 0.000420315 10.8386 0.185755 10.1699 0.540035C9.50119 0.894315 8.92809 1.40691 8.5 2.03366C7.93611 1.20982 7.12529 0.588972 6.18593 0.261773C5.24657 -0.0654256 4.22788 -0.0818366 3.2786 0.214937C2.32931 0.511711 1.49916 1.10613 0.909363 1.91138C0.319564 2.71664 0.00100749 3.69057 0.000209764 4.69095C-0.0118153 6.35369 0.493447 7.97854 1.44517 9.33775C2.50079 10.8306 4.51607 12.9939 8.13259 14.8151L8.5 15L8.86741 14.8151C12.4839 12.9939 14.502 10.8333 15.5548 9.33775C16.5066 7.97854 17.0118 6.35369 16.9998 4.69095C16.9998 3.44683 16.5087 2.25367 15.6346 1.37395C14.7604 0.494224 13.5748 0 12.3386 0ZM14.2141 8.37748C13.3038 9.65784 11.5819 11.5204 8.5 13.1429C5.41814 11.5204 3.69625 9.65784 2.78595 8.37748C2.03287 7.2985 1.63404 6.00946 1.64533 4.69095C1.64533 3.88593 1.96309 3.11389 2.52871 2.54465C3.09433 1.97542 3.86148 1.65563 4.66139 1.65563C5.46129 1.65563 6.22844 1.97542 6.79406 2.54465C7.35968 3.11389 7.67744 3.88593 7.67744 4.69095H9.32256C9.32256 3.88593 9.64032 3.11389 10.2059 2.54465C10.7716 1.97542 11.5387 1.65563 12.3386 1.65563C13.1385 1.65563 13.9057 1.97542 14.4713 2.54465C15.0369 3.11389 15.3547 3.88593 15.3547 4.69095C15.366 6.00946 14.9671 7.2985 14.2141 8.37748Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgHeart);
export default Memo;
