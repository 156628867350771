import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgBag: FC<IconComponent> = ({ color = COLORS.gray1, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={16}
      viewBox="0 0 14 16"
      fill="none"
      {...props}
    >
      <path
        d="M2.65517 15H11.8276C13.189 15 14 14.0056 14 12.3387V6.24919C14 5.61845 13.75 5.01354 13.305 4.56753C12.8601 4.12153 12.2565 3.87097 11.6272 3.87097H10.6207V3.62903C10.6207 2.66655 10.2392 1.74349 9.56021 1.06292C8.8812 0.382343 7.96027 0 7 0C6.03973 0 5.1188 0.382343 4.43979 1.06292C3.76077 1.74349 3.37931 2.66655 3.37931 3.62903V3.87097H2.37276C1.74346 3.87097 1.13994 4.12153 0.694965 4.56753C0.249986 5.01354 0 5.61845 0 6.24919V12.3387C0 13.0445 0.27974 13.7214 0.777682 14.2205C1.27562 14.7196 1.95098 15 2.65517 15ZM4.82759 3.62903C4.82759 3.05154 5.05647 2.49771 5.46387 2.08936C5.87128 1.68102 6.42384 1.45161 7 1.45161C7.57616 1.45161 8.12872 1.68102 8.53613 2.08936C8.94353 2.49771 9.17241 3.05154 9.17241 3.62903V3.87097H4.82759V3.62903ZM1.44828 6.24919C1.44777 6.12741 1.4714 6.00673 1.51781 5.89418C1.56422 5.78163 1.63248 5.67944 1.71862 5.59355C1.89247 5.42028 2.12759 5.32288 2.37276 5.32258H11.6272C11.8724 5.32288 12.1075 5.42028 12.2814 5.59355C12.3675 5.67944 12.4358 5.78163 12.4822 5.89418C12.5286 6.00673 12.5522 6.12741 12.5517 6.24919V7.74194H1.44828V6.24919ZM6.27586 9.19355V11.129H7.72414V9.19355H12.5517V12.3387C12.5517 13.5484 12.0448 13.5484 11.8276 13.5484H2.65517C2.33508 13.5484 2.0281 13.4209 1.80177 13.1941C1.57543 12.9672 1.44828 12.6595 1.44828 12.3387V9.19355H6.27586Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgBag);
export default Memo;
