import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgMaximize2: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2v2.5C9 6.99 6.99 9 4.5 9H2M22 9h-2.5C17.01 9 15 6.99 15 4.5V2M16 22v-1.5c0-2.49 2.01-4.5 4.5-4.5H22M2 15h2.5C6.99 15 9 17.01 9 19.5V22"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Memo = memo(SvgMaximize2);
export default Memo;
