import { API_URL } from '@app/constants';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../helper';
import { HttpMethods } from '@app/types';
import { CreateTestSessionResponse, JoinSessionResponseDto } from '@app/api';

export const videoCallApi = createApi({
  reducerPath: 'api/videoCall',
  baseQuery,
  endpoints: (builder) => ({
    joinSession: builder.mutation<JoinSessionResponseDto, string>({
      query: (sessionId: string) => ({
        url: API_URL.joinSession(sessionId),
        method: HttpMethods.patch,
      }),
    }),

    getTestSessionToken: builder.query<CreateTestSessionResponse, void>({
      query: () => ({
        url: API_URL.getTestSessionToken,
        method: HttpMethods.get,
      }),
    }),
  }),
});

export const { useJoinSessionMutation, useLazyGetTestSessionTokenQuery } =
  videoCallApi;
