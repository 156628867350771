/* eslint-disable @typescript-eslint/ban-types */
import queryString from 'query-string';

export const PATHS = [
  '/alpha-test',
  '/app',
  '/ui',
  '/login',
  '/login/business',
  '/logout',

  '/sign-up/client',
  '/sign-up/client?:query',

  '/sign-up/therapist',
  '/sign-up/therapist?:query',

  '/sign-up/client-askona',

  '/client',
  '/client/onboarding',
  '/client/interview',
  '/client/consultations',
  '/client/history',
  '/client/therapists',
  '/client/profile',
  '/client/payments',
  '/client/faq',

  '/therapist',
  '/therapist/schedule',
  '/therapist/questionnaire',
  '/therapist/onboarding',
  '/therapist/:url',
  '/therapist/clients',
  '/therapist/clients/:id',
  '/therapist/profile',
  '/therapist/consultations',
  '/therapist/history',
  '/therapist/payments',
  '/therapist/settings',
  '/therapist/faq',

  '/all-therapists',
  '/child-therapists',
  '/privacy-policy',
  '/term-of-use',
  '/session-payment',
  '/call?:query',
  '/delete-account',
  '/check-email',
  '/confirm-email',

  '/password-recovery',
  '/password-recovery?:query',

  '/for-business',
  '/for-business/settings',
  '/test-call',
] as const;

export const ROUTES = PATHS.reduce(function (result, item, index) {
  result[item] = item;
  return result;
}, {} as Record<RoutesPath, string>);

export type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: string }
  : {};

export type RoutesPath = typeof PATHS[number];

export type PathParams<P extends RoutesPath> = ExtractRouteParams<P>;

export const buildUrl = <P extends RoutesPath>(
  path: P,
  params: PathParams<P>,
): string => {
  let ret: string = path;

  // Upcast `params` to be used in string replacement.
  const paramObj: { [i: string]: any } = params;

  for (const key of Object.keys(paramObj)) {
    if (key === 'query') {
      ret = ret.replace(`:${key}`, queryString.stringify(paramObj[key]));
    } else {
      ret = ret.replace(`:${key}`, paramObj[key]);
    }
  }

  return ret;
};
