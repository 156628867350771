import { toastService } from '@app/services/toastService';
import { CustomError } from '@app/types';

export const getToasterByError = (error: CustomError) => {
  if (error.data.message === 'Unauthorized') {
    toastService.error(
      { title: 'Ошибка входа, попробуйте снова' },
      { toastId: 'Unauthorized' },
    );
  } else {
    toastService.error(
      { title: 'Ошибка', content: error.data.message },
      { toastId: error.data.message },
    );
  }
};
