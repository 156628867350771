import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { CustomError } from '@app/types';
import { getToasterByError } from '@app/utils/getToasterByError';
import { actions } from '../ducks';

const dontLogoutActions = ['checkPassword', 'validateMedsiToken'];

export const errorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: any): AnyAction => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload.status === 401 &&
        !dontLogoutActions.includes(action.meta.arg.endpointName)
      ) {
        const error = action.payload as CustomError;

        getToasterByError(error);

        dispatch(actions.auth.logout());
        dispatch(actions.auth.setUnauthorized(true));
      }
    }

    return next(action);
  };
