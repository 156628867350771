import { API_URL } from '@app/constants';
import {
  DiseaseGroupResponseDto,
  DiseaseResponseDto,
  FaqResponseDto,
  iDiseaseGroupParams,
  TherapyMethodResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

export const commonApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTherapyMethods: builder.query<TherapyMethodResponseDto[], void>({
      query: () => ({
        url: API_URL.getTherapyMethods,
      }),
    }),
    getDiseases: builder.query<DiseaseResponseDto[], void>({
      query: () => ({
        url: API_URL.getDiseases,
      }),
    }),
    getDiseasesGroups: builder.query<DiseaseGroupResponseDto[], iDiseaseGroupParams>({
      query: ({ isChild } = {}) => ({
        url: API_URL.getGroupDiseases,
        params: { isChild },
      }),
    }),
    getFaq: builder.query<FaqResponseDto, void>({
      query: () => ({
        url: API_URL.getFaq,
      }),
    }),
  }),
});

export const {
  useGetDiseasesGroupsQuery,
  useGetDiseasesQuery,
  useGetTherapyMethodsQuery,
  useGetFaqQuery,
  useLazyGetDiseasesGroupsQuery,
  useLazyGetTherapyMethodsQuery,
} = commonApi;
