import { RootState } from '@app/store';

export const selectSelectedFilters = (state: RootState) =>
  state.allTherapistsFilters.selectedFilters;

export const selectSelectedFiltersValues = (state: RootState) =>
  state.allTherapistsFilters.selectedFiltersValues;

export const selectSelectedFiltersDiseases = (state: RootState) => {
  const _selectedFilterValues =
    state.allTherapistsFilters.selectedFiltersValues;

  if (!_selectedFilterValues) {
    return [];
  }

  const allFiltersKeys = Object.keys(_selectedFilterValues);
  let _diseases: string[] = [];

  for (const afKey of allFiltersKeys) {
    if (!afKey.includes('diseases')) {
      continue;
    }
    _diseases = _diseases.concat(_selectedFilterValues[afKey]);
  }

  return _diseases;
};

export const selectQueryFilters = (state: RootState) =>
  state.allTherapistsFilters.queryFilters;

export const selectFilters = (state: RootState) =>
  state.allTherapistsFilters.filters;

export const selectDiseasesGroups = (state: RootState) =>
  state.allTherapistsFilters.diseasesGroups;

export const selectTherapyMethods = (state: RootState) =>
  state.allTherapistsFilters.therapyMethods;

export const selectSortParams = (state: RootState) =>
  state.allTherapistsFilters.sorting;

export const selectActiveTab = (state: RootState) =>
  state.allTherapistsFilters.activeTab;

export const selectSearchValue = (state: RootState) =>
  state.allTherapistsFilters.searchValue;
