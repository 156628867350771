import {
  UserMetadataResponseDto,
  UserResponseDto,
  UserWithTokenAndMetadataResponseDto,
  UserWithMetadataResponseDto,
  UserWithTokenResponseDto,
  UserResponseDtoRoleEnum,
} from '@app/api';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import * as actions from './actions';
import { PasswordRecoveryContext, SignUpContext, Modals } from './types';

interface InitialState extends UserWithTokenAndMetadataResponseDto {
  signUpContext: SignUpContext;
  passwordRecoveryContext: PasswordRecoveryContext;
  unauthorized: boolean;
  loginRole: UserResponseDtoRoleEnum | null;
  modals: Modals;
}

const initialState: InitialState = {
  user: {} as UserResponseDto,
  metadata: {} as UserMetadataResponseDto,
  accessToken: '',
  signUpContext: {
    type: 'phone',
    value: '',
    role: 'patient',
    code: '',
    mode: 'normal',
  },
  passwordRecoveryContext: {
    type: 'phone',
    value: '',
  },
  unauthorized: false,
  loginRole: null,
  modals: {
    authModalActiveView: 'register',
    isAuthModalOpen: false,
  },
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.setToken, (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    })
    .addCase(
      actions.setUser,
      (state, { payload }: PayloadAction<UserResponseDto>) => {
        state.user = payload;
        state.loginRole = payload.role;
      },
    )
    .addCase(
      actions.setUserWithMetadata,
      (state, { payload }: PayloadAction<UserWithMetadataResponseDto>) => {
        state.metadata = payload.metadata;
        state.user = payload.user;
        state.loginRole = payload.user.role;
      },
    )
    .addCase(
      actions.setUserWithToken,
      (state, { payload }: PayloadAction<UserWithTokenResponseDto>) => {
        state.user = payload.user;
        state.accessToken = payload.accessToken;
        state.loginRole = payload.user.role;
      },
    )
    .addCase(
      actions.setUserWithTokenAndMetadata,
      (
        state,
        { payload }: PayloadAction<UserWithTokenAndMetadataResponseDto>,
      ) => {
        state.user = payload.user;
        state.accessToken = payload.accessToken;
        state.metadata = payload.metadata;
        state.loginRole = payload.user.role;
      },
    )
    .addCase(
      actions.setSignUpContext,
      (state, { payload }: PayloadAction<Partial<SignUpContext>>) => {
        state.signUpContext = { ...state.signUpContext, ...payload };
      },
    )
    .addCase(actions.logout, (state) => {
      state.user = {} as UserResponseDto;
      state.metadata = {} as UserMetadataResponseDto;
      state.accessToken = '';
      state.unauthorized = false;
    })
    .addCase(
      actions.setPasswordRecoveryContext,
      (state, { payload }: PayloadAction<PasswordRecoveryContext>) => {
        state.passwordRecoveryContext = payload;
      },
    )
    .addCase(
      actions.setUnauthorized,
      (state, { payload }: PayloadAction<boolean>) => {
        state.unauthorized = payload;
      },
    )
    .addCase(
      actions.setLoginRole,
      (state, { payload }: PayloadAction<UserResponseDtoRoleEnum | null>) => {
        state.loginRole = payload;
      },
    )
    .addCase(
      actions.setModalActiveTab,
      (state, { payload }: PayloadAction<string>) => {
        state.modals.authModalActiveView = payload;
      },
    )
    .addCase(
      actions.setAuthModalState,
      (state, { payload }: PayloadAction<boolean>) => {
        state.modals.isAuthModalOpen = payload;
      },
    )
    .addCase(HYDRATE, (state, { payload }: any) => {
      return {
        ...state,
        ...payload.auth,
      };
    });
});
