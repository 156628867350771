import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgUnarchive: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={17}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.503 4.61v-4L7.17 1.942M8.616.74l1.333 1.333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.167 8C2.5 8 2.5 9.193 2.5 10.667v.666c0 1.84 0 3.334 3.333 3.334h5.334c2.666 0 3.333-1.494 3.333-3.334v-.666C14.5 9.193 14.5 8 11.833 8c-.666 0-.853.14-1.2.4l-.68.72c-.786.84-2.12.84-2.913 0l-.673-.72c-.347-.26-.534-.4-1.2-.4ZM3.833 8V5.331c0-1.34 0-2.446 2-2.64M13.167 8V5.331c0-1.34 0-2.446-2-2.64"
        stroke={color}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Memo = memo(SvgUnarchive);
export default Memo;
