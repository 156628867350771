import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const MapMarker: FC<IconComponent> = ({
  color = COLORS.primary300,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={17}
      viewBox="0 0 15 17"
      fill="none"
      {...props}
    >
      <path
        d="M5.77855 16.1301C5.99572 16.4015 6.27116 16.6207 6.58448 16.7712C6.8978 16.9218 7.24097 17 7.5886 17C7.93622 17 8.27939 16.9218 8.59271 16.7712C8.90603 16.6207 9.18147 16.4015 9.39865 16.1301C9.66909 15.7901 10.0245 15.3922 10.3954 14.9711C12.1495 13.0045 14.5448 10.3078 14.5448 6.9543C14.5448 5.1099 13.8121 3.34105 12.508 2.03687C11.2038 0.732683 9.43492 0 7.59053 0C5.74613 0 3.97728 0.732683 2.6731 2.03687C1.36891 3.34105 0.63623 5.1099 0.63623 6.9543C0.63623 10.3078 3.0316 13.0045 4.78176 14.9711C5.15652 15.3922 5.5081 15.7901 5.77855 16.1301ZM7.59053 2.3181C8.82013 2.3181 9.99936 2.80656 10.8688 3.67601C11.7383 4.54547 12.2267 5.7247 12.2267 6.9543C12.2267 10.0451 9.13593 12.7495 7.59053 14.6813C6.04513 12.7495 2.95433 10.0451 2.95433 6.9543C2.95433 5.7247 3.44278 4.54547 4.31224 3.67601C5.1817 2.80656 6.36093 2.3181 7.59053 2.3181Z"
        fill={color}
      />
      <path
        d="M7.59081 8.49998C8.44431 8.49998 9.13621 7.80808 9.13621 6.95458C9.13621 6.10108 8.44431 5.40918 7.59081 5.40918C6.73731 5.40918 6.04541 6.10108 6.04541 6.95458C6.04541 7.80808 6.73731 8.49998 7.59081 8.49998Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(MapMarker);
export default Memo;
