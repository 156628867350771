import { FC, memo } from 'react';
import { IconComponent } from '@app/types';

const OrthodoxyBannerImage: FC<IconComponent> = (props) => {
  return (
    <svg
      width="582"
      height="526"
      viewBox="0 0 582 526"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M129.006 141.365C141.301 143.569 148.915 150.988 158.333 161.233C158.333 161.233 164.752 168.707 162.716 171.23C160.68 173.753 154.943 160.074 137.087 148.777C122.803 139.74 112.59 144.038 109.269 147.317L106.614 145.278C108.623 142.4 116.711 139.161 129.006 141.365Z"
        fill="url(#paint0_linear_103_8576)"
      />
      <path
        d="M155.575 181.564C163.779 183.769 167.327 185.433 176.051 193.993L179.905 201.364C175.039 199.543 177.724 195.24 161.553 186.139C148.615 178.858 139.852 183.089 137.087 186.115L134.666 184.447C136.298 181.831 144.98 178.718 155.575 181.564Z"
        fill="url(#paint1_linear_103_8576)"
      />
      <path
        d="M155.575 181.564C163.779 183.769 167.327 185.433 176.051 193.993L179.905 201.364C175.039 199.543 177.724 195.24 161.553 186.139C148.615 178.858 139.852 183.089 137.087 186.115L134.666 184.447C136.298 181.831 144.98 178.718 155.575 181.564Z"
        fill="url(#paint2_linear_103_8576)"
      />
      <path
        d="M247.676 266.392C235.158 245.926 238.248 241.047 229.759 206.574L228.793 184.006C235.817 197.195 258.335 242.273 257.149 260.929C256.026 278.576 284.074 299.281 291.215 298.472L294.119 306.591C289.26 308.766 263.841 292.82 247.676 266.392Z"
        fill="url(#paint3_linear_103_8576)"
      />
      <path
        d="M197.328 225.879C179.221 212.455 182.962 222.486 164.383 216.64L152.837 215.669C159.176 220.971 169.772 219.112 185.304 223.365C200.835 227.619 200.875 232.045 200.875 232.045L204.724 232.794C204.724 232.794 207.94 233.747 197.328 225.879Z"
        fill="url(#paint4_linear_103_8576)"
      />
      <path
        d="M197.328 225.879C179.221 212.455 182.962 222.486 164.383 216.64L152.837 215.669C159.176 220.971 169.772 219.112 185.304 223.365C200.835 227.619 200.875 232.045 200.875 232.045L204.724 232.794C204.724 232.794 207.94 233.747 197.328 225.879Z"
        fill="url(#paint5_linear_103_8576)"
      />
      <path
        d="M197.328 225.879C179.221 212.455 182.962 222.486 164.383 216.64L152.837 215.669C159.176 220.971 169.772 219.112 185.304 223.365C200.835 227.619 200.875 232.045 200.875 232.045L204.724 232.794C204.724 232.794 207.94 233.747 197.328 225.879Z"
        fill="url(#paint6_linear_103_8576)"
      />
      <path
        d="M200.648 228.595C185.896 223.989 189.64 226.858 176.301 226.22L170.422 228.92C175.55 231.331 171.006 227.673 182.047 227.786C193.087 227.899 206.501 232.041 213.182 239.385L215.057 239.384C215.863 236.329 212.031 232.15 200.648 228.595Z"
        fill="url(#paint7_linear_103_8576)"
      />
      <path
        d="M200.648 228.595C185.896 223.989 189.64 226.858 176.301 226.22L170.422 228.92C175.55 231.331 171.006 227.673 182.047 227.786C193.087 227.899 206.501 232.041 213.182 239.385L215.057 239.384C215.863 236.329 212.031 232.15 200.648 228.595Z"
        fill="url(#paint8_linear_103_8576)"
      />
      <path
        d="M188.07 61.9779C189.77 58.3857 199.035 52.0971 190.417 46.622C189.431 45.9959 187.247 42.8549 184.117 41.2074C180.896 39.5121 176.726 39.2915 175.585 37.3248C173.334 33.447 169.158 33.0792 164.52 31.3932C159.882 29.7072 157.587 31.8237 153.503 31.3806C149.418 30.9375 142.138 21.8682 138.827 33.3368C135.517 44.8054 130.424 74.6766 144.455 83.0328C158.486 91.3891 160.02 88.5228 173.71 78.081C184.619 69.7605 186.371 65.57 188.07 61.9779Z"
        fill="url(#paint9_linear_103_8576)"
      />
      <path
        d="M188.07 61.9779C189.77 58.3857 199.035 52.0971 190.417 46.622C189.431 45.9959 187.247 42.8549 184.117 41.2074C180.896 39.5121 176.726 39.2915 175.585 37.3248C173.334 33.447 169.158 33.0792 164.52 31.3932C159.882 29.7072 157.587 31.8237 153.503 31.3806C149.418 30.9375 142.138 21.8682 138.827 33.3368C135.517 44.8054 130.424 74.6766 144.455 83.0328C158.486 91.3891 160.02 88.5228 173.71 78.081C184.619 69.7605 186.371 65.57 188.07 61.9779Z"
        fill="url(#paint10_linear_103_8576)"
      />
      <path
        d="M493.922 434.096C465.155 423.654 231.434 255.424 206.643 224.785C150.951 171.045 151.993 115.792 150.305 110.334C148.616 104.876 149.342 79.8101 149.342 79.8101L146.458 77.8848C146.458 77.8848 144.25 91.9425 145.601 111.889C146.934 131.561 151.728 156.961 165.517 180.252C193.288 227.158 233.902 277.941 388.371 381.076C399.649 385.375 479.422 437.277 489.233 441.118L493.922 434.096Z"
        fill="#7BB190"
      />
      <path
        d="M493.922 434.096C465.155 423.654 231.434 255.424 206.643 224.785C150.951 171.045 151.993 115.792 150.305 110.334C148.616 104.876 149.342 79.8101 149.342 79.8101L146.458 77.8848C146.458 77.8848 144.25 91.9425 145.601 111.889C146.934 131.561 151.728 156.961 165.517 180.252C193.288 227.158 233.902 277.941 388.371 381.076C399.649 385.375 479.422 437.277 489.233 441.118L493.922 434.096Z"
        fill="url(#paint11_linear_103_8576)"
      />
      <path
        d="M176.914 50.4788C178.129 52.877 183.415 50.1978 185.572 53.1924C190.309 59.7679 196.1 57.6874 196.104 59.5664C196.107 61.4454 192.268 61.5066 182.384 71.3563C157.096 96.5584 148.179 91.6351 145.737 90.2676C143.295 88.9002 143.177 79.0525 151.306 63.4209C157.784 50.9649 167.87 41.8931 167.87 41.8931C175.523 37.9087 173.321 43.3905 176.914 50.4788Z"
        fill="url(#paint12_linear_103_8576)"
      />
      <path
        d="M176.914 50.4788C178.129 52.877 183.415 50.1978 185.572 53.1924C190.309 59.7679 196.1 57.6874 196.104 59.5664C196.107 61.4454 192.268 61.5066 182.384 71.3563C157.096 96.5584 148.179 91.6351 145.737 90.2676C143.295 88.9002 143.177 79.0525 151.306 63.4209C157.784 50.9649 167.87 41.8931 167.87 41.8931C175.523 37.9087 173.321 43.3905 176.914 50.4788Z"
        fill="url(#paint13_linear_103_8576)"
      />
      <path
        d="M160.684 49.1183C160.185 45.3096 161.819 37.3216 154.003 37.1888C152.189 37.158 149.458 37.2362 146.348 35.1919C143.238 33.1477 138.872 35.416 136.422 35.2677C133.971 35.1194 130.148 32.5085 128.768 34.4994C127.389 36.4904 128.52 38.779 128.192 55.6427C127.864 72.5065 140.762 90.3989 146.241 89.7043C151.719 89.0097 154.619 85.1046 158.677 69.6046C161.91 57.2534 161.183 52.927 160.684 49.1183Z"
        fill="url(#paint14_linear_103_8576)"
      />
      <path
        d="M76.3681 142.339C73.0469 141.471 67.8996 148.221 70.6812 153.056C70.9993 153.609 70.3168 158.195 71.435 159.782C72.5856 161.415 74.7009 162.549 74.6759 163.806C74.6267 166.283 76.6985 167.492 78.5949 169.469C80.4912 171.445 84.4742 170.792 86.6568 174.637C88.8393 178.482 94.2467 178.965 95.9969 177.47C97.7472 175.975 110.177 156.366 105.472 148.771C100.767 141.175 99.0546 142.221 88.5717 144.039C80.2184 145.487 79.6892 143.207 76.3681 142.339Z"
        fill="url(#paint15_linear_103_8576)"
      />
      <path
        d="M131.975 179.212C129.665 179.587 130.685 179.761 128.75 180.075C127.986 180.199 127.01 181.659 127.152 182.365C127.299 183.092 126.857 184.393 126.661 184.816C126.274 185.65 127.741 187.185 128.09 188.14C128.439 189.094 130.311 189.084 130.477 190.716C130.644 192.347 132.882 192.394 133.699 192.148C134.517 191.902 141.667 187.099 141.204 183.817C140.741 180.535 140.004 180.635 136.172 179.686C133.119 178.93 134.285 178.837 131.975 179.212Z"
        fill="url(#paint16_linear_103_8576)"
      />
      <path
        d="M131.975 179.212C129.665 179.587 130.685 179.761 128.75 180.075C127.986 180.199 127.01 181.659 127.152 182.365C127.299 183.092 126.857 184.393 126.661 184.816C126.274 185.65 127.741 187.185 128.09 188.14C128.439 189.094 130.311 189.084 130.477 190.716C130.644 192.347 132.882 192.394 133.699 192.148C134.517 191.902 141.667 187.099 141.204 183.817C140.741 180.535 140.004 180.635 136.172 179.686C133.119 178.93 134.285 178.837 131.975 179.212Z"
        fill="url(#paint17_linear_103_8576)"
      />
      <path
        d="M137.352 179.686C135.914 179.919 136.549 180.028 135.345 180.224C134.869 180.301 134.261 181.21 134.35 181.649C134.441 182.101 134.166 182.911 134.044 183.174C133.803 183.694 134.717 184.65 134.934 185.244C135.151 185.838 136.316 185.831 136.42 186.847C136.524 187.863 137.916 187.892 138.425 187.738C138.934 187.585 143.385 184.596 143.097 182.553C142.809 180.51 142.35 180.572 139.965 179.981C138.064 179.511 138.79 179.453 137.352 179.686Z"
        fill="url(#paint18_linear_103_8576)"
      />
      <path
        d="M137.352 179.686C135.914 179.919 136.549 180.028 135.345 180.224C134.869 180.301 134.261 181.21 134.35 181.649C134.441 182.101 134.166 182.911 134.044 183.174C133.803 183.694 134.717 184.65 134.934 185.244C135.151 185.838 136.316 185.831 136.42 186.847C136.524 187.863 137.916 187.892 138.425 187.738C138.934 187.585 143.385 184.596 143.097 182.553C142.809 180.51 142.35 180.572 139.965 179.981C138.064 179.511 138.79 179.453 137.352 179.686Z"
        fill="url(#paint19_linear_103_8576)"
      />
      <path
        d="M86.0107 166.155C85.407 164.358 81.5276 164.251 81.065 161.758C80.4571 158.482 75.9842 155.735 76.7727 153.738C77.5611 151.74 86.7193 152.478 91.4883 147.066C95.5086 142.504 106.021 140.206 109.228 143.682C112.435 147.157 109.353 152.292 100.151 159.463C94.2811 164.038 93.778 168.522 92.3545 169.766C90.931 171.01 87.2511 169.846 86.0107 166.155Z"
        fill="url(#paint20_linear_103_8576)"
      />
      <path
        d="M90.6759 150.986C84.2778 154.476 78.1995 152.281 77.3475 150.355C76.4955 148.429 75.6439 146.503 75.6026 144.218C75.5612 141.934 73.1934 141.83 73.4466 139.799C73.6999 137.768 78.3033 141.037 87.5958 138.516C96.8882 135.996 108.552 140.597 108.914 143.702C109.276 146.808 98.7051 146.606 90.6759 150.986Z"
        fill="url(#paint21_linear_103_8576)"
      />
      <path
        d="M96.6369 157.463C91.0953 161.452 90.4847 167.475 91.7661 168.976C93.0474 170.476 94.3291 171.976 96.2418 172.937C98.1546 173.898 97.2828 175.94 99.1009 176.55C100.919 177.159 100.024 171.946 105.92 165.119C111.816 158.293 112.658 146.576 110.182 145.011C107.706 143.447 103.591 152.457 96.6369 157.463Z"
        fill="url(#paint22_linear_103_8576)"
      />
      <path
        d="M104.472 142.238C104.774 143.384 104.577 143.618 104.45 144.773C104.395 145.274 104.154 146.501 104.476 146.992C104.806 147.498 105.015 148.601 105.055 148.975C105.132 149.711 106.461 150.456 106.98 151.077C107.499 151.698 108.514 151.166 109.168 152.348C109.823 153.53 111.056 152.938 111.416 152.524C111.776 152.109 114.007 146.491 112.622 144.15C111.237 141.809 109.439 140.728 106.904 140.343C103.789 139.87 104.17 141.093 104.472 142.238Z"
        fill="url(#paint23_linear_103_8576)"
      />
      <path
        d="M104.472 142.238C104.774 143.384 104.577 143.618 104.45 144.773C104.395 145.274 104.154 146.501 104.476 146.992C104.806 147.498 105.015 148.601 105.055 148.975C105.132 149.711 106.461 150.456 106.98 151.077C107.499 151.698 108.514 151.166 109.168 152.348C109.823 153.53 111.056 152.938 111.416 152.524C111.776 152.109 114.007 146.491 112.622 144.15C111.237 141.809 109.439 140.728 106.904 140.343C103.789 139.87 104.17 141.093 104.472 142.238Z"
        fill="url(#paint24_linear_103_8576)"
      />
      <path
        d="M156.982 87.3231C155.37 86.4163 155.321 85.9406 154.166 84.5435C153.664 83.9375 152.551 82.3348 151.652 82.1608C150.726 81.9816 149.262 81.0167 148.801 80.6549C147.892 79.9417 145.614 80.6199 144.354 80.5268C143.094 80.4338 142.587 82.1491 140.552 81.5977C138.517 81.0463 137.839 83.071 137.912 83.9244C137.986 84.7779 141.854 93.3832 145.982 94.3756C150.111 95.368 153.276 94.5287 156.462 92.1073C160.378 89.1321 158.593 88.2298 156.982 87.3231Z"
        fill="url(#paint25_linear_103_8576)"
      />
      <path
        d="M166.688 239.8C169.611 238.052 170.951 232.302 171.256 229.645C170.155 227.013 163.845 228.185 161.764 229.813C159.683 231.44 159.155 231.449 153.947 235.253C149.781 238.296 147.37 245.561 148.343 246.487C149.316 247.413 152.456 246.414 154.144 246.384C156.253 246.347 163.034 241.986 166.688 239.8Z"
        fill="url(#paint26_linear_103_8576)"
      />
      <path
        d="M169.796 238.557C172.613 236.382 173.236 230.398 173.195 227.679C171.688 225.183 170.788 229.541 168.834 231.475C166.88 233.41 167.989 237.328 163.063 241.898C159.122 245.554 157.869 248.408 158.224 248.717C158.579 249.027 161.504 244.954 163.771 243.618C165.687 242.49 166.274 241.277 169.796 238.557Z"
        fill="url(#paint27_linear_103_8576)"
      />
      <path
        d="M163.51 227.344C166.648 226.248 169.996 225.418 172.281 226.614C174.114 228.699 170.784 228.637 168.472 229.777C166.161 230.918 162.734 230.624 157.186 233.591C152.748 235.965 148.814 239.23 148.361 237.845C147.907 236.46 152.206 235.354 152.951 233.883C153.883 232.044 156.718 229.716 163.51 227.344Z"
        fill="url(#paint28_linear_103_8576)"
      />
      <path
        d="M165.098 230.316C167.67 228.513 170.491 226.903 172.812 227.444C174.927 228.922 171.922 229.698 170.102 231.335C168.283 232.973 165.138 233.558 160.822 237.704C157.37 241.02 154.571 245.038 153.852 243.864C153.133 242.69 156.745 240.587 157.084 239.034C157.507 237.091 159.531 234.219 165.098 230.316Z"
        fill="url(#paint29_linear_103_8576)"
      />
      <path
        d="M204.377 124.45C208.512 131.555 208.037 138.534 206.915 147.623C206.915 147.623 205.903 154.027 203.771 154.081C201.638 154.136 206.051 145.429 204.105 131.662C202.547 120.648 196.039 117.359 192.98 117.092L192.87 114.891C195.17 114.672 200.241 117.346 204.377 124.45Z"
        fill="url(#paint30_linear_103_8576)"
      />
      <path
        d="M195.545 154.905C197.937 159.959 198.614 162.447 198.041 170.469L195.988 175.543C194.827 172.327 198.129 171.84 195.8 159.852C193.936 150.263 188.079 147.672 185.384 147.576L185.188 145.651C187.195 145.355 192.457 148.38 195.545 154.905Z"
        fill="url(#paint31_linear_103_8576)"
      />
      <path
        d="M195.545 154.905C197.937 159.959 198.614 162.447 198.041 170.469L195.988 175.543C194.827 172.327 198.129 171.84 195.8 159.852C193.936 150.263 188.079 147.672 185.384 147.576L185.188 145.651C187.195 145.355 192.457 148.38 195.545 154.905Z"
        fill="url(#paint32_linear_103_8576)"
      />
      <path
        d="M198.893 198.716C207.429 185.82 212.227 180.354 230.97 167.603L244.831 162.525C238.969 170.092 233.95 165.096 209.728 189.779C190.351 209.525 191.475 224.317 194.459 229.244L190.358 232.892C187.306 229.855 187.87 215.368 198.893 198.716Z"
        fill="url(#paint33_linear_103_8576)"
      />
      <path
        d="M198.893 198.716C207.429 185.82 212.227 180.354 230.97 167.603L244.831 162.525C238.969 170.092 233.95 165.096 209.728 189.779C190.351 209.525 191.475 224.317 194.459 229.244L190.358 232.892C187.306 229.855 187.87 215.368 198.893 198.716Z"
        fill="url(#paint34_linear_103_8576)"
      />
      <path
        d="M198.893 198.716C207.429 185.82 212.227 180.354 230.97 167.603L244.831 162.525C238.969 170.092 233.95 165.096 209.728 189.779C190.351 209.525 191.475 224.317 194.459 229.244L190.358 232.892C187.306 229.855 187.87 215.368 198.893 198.716Z"
        fill="url(#paint35_linear_103_8576)"
      />
      <path
        d="M192.27 237.238C197.196 222.239 200.959 221.708 214.625 202.76L225.528 192.656C221.91 201.799 208.906 232.302 199.046 239.659C189.72 246.618 191.293 269.505 194.743 272.741L191.91 277.658C188.749 276.148 185.909 256.607 192.27 237.238Z"
        fill="url(#paint36_linear_103_8576)"
      />
      <path
        d="M191.123 194.727C190.136 179.927 186.701 186.078 181.713 174.271L177.279 168.069C177.323 173.507 182.771 178.027 187.258 187.627C191.746 197.227 189.543 199.134 189.543 199.134L190.808 201.383C190.808 201.383 191.701 203.402 191.123 194.727Z"
        fill="url(#paint37_linear_103_8576)"
      />
      <path
        d="M191.123 194.727C190.136 179.927 186.701 186.078 181.713 174.271L177.279 168.069C177.323 173.507 182.771 178.027 187.258 187.627C191.746 197.227 189.543 199.134 189.543 199.134L190.808 201.383C190.808 201.383 191.701 203.402 191.123 194.727Z"
        fill="url(#paint38_linear_103_8576)"
      />
      <path
        d="M191.123 194.727C190.136 179.927 186.701 186.078 181.713 174.271L177.279 168.069C177.323 173.507 182.771 178.027 187.258 187.627C191.746 197.227 189.543 199.134 189.543 199.134L190.808 201.383C190.808 201.383 191.701 203.402 191.123 194.727Z"
        fill="url(#paint39_linear_103_8576)"
      />
      <path
        d="M191.176 197.549C187.198 188.19 187.355 191.29 181.99 184.33L178.13 182.534C179.107 186.132 179.005 182.295 183.654 187.878C188.303 193.462 191.943 201.952 191.108 208.433L191.908 209.372C193.783 208.474 194.245 204.772 191.176 197.549Z"
        fill="url(#paint40_linear_103_8576)"
      />
      <path
        d="M191.176 197.549C187.198 188.19 187.355 191.29 181.99 184.33L178.13 182.534C179.107 186.132 179.005 182.295 183.654 187.878C188.303 193.462 191.943 201.952 191.108 208.433L191.908 209.372C193.783 208.474 194.245 204.772 191.176 197.549Z"
        fill="url(#paint41_linear_103_8576)"
      />
      <path
        d="M269.354 120.228C271.879 119.549 278.981 121.514 278.053 114.859C277.947 114.098 278.591 111.664 278.083 109.393C277.56 107.056 275.894 104.871 276.393 103.46C277.378 100.679 275.783 98.4286 274.651 95.3846C273.52 92.3406 271.48 92.0916 269.961 89.8551C268.443 87.6185 269.887 80.1027 262.726 83.331C255.565 86.5593 238.417 96.7377 240.208 107.334C241.998 117.93 244.089 117.478 255.16 119.891C263.981 121.815 266.829 120.907 269.354 120.228Z"
        fill="url(#paint42_linear_103_8576)"
      />
      <path
        d="M269.354 120.228C271.879 119.549 278.981 121.514 278.053 114.859C277.947 114.098 278.591 111.664 278.083 109.393C277.56 107.056 275.894 104.871 276.393 103.46C277.378 100.679 275.783 98.4286 274.651 95.3846C273.52 92.3406 271.48 92.0916 269.961 89.8551C268.443 87.6185 269.887 80.1027 262.726 83.331C255.565 86.5593 238.417 96.7377 240.208 107.334C241.998 117.93 244.089 117.478 255.16 119.891C263.981 121.815 266.829 120.907 269.354 120.228Z"
        fill="url(#paint43_linear_103_8576)"
      />
      <path
        d="M213.141 432.18C206.115 413.306 190.846 224.42 195.641 198.932C198.849 148.104 226.996 125.076 229.013 121.903C231.03 118.73 243.906 108.41 243.906 108.41L243.643 106.144C243.643 106.144 235.653 111.029 226.228 120.208C216.933 129.26 206.241 142.49 200.441 159.331C188.76 193.247 180.609 235.254 194.736 356.66C197.387 364.147 205.365 426.266 207.622 432.822L213.141 432.18Z"
        fill="#7BB190"
      />
      <path
        d="M213.141 432.18C206.115 413.306 190.846 224.42 195.641 198.932C198.849 148.104 226.996 125.076 229.013 121.903C231.03 118.73 243.906 108.41 243.906 108.41L243.643 106.144C243.643 106.144 235.653 111.029 226.228 120.208C216.933 129.26 206.241 142.49 200.441 159.331C188.76 193.247 180.609 235.254 194.736 356.66C197.387 364.147 205.365 426.266 207.622 432.822L213.141 432.18Z"
        fill="url(#paint44_linear_103_8576)"
      />
      <path
        d="M270.364 109.733C269.68 111.365 273.276 112.873 272.694 115.231C271.416 120.408 274.928 122.425 273.987 123.228C273.046 124.031 271.379 122.132 262.228 121.375C238.814 119.437 237.482 112.868 237.127 111.061C236.772 109.253 241.659 104.997 252.961 102.41C261.967 100.349 270.814 101.539 270.814 101.539C276.074 103.678 272.387 104.911 270.364 109.733Z"
        fill="url(#paint45_linear_103_8576)"
      />
      <path
        d="M270.364 109.733C269.68 111.365 273.276 112.873 272.694 115.231C271.416 120.408 274.928 122.425 273.987 123.228C273.046 124.031 271.379 122.132 262.228 121.375C238.814 119.437 237.482 112.868 237.127 111.061C236.772 109.253 241.659 104.997 252.961 102.41C261.967 100.349 270.814 101.539 270.814 101.539C276.074 103.678 272.387 104.911 270.364 109.733Z"
        fill="url(#paint46_linear_103_8576)"
      />
      <path
        d="M264.129 101.016C265.826 99.1421 270.528 96.5569 267.263 92.5813C266.505 91.6588 265.302 90.323 265.001 87.8923C264.701 85.4615 261.703 84.2395 260.732 82.9477C259.762 81.6559 259.442 78.626 257.856 78.7829C256.269 78.9398 255.604 80.4823 247.009 87.5055C238.414 94.5287 234.941 108.622 237.624 111.073C240.307 113.523 243.501 113.313 253.002 108.741C260.573 105.098 262.432 102.889 264.129 101.016Z"
        fill="url(#paint47_linear_103_8576)"
      />
      <path
        d="M181.453 98.4739C180.472 96.4389 174.894 96.7349 173.656 100.19C173.514 100.586 170.924 102.198 170.605 103.435C170.276 104.708 170.609 106.252 169.969 106.775C168.705 107.806 168.982 109.36 168.8 111.154C168.617 112.947 170.642 114.665 169.645 117.398C168.647 120.132 170.709 123.049 172.205 123.289C173.7 123.53 188.83 121.403 190.633 115.807C192.435 110.211 191.181 109.798 185.802 105.317C181.515 101.746 182.433 100.509 181.453 98.4739Z"
        fill="url(#paint48_linear_103_8576)"
      />
      <path
        d="M186.666 142.07C185.493 141.072 185.841 141.658 184.858 140.821C184.471 140.491 183.323 140.624 183.029 140.996C182.728 141.38 181.887 141.713 181.591 141.795C181.008 141.956 180.864 143.346 180.534 143.928C180.204 144.509 181.007 145.444 180.26 146.223C179.513 147.002 180.443 148.143 180.915 148.448C181.387 148.754 186.842 150.292 188.29 148.661C189.739 147.03 189.375 146.702 188.217 144.377C187.295 142.524 187.838 143.068 186.666 142.07Z"
        fill="url(#paint49_linear_103_8576)"
      />
      <path
        d="M186.666 142.07C185.493 141.072 185.841 141.658 184.858 140.821C184.471 140.491 183.323 140.624 183.029 140.996C182.728 141.38 181.887 141.713 181.591 141.795C181.008 141.956 180.864 143.346 180.534 143.928C180.204 144.509 181.007 145.444 180.26 146.223C179.513 147.002 180.443 148.143 180.915 148.448C181.387 148.754 186.842 150.292 188.29 148.661C189.739 147.03 189.375 146.702 188.217 144.377C187.295 142.524 187.838 143.068 186.666 142.07Z"
        fill="url(#paint50_linear_103_8576)"
      />
      <path
        d="M188.72 144.968C187.99 144.347 188.206 144.711 187.595 144.191C187.354 143.985 186.639 144.068 186.456 144.3C186.269 144.538 185.745 144.746 185.561 144.797C185.198 144.897 185.108 145.762 184.903 146.125C184.698 146.487 185.197 147.068 184.732 147.553C184.267 148.038 184.847 148.749 185.14 148.939C185.434 149.128 188.83 150.086 189.731 149.071C190.633 148.055 190.406 147.852 189.686 146.404C189.112 145.251 189.45 145.59 188.72 144.968Z"
        fill="url(#paint51_linear_103_8576)"
      />
      <path
        d="M188.72 144.968C187.99 144.347 188.206 144.711 187.595 144.191C187.354 143.985 186.639 144.068 186.456 144.3C186.269 144.538 185.745 144.746 185.561 144.797C185.198 144.897 185.108 145.762 184.903 146.125C184.698 146.487 185.197 147.068 184.732 147.553C184.267 148.038 184.847 148.749 185.14 148.939C185.434 149.128 188.83 150.086 189.731 149.071C190.633 148.055 190.406 147.852 189.686 146.404C189.112 145.251 189.45 145.59 188.72 144.968Z"
        fill="url(#paint52_linear_103_8576)"
      />
      <path
        d="M173.622 113.459C174.265 112.391 172.666 110.4 173.719 109.105C175.102 107.404 174.573 103.991 175.91 103.535C177.248 103.079 180.781 107.985 185.527 108.07C189.528 108.141 195.161 112.432 194.785 115.521C194.41 118.611 190.522 119.254 183.004 117.697C178.208 116.704 175.746 118.362 174.515 118.179C173.285 117.996 172.3 115.654 173.622 113.459Z"
        fill="url(#paint53_linear_103_8576)"
      />
      <path
        d="M183.216 109.333C178.739 107.613 177.249 103.63 177.851 102.382C178.454 101.134 179.056 99.8856 180.184 98.8911C181.312 97.8967 180.355 96.6651 181.481 95.9264C182.608 95.1877 182.931 98.8891 188.155 102.474C193.379 106.059 196.044 113.868 194.641 115.373C193.238 116.878 188.834 111.492 183.216 109.333Z"
        fill="url(#paint54_linear_103_8576)"
      />
      <path
        d="M182.509 115.083C178.147 114.004 174.867 116.265 174.661 117.547C174.455 118.829 174.249 120.111 174.582 121.48C174.915 122.849 173.52 123.282 173.989 124.453C174.459 125.625 176.691 122.954 182.627 123C188.563 123.047 194.796 118.475 194.525 116.567C194.254 114.658 187.983 116.436 182.509 115.083Z"
        fill="url(#paint55_linear_103_8576)"
      />
      <path
        d="M193.482 112.522C193.036 113.162 192.834 113.163 192.201 113.591C191.927 113.777 191.209 114.179 191.1 114.55C190.987 114.931 190.523 115.507 190.353 115.685C190.017 116.038 190.209 117.022 190.119 117.547C190.029 118.072 190.728 118.354 190.415 119.186C190.101 120.017 190.923 120.384 191.285 120.387C191.646 120.391 195.414 119.116 195.997 117.423C196.58 115.731 196.356 114.368 195.469 112.933C194.378 111.17 193.927 111.882 193.482 112.522Z"
        fill="url(#paint56_linear_103_8576)"
      />
      <path
        d="M193.482 112.522C193.036 113.162 192.834 113.163 192.201 113.591C191.927 113.777 191.209 114.179 191.1 114.55C190.987 114.931 190.523 115.507 190.353 115.685C190.017 116.038 190.209 117.022 190.119 117.547C190.029 118.072 190.728 118.354 190.415 119.186C190.101 120.017 190.923 120.384 191.285 120.387C191.646 120.391 195.414 119.116 195.997 117.423C196.58 115.731 196.356 114.368 195.469 112.933C194.378 111.17 193.927 111.882 193.482 112.522Z"
        fill="url(#paint57_linear_103_8576)"
      />
      <path
        d="M243.396 115.443C243.163 114.249 243.381 114.022 243.589 112.846C243.679 112.337 244.009 111.096 243.712 110.57C243.407 110.03 243.268 108.885 243.252 108.5C243.223 107.74 241.911 106.886 241.421 106.215C240.931 105.544 239.855 106.021 239.264 104.765C238.673 103.51 237.369 104.033 236.972 104.434C236.575 104.834 233.91 110.441 235.172 112.934C236.434 115.427 238.204 116.656 240.776 117.222C243.936 117.917 243.628 116.638 243.396 115.443Z"
        fill="url(#paint58_linear_103_8576)"
      />
      <path
        d="M171.083 185.299C173.206 186.019 176.66 184.24 178.122 183.261C178.972 181.587 175.696 178.923 173.993 178.573C172.289 178.223 172.06 177.963 167.933 176.973C164.632 176.181 159.962 178.068 159.912 178.951C159.863 179.833 161.702 180.982 162.436 181.816C163.353 182.857 168.43 184.398 171.083 185.299Z"
        fill="url(#paint59_linear_103_8576)"
      />
      <path
        d="M173.031 186.327C175.323 186.812 178.588 184.574 179.934 183.394C180.544 181.575 177.975 182.982 176.172 182.826C174.369 182.671 172.878 184.897 168.487 184.375C164.974 183.957 163.009 184.546 163.005 184.855C163.001 185.165 166.29 184.896 167.926 185.463C169.309 185.943 170.167 185.721 173.031 186.327Z"
        fill="url(#paint60_linear_103_8576)"
      />
      <path
        d="M175.974 178.396C177.861 179.503 179.705 180.827 180.078 182.482C179.815 184.29 178.426 182.594 176.87 181.921C175.313 181.249 173.999 179.405 170.147 177.888C167.065 176.675 163.751 176.094 164.253 175.276C164.754 174.458 167.14 176.142 168.196 175.889C169.515 175.572 171.891 176.002 175.974 178.396Z"
        fill="url(#paint61_linear_103_8576)"
      />
      <path
        d="M175.161 180.459C177.162 180.98 179.171 181.708 179.889 183.102C180.05 184.793 178.38 183.617 176.783 183.403C175.187 183.188 173.553 181.861 169.635 181.464C166.501 181.146 163.293 181.456 163.575 180.595C163.857 179.734 166.451 180.649 167.375 180.156C168.529 179.541 170.832 179.331 175.161 180.459Z"
        fill="url(#paint62_linear_103_8576)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_8576"
          x1="109.488"
          y1="135.251"
          x2="160.795"
          y2="173.733"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_103_8576"
          x1="139.857"
          y1="176.907"
          x2="183.474"
          y2="206.956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4D0C1" />
          <stop offset="1" stopColor="#7BB190" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_103_8576"
          x1="136.67"
          y1="176.3"
          x2="178.004"
          y2="204.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_103_8576"
          x1="279.27"
          y1="311.914"
          x2="226.94"
          y2="165.629"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E935F" />
          <stop offset="1" stopColor="#CDF0D1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_103_8576"
          x1="190.732"
          y1="229.551"
          x2="191.926"
          y2="223.578"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_103_8576"
          x1="198.986"
          y1="234.431"
          x2="201.289"
          y2="222.526"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_103_8576"
          x1="91.5314"
          y1="199.225"
          x2="110.071"
          y2="273.546"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_103_8576"
          x1="218.093"
          y1="240.844"
          x2="161.382"
          y2="228.781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4D0C1" />
          <stop offset="1" stopColor="#CDF0D1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_103_8576"
          x1="218.637"
          y1="236.535"
          x2="171.189"
          y2="225.392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_103_8576"
          x1="153.942"
          y1="52.7185"
          x2="182.152"
          y2="19.0175"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ACE4E3" />
          <stop offset="1" stopColor="#50B6BD" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_103_8576"
          x1="128.52"
          y1="56.6321"
          x2="181.731"
          y2="40.2103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_103_8576"
          x1="239.131"
          y1="296.076"
          x2="338.919"
          y2="222.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_103_8576"
          x1="152.754"
          y1="92.5702"
          x2="172.078"
          y2="52.9838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ACE4E3" />
          <stop offset="1" stopColor="#50B6BD" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_103_8576"
          x1="169.402"
          y1="89.4341"
          x2="157.853"
          y2="51.0612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_103_8576"
          x1="128.747"
          y1="76.6759"
          x2="162.392"
          y2="51.7369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_103_8576"
          x1="106.022"
          y1="167.023"
          x2="71.1586"
          y2="161.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_103_8576"
          x1="140.423"
          y1="186.347"
          x2="125.442"
          y2="188.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF6FF" />
          <stop offset="1" stopColor="#A4CFF8" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_103_8576"
          x1="138.146"
          y1="183.101"
          x2="123.544"
          y2="187.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_103_8576"
          x1="142.619"
          y1="185.001"
          x2="133.396"
          y2="186.369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#679B69" />
          <stop offset="1" stopColor="#ACE4E3" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_103_8576"
          x1="142.619"
          y1="185.001"
          x2="133.302"
          y2="195.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_103_8576"
          x1="102.472"
          y1="145.3"
          x2="96.1751"
          y2="168.471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_103_8576"
          x1="111.761"
          y1="142.867"
          x2="69.0543"
          y2="141.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_103_8576"
          x1="145.297"
          y1="142.674"
          x2="97.2113"
          y2="188.291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_103_8576"
          x1="113.071"
          y1="146.273"
          x2="105.004"
          y2="150.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#679B69" />
          <stop offset="1" stopColor="#ACE4E3" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_103_8576"
          x1="113.071"
          y1="146.273"
          x2="104.045"
          y2="162.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_103_8576"
          x1="143.116"
          y1="92.571"
          x2="135.251"
          y2="65.3152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_103_8576"
          x1="163.561"
          y1="227.496"
          x2="184.184"
          y2="241.307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_103_8576"
          x1="138.271"
          y1="226.548"
          x2="177.672"
          y2="220.407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_103_8576"
          x1="159.524"
          y1="264.984"
          x2="180.659"
          y2="223.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_103_8576"
          x1="169.983"
          y1="266.29"
          x2="179.684"
          y2="222.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_103_8576"
          x1="199.123"
          y1="112.059"
          x2="201.697"
          y2="154.185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_103_8576"
          x1="191.181"
          y1="145.04"
          x2="194.706"
          y2="179.716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4D0C1" />
          <stop offset="1" stopColor="#7BB190" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_103_8576"
          x1="190.127"
          y1="143.183"
          x2="193.794"
          y2="175.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_103_8576"
          x1="181.864"
          y1="223.353"
          x2="255.749"
          y2="157.63"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E935F" />
          <stop offset="1" stopColor="#95CCBC" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_103_8576"
          x1="178.936"
          y1="228.4"
          x2="247.539"
          y2="165.581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_103_8576"
          x1="346.273"
          y1="126.254"
          x2="336.924"
          y2="260.784"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_103_8576"
          x1="182.912"
          y1="272.481"
          x2="233.952"
          y2="183.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E935F" />
          <stop offset="1" stopColor="#CDF0D1" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_103_8576"
          x1="186.471"
          y1="192.985"
          x2="189.974"
          y2="191.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_103_8576"
          x1="187.542"
          y1="199.204"
          x2="194.492"
          y2="195.284"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_103_8576"
          x1="159.394"
          y1="130.323"
          x2="130.033"
          y2="171.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_103_8576"
          x1="192.47"
          y1="211.517"
          x2="174.347"
          y2="177.942"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4D0C1" />
          <stop offset="1" stopColor="#CDF0D1" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_103_8576"
          x1="194.863"
          y1="209.952"
          x2="180.226"
          y2="181.414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_103_8576"
          x1="259.45"
          y1="99.1702"
          x2="288.371"
          y2="98.9498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ACE4E3" />
          <stop offset="1" stopColor="#50B6BD" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_103_8576"
          x1="246.653"
          y1="88.0918"
          x2="277.566"
          y2="107.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_103_8576"
          x1="173.745"
          y1="245.606"
          x2="252.939"
          y2="264.471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_103_8576"
          x1="238.963"
          y1="115.56"
          x2="267.047"
          y2="108.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ACE4E3" />
          <stop offset="1" stopColor="#50B6BD" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_103_8576"
          x1="247.631"
          y1="122.571"
          x2="261.948"
          y2="100.425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_103_8576"
          x1="236.7"
          y1="96.7488"
          x2="263.544"
          y2="102.988"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_103_8576"
          x1="181.716"
          y1="123.862"
          x2="169.482"
          y2="104.151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_103_8576"
          x1="186.689"
          y1="149.347"
          x2="179.396"
          y2="142.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF6FF" />
          <stop offset="1" stopColor="#A4CFF8" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_103_8576"
          x1="187.346"
          y1="146.822"
          x2="179.048"
          y2="141.264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_103_8576"
          x1="188.3"
          y1="149.874"
          x2="183.683"
          y2="145.833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#679B69" />
          <stop offset="1" stopColor="#ACE4E3" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_103_8576"
          x1="188.3"
          y1="149.874"
          x2="179.016"
          y2="149.719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_103_8576"
          x1="191.095"
          y1="112.824"
          x2="176.793"
          y2="119.542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_103_8576"
          x1="196.273"
          y1="116.445"
          x2="178.75"
          y2="94.4544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_103_8576"
          x1="210.664"
          y1="133.177"
          x2="167.297"
          y2="128.51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_103_8576"
          x1="195.124"
          y1="118.553"
          x2="189.373"
          y2="116.474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#679B69" />
          <stop offset="1" stopColor="#ACE4E3" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_103_8576"
          x1="195.124"
          y1="118.553"
          x2="183.367"
          y2="120.752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_103_8576"
          x1="234.855"
          y1="110.728"
          x2="245.168"
          y2="95.1678"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365F61" />
          <stop offset="1" stopColor="#C3E7C9" />
          <stop offset="1" stopColor="#675C34" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_103_8576"
          x1="175.92"
          y1="178.487"
          x2="177.786"
          y2="194.713"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_103_8576"
          x1="165.616"
          y1="165.403"
          x2="185.489"
          y2="182.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_103_8576"
          x1="155.404"
          y1="192.44"
          x2="185.137"
          y2="185.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_103_8576"
          x1="159.206"
          y1="198.241"
          x2="185.25"
          y2="184.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#94C3C7" />
          <stop offset="1" stopColor="#3F858A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Memo = memo(OrthodoxyBannerImage);
export default Memo;
