import {
  SupervisionRequestResponseDtoTypeEnum,
  TherapistWorkingInfoRequestDtoLanguagesEnum,
  TherapistWorkingInfoRequestDtoWorkWithReligionsEnum,
} from '@app/api';

export const SELECT_OPTIONS = {
  religions: [
    {
      value: TherapistWorkingInfoRequestDtoWorkWithReligionsEnum.Christianity,
      label: 'Христианство',
    },
    {
      value: TherapistWorkingInfoRequestDtoWorkWithReligionsEnum.Islamism,
      label: 'Ислам',
    },
    {
      value: TherapistWorkingInfoRequestDtoWorkWithReligionsEnum.Judaism,
      label: 'Иудаизм',
    },
    {
      value: TherapistWorkingInfoRequestDtoWorkWithReligionsEnum.Buddhism,
      label: 'Буддизм',
    },
  ],
  languages: [
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.English,
      label: 'Английский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.German,
      label: 'Немецкий',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Russian,
      label: 'Русский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Kazakh,
      label: 'Казахский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Tatar,
      label: 'Татарский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Armenian,
      label: 'Армянский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.French,
      label: 'Французский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Spanish,
      label: 'Испанский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Italian,
      label: 'Итальянский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Chinese,
      label: 'Китайский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Arab,
      label: 'Арабский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Azerbaijani,
      label: 'Азербайджанский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Georgian,
      label: 'Грузинский',
    },
    {
      value: TherapistWorkingInfoRequestDtoLanguagesEnum.Uzbek,
      label: 'Узбекский',
    },
  ],
  superVisionTypeOptions: [
    {
      value: SupervisionRequestResponseDtoTypeEnum.Group,
      label: 'Групповая',
    },
    {
      value: SupervisionRequestResponseDtoTypeEnum.Personal,
      label: 'Персональная',
    },
  ],
};
