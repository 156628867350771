import { RootState } from '@app/store';

export const selectToken = (state: RootState) => state.auth.accessToken;
export const selectRole = (state: RootState) => state.auth.user.role;
export const selectUser = (state: RootState) => state.auth.user;
export const selectMetadata = (state: RootState) => state.auth.metadata;

export const selectSignUpContext = (state: RootState) =>
  state.auth.signUpContext;
export const selectPasswordRecoveryContext = (state: RootState) =>
  state.auth.passwordRecoveryContext;

export const selectUnauthorized = (state: RootState) => state.auth.unauthorized;

export const selectLoginRole = (state: RootState) => state.auth.loginRole;
export const selectActiveTab = (state: RootState) =>
  state.auth.modals.authModalActiveView;
export const selectAuthModalState = (state: RootState) =>
  state.auth.modals.isAuthModalOpen;
