import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgGear: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.75 7.683c-1.508 0-2.125-1.067-1.375-2.375a1.58 1.58 0 0 0-.583-2.158l-1.442-.825c-.658-.392-1.508-.159-1.9.5l-.091.158c-.75 1.308-1.984 1.308-2.742 0l-.092-.158a1.368 1.368 0 0 0-1.883-.5L5.2 3.15a1.59 1.59 0 0 0-.583 2.166c.758 1.3.142 2.367-1.367 2.367-.866 0-1.583.708-1.583 1.583v1.467c0 .867.708 1.583 1.583 1.583 1.509 0 2.125 1.067 1.367 2.375A1.58 1.58 0 0 0 5.2 16.85l1.442.825c.658.392 1.508.159 1.9-.5l.092-.158c.75-1.308 1.983-1.308 2.741 0l.092.159c.392.658 1.242.891 1.9.5l1.442-.826a1.583 1.583 0 0 0 .583-2.158c-.758-1.308-.142-2.375 1.367-2.375.866 0 1.583-.708 1.583-1.583V9.266a1.6 1.6 0 0 0-1.592-1.583ZM10 12.708A2.714 2.714 0 0 1 7.292 10 2.714 2.714 0 0 1 10 7.29 2.714 2.714 0 0 1 12.71 10 2.714 2.714 0 0 1 10 12.708Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgGear);
export default Memo;
