export const COLORS = {
  grayscaleBlack: '#15181E',
  grayscaleBlack80: 'rgba(57, 64, 73, 0.8)',
  grayscaleBlack40: 'rgba(57, 64, 73, 0.4)',
  grayscaleVeryDarkBlue: '#292D32',
  grayscale700: '#212731',
  grayscale600: '#394049',
  grayscale500: '#657281',
  grayscale400: '#8D98A5',
  grayscale300: '#C4CBD4',
  grayscale200: '#E1E5EA',
  grayscale100: '#F0F2F5',
  grayscale0: '#FBFCFE',
  grayscaleWhite: '#FFFFFF',
  primary500: '#1D6761',
  primary400: '#119386',
  primary300: '#3DAE9F',
  primary200: '#B4E4E1',
  primary100: '#D0EBE9',
  primary50: '#ECF7F6',
  primary25: '#F3FCFB',
  systemSuccess: '#00B26B',
  systemWarning: '#FCDA73',
  isTop: '#FFF495',
  darkRed: '#B8362D',
  systemError: '#F06060',
  systemErrorLight: '#F2A1A1',
  systemErrorExtraLightRed: '#FFF6F6',
  elementsStates: '#3DAE9F',
  elementsStatesHover: '#56B39E',
  elementsStatesPressed: '#439D8E',
  elementsStatesFocusedBorder: '#0077EA33',
  shadowDark: '#f4f5f6',
  whiteDark600: '#F6F6F3',
  black800: '#001F1B',
  white20: '#FFFFFF33',
  white40: '#FFFFFF66',
  white50: '#FFFFFF80',
  white80: '#FFFFFFCC',
  black20: '#15181E33',
  black40: '#15181E66',
  black50: '#15181E80',
  black80: '#15181ECC',
  lightGreen: '#E1FAEE',
  darkGreen: '#22494A',
  green: '#64AB8A',

  lightGreen10: 'rgba(61, 174, 159, 0.1)',
  lightGreen30: 'rgba(61, 174, 159, 0.3)',
  lightGreen50: 'rgba(61, 174, 159, 0.5)',

  red0: '#F76E6E',
  red100: '#FF7070',
  blue300: '#6AB5FB',
  blue400: '#389DFF',

  gray1: '#212731',

  // askona
  askonaLight: '#85C5CA',
};
