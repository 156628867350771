import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgLogoOnlineBusiness: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={254}
      height={87}
      viewBox="0 0 254 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)" fill="#1E1E1C">
          <path d="M34.575 28.848v33.76h-5.773V34.066H5.788v28.54H0V28.848h34.575ZM35.083 50.138c0-8.79 5.05-14.3 14.717-14.3 9.713 0 14.716 5.51 14.716 14.3 0 8.744-5.003 14.209-14.716 14.209-9.668 0-14.717-5.45-14.717-14.209Zm24.23 0c0-6.42-3.587-9.575-9.513-9.575-5.88 0-9.468 3.14-9.468 9.575 0 6.327 3.587 9.467 9.468 9.467 5.926.016 9.513-3.125 9.513-9.467ZM80.372 50.138c0-8.79 5.05-14.3 14.717-14.3 9.713 0 14.716 5.51 14.716 14.3 0 8.744-5.003 14.209-14.716 14.209-9.668 0-14.717-5.45-14.717-14.209Zm24.23 0c0-6.42-3.587-9.575-9.513-9.575-5.88 0-9.468 3.14-9.468 9.575 0 6.327 3.587 9.467 9.468 9.467 5.926.016 9.513-3.125 9.513-9.467ZM135.098 50.138c0-8.79 5.049-14.3 14.716-14.3 9.714 0 14.717 5.51 14.717 14.3 0 8.744-5.003 14.209-14.717 14.209-9.667 0-14.716-5.45-14.716-14.209Zm24.23 0c0-6.42-3.587-9.575-9.514-9.575-5.88 0-9.467 3.14-9.467 9.575 0 6.327 3.587 9.467 9.467 9.467 5.912.016 9.514-3.125 9.514-9.467ZM134.959 55.895c0 4.634-4.064 7.05-10.129 7.05h-14.286V37.239h13.223c5.665 0 9.714 2.324 9.714 6.542 0 2.94-2.078 5.588-5.865 5.85 4.018.246 7.343 2.094 7.343 6.265Zm-19.243-8.097h6.774c3.448 0 5.772-.492 5.772-3.325 0-2.832-2.309-3.002-5.772-3.002h-6.774v6.327Zm13.886 7.666c0-2.894-2.309-3.54-6.173-3.54h-7.697v7.081h7.697c3.848 0 6.173-.647 6.173-3.54Z" />
          <path d="M65.178 62.961V37.238H82.31v4.233h-11.96V62.961h-5.173ZM170.626 58.081v13.932h-5.465v-34.96h2.863l2.602 4.588c2.201-3.233 5.203-5.511 10.668-5.511 8.313 0 13.839 5.418 13.839 13.746 0 8.267-5.511 13.747-13.839 13.747-5.48-.03-8.467-2.309-10.668-5.542Zm0-9.282v2.155c.585 5.88 5.018 7.974 9.267 7.974 5.619 0 9.914-3.233 9.914-9.067s-4.711-9.113-9.914-9.113c-4.264-.016-8.59 2.74-9.267 8.05ZM224.613 37.361v25.6h-3.402l-2.063-4.957c-2.247 3.649-6.45 5.88-11.684 5.88-7.804 0-11.684-5.002-11.684-11.914V37.346h5.419V51.6c0 5.049 2.571 7.758 7.666 7.758 4.387 0 8.082-2.063 10.283-6.265V37.36h5.465Z" />
          <path d="M253.862 37.33v25.677h-4.957V44.704l-12.207 17.58h-.262l-12.208-17.857v18.58h-2.755l-2.063-4.987V37.33h5.326l11.915 17.488 11.931-17.488h5.28ZM172.95 81.542a5.328 5.328 0 0 1-5.326 5.326 5.328 5.328 0 0 1-5.326-5.326 5.328 5.328 0 0 1 5.326-5.327 5.318 5.318 0 0 1 5.326 5.327ZM237.036 13.716h2.54v7.99h11.869v-7.99H254v19.458h-2.555v-9.267h-11.869v9.267h-2.54V13.716ZM164.469 13.716h2.555v7.99h11.869v-7.99h2.555v19.458h-2.555v-9.267h-11.869v9.267h-2.555V13.716ZM221.889 32.974c-1.155.323-1.678.4-2.356.4-1.678 0-2.54-1.108-2.54-3.31-2.54 2.74-4.695 3.664-8.512 3.664-4.696 0-7.605-2.186-7.605-5.696 0-2.309 1.031-3.894 3.187-4.849 1-.43 2.355-.754 4.849-1.077l4.064-.555c3.063-.43 3.987-1.03 3.987-2.663 0-2.263-2.032-3.463-5.773-3.463-4.541 0-6.604 1.354-6.927 4.572h-2.602c.323-2.11.724-3.14 1.678-4.172 1.632-1.909 3.987-2.663 7.959-2.663 3.54 0 5.803.8 7.081 2.54.877 1.154 1.031 1.986 1.031 5.018v8.76c0 1.154.401 1.708 1.278 1.708.277 0 .631-.077 1.201-.246v2.032Zm-4.896-8.99c0-.678.047-.955.077-1.678-1.031.723-1.831 1-4.541 1.431l-3.617.555c-4.218.677-5.496 1.508-5.496 3.586 0 2.356 1.986 3.695 5.419 3.695 2.832 0 5.326-1.031 7.05-2.94.831-1 1.078-1.509 1.078-2.633l.03-2.016ZM163.699 23.614c0 5.804-4.418 10.114-10.421 10.114-6.127 0-10.391-4.172-10.391-10.19 0-6.174 4.295-10.392 10.514-10.392 6.08.016 10.298 4.264 10.298 10.468Zm-18.272-.184c0 4.695 3.309 8.035 7.958 8.035 4.418 0 7.728-3.387 7.728-7.928 0-4.741-3.217-8.112-7.758-8.112-4.588 0-7.928 3.386-7.928 8.005Z" />
          <path d="m192.902 13.716 8.913 19.458h-2.771l-5.649-12.5c-.431-1-.801-1.832-1.878-4.572-.878 2.355-1.309 3.464-1.878 4.741l-5.496 12.33h-2.709l8.79-19.457h2.678ZM237.221 33.174v-4.095c-.908 1.386-1.509 2.032-2.509 2.71-1.586 1.108-3.864 1.754-6.127 1.754-4.972 0-7.481-2.309-7.481-6.88V13.73h2.586v12.377c0 3.618 1.708 5.25 5.495 5.25 3.218 0 6.004-1.432 7.251-3.695.4-.754.6-1.709.6-2.94V13.716h2.54v19.458h-2.355Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h253.972v86.86H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h254v86.868H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgLogoOnlineBusiness);
export default Memo;
