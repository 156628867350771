import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgSort: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.246 5.78a.5.5 0 1 0 .707.707L8 4.441l2.046 2.046a.5.5 0 0 0 .707-.707l-2.4-2.4a.5.5 0 0 0-.707 0l-2.4 2.4Zm5.507 4.44a.5.5 0 0 0-.707-.707L8 11.56 5.953 9.513a.5.5 0 1 0-.707.707l2.4 2.4a.5.5 0 0 0 .707 0l2.4-2.4Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgSort);
export default Memo;
