export const TYPOGRAPHY = {
  heading1Bold32: `
    font-size: 32px;
    line-height:40px;
    font-weight: 700;
  `,
  heading1Regular32: `
    font-size: 32px;
    line-height:40px;
    font-weight: 400;
  `,
  heading2Bold28: `
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
  `,
  heading2SemiBold28: `
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
  `,
  heading3Bold24: `
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  `,
  heading3SemiBold24: `
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  `,
  heading3Regular24: `
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
  `,
  heading4Semibold22: `
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
  `,
  heading4Semibold20: `
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  `,
  heading4Medium20: `
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  `,
  headline1Semibold18: `
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  `,
  headline1Medium18: `
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  `,
  headline1Regular18: `
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  `,
  headline2Semibold16: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  `,
  headline2Medium16: `
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  `,
  body1Semibold16: `
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  `,
  body1Semibold15: `
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
  `,
  body1Medium15: `
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  `,
  body1Medium14: `
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  `,
  body1Regular16: `
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  body1Regular15: `
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  `,
  body1Semibold14: `
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  `,
  body1Bold14: `
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  `,
  body1Regular14: `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  `,
  body1Regular13: `
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  `,
  footnoteMedium13: `
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
  `,
  footnoteRegular13: `
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  `,
  bagesSemibold12: `
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  `,
  disclaimerRegular12: `
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
  disclaimerMedium12: `
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,

  // banners
  bodyB7medium12: `
    font-family: 'Gilroy', Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
  `,
  bodyB7bold12: `
    font-family: 'Gilroy', Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 22px;
  `,
  // banners mobile
  bodyB12medium14: `
    font-family: 'Gilroy', Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  `,
  bodyB12bold14: `
    font-family: 'Gilroy', Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
  `,
};
