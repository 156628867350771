import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgRecommend: FC<IconComponent> = ({
  color = COLORS.primary300,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      {...props}
    >
      <path
        d="M12.9565 2.71186C12.9565 1.99263 12.659 1.30286 12.1295 0.794287C11.6001 0.285713 10.8819 0 10.1332 0H9.24664L8.74127 0.696949L4.22397 6.93424L3.92188 7.35458V16H14.6505L15.0881 15.0075L17.1914 10.1776V10.1641V10.1505C17.4102 9.6252 17.491 9.05624 17.4266 8.49385C17.3623 7.93146 17.1548 7.39293 16.8225 6.9258C16.4902 6.45866 16.0432 6.07728 15.5212 5.81532C14.9991 5.55336 14.4179 5.41887 13.8289 5.42373H12.5273L12.6374 4.95186C12.8488 4.2225 12.9561 3.46896 12.9565 2.71186ZM13.8289 7.05085C14.1501 7.04625 14.4675 7.11814 14.7528 7.26011C15.038 7.40207 15.2822 7.60969 15.4636 7.86441C15.6326 8.1099 15.7373 8.39101 15.7688 8.68378C15.8002 8.97655 15.7575 9.27228 15.6443 9.54576L13.5211 14.3729H5.61586V7.86441L10.1332 1.62712C10.4327 1.62712 10.7199 1.7414 10.9317 1.94483C11.1435 2.14826 11.2625 2.42417 11.2625 2.71186C11.2593 3.34338 11.1643 3.97137 10.9802 4.57763L10.4155 7.05085H13.8289Z"
        fill={color}
      />
      <path
        d="M2.22817 7.32129H0.53418V15.9993H2.22817V7.32129Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgRecommend);
export default Memo;
