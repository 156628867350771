import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';
import { BreakpointsEnum } from '@app/types';
import { useClientSize } from '@app/hooks';

const CustomToasterContainer = () => {
  const { getIsBreakpoint } = useClientSize();
  const isMobile = getIsBreakpoint('sm');

  return (
    <StyledToastContainer
      closeButton={false}
      icon={false}
      position={isMobile ? 'top-center' : 'bottom-right'}
      hideProgressBar
      closeOnClick={false}
    />
  );
};

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
})`
  /* .toast-container */
  width: unset;
  padding: 0;

  /* .toast is passed to toastClassName */
  .toast {
    cursor: unset;
    background: unset;
    box-shadow: unset;
    padding: 0;
    font-family: unset;
  }

  &__toast-container {
    z-index: 1000;
  }

  /* .body is passed to bodyClassName */
  .body {
    padding: 0;
  }

  @media (min-width: ${BreakpointsEnum.sm}px) {
    bottom: 40px;
    right: 40px;
  }
  @media (max-width: ${BreakpointsEnum.sm}px) {
    top: 16px;
    right: 16px;
    left: 16px;
  }
`;

export default CustomToasterContainer;
