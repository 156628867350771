export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
] as const;

export const monthNamesWithNum = [
  'Января',
  'Февраля',
  'Марта',
  'Апреля',
  'Мая',
  'Июня',
  'Июля',
  'Августа',
  'Сентября',
  'Октября',
  'Ноября',
  'Декабря',
] as const;

export const weekdayNames = [
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
  'Воскресенье',
] as const;

export const weekdayShortNames = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Сб',
  'Вc',
] as const;

export const weekIndexes = [7, 14, 21, 28, 35, 42];

export const numDaysToAddFromPreviousMonth = [0, 1, 2, 3, 4, 5, 6];
export const numDaysToAddFromNextMonth = [6, 5, 4, 3, 2, 1, 0];

export const timeline = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const recurringOptions = [
  {
    label: 'Только выбранное окно',
    value: 'once',
  },
  {
    label: 'Это и все последующие повторяющиеся окна',
    value: 'future',
  },
  {
    label: 'Все',
    value: 'each',
  },
];

export const startOptions = [
  {
    label: '00:00',
    value: '0',
  },
  {
    label: '01:00',
    value: '1',
  },
  {
    label: '02:00',
    value: '2',
  },
  {
    label: '03:00',
    value: '3',
  },
  {
    label: '04:00',
    value: '4',
  },
  {
    label: '05:00',
    value: '5',
  },
  {
    label: '06:00',
    value: '6',
  },
  {
    label: '07:00',
    value: '7',
  },
  {
    label: '08:00',
    value: '8',
  },
  {
    label: '09:00',
    value: '9',
  },
  {
    label: '10:00',
    value: '10',
  },
  {
    label: '11:00',
    value: '11',
  },
  {
    label: '12:00',
    value: '12',
  },
  {
    label: '13:00',
    value: '13',
  },
  {
    label: '14:00',
    value: '14',
  },
  {
    label: '15:00',
    value: '15',
  },
  {
    label: '16:00',
    value: '16',
  },
  {
    label: '17:00',
    value: '17',
  },
  {
    label: '18:00',
    value: '18',
  },
  {
    label: '19:00',
    value: '19',
  },
  {
    label: '20:00',
    value: '20',
  },
  {
    label: '21:00',
    value: '21',
  },
  {
    label: '22:00',
    value: '22',
  },
  {
    label: '23:00',
    value: '23',
  },
  {
    label: '00:00',
    value: '24',
  },
];

export const weekdayNamesEn = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;
