import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgMicrophoneSlashBold: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.683 5.35v.966l-6.067 6.067a3.98 3.98 0 0 1-1.3-2.933v-4.1a3.659 3.659 0 0 1 2.75-3.55c.159-.042.309.091.309.25v1.283a.63.63 0 0 0 .625.625.63.63 0 0 0 .625-.625V2.05c0-.159.15-.292.308-.25a3.659 3.659 0 0 1 2.75 3.55ZM16.508 8.175V9.5a6.507 6.507 0 0 1-5.925 6.475v1.775a.58.58 0 0 1-.584.583.58.58 0 0 1-.583-.583v-1.775a6.422 6.422 0 0 1-2.533-.775l.858-.859c.683.317 1.45.5 2.258.5 2.95 0 5.35-2.4 5.35-5.341V8.175c0-.317.259-.575.584-.575.317 0 .575.258.575.575Z"
        fill="#fff"
      />
      <path
        d="M13.683 8.4V9.61a3.69 3.69 0 0 1-4.05 3.666 3.111 3.111 0 0 1-.684-.141L13.683 8.4ZM18.143 1.858a.644.644 0 0 0-.908 0L6.027 13.067A5.268 5.268 0 0 1 4.652 9.5V8.175a.578.578 0 0 0-.584-.575.576.576 0 0 0-.575.575V9.5c0 1.692.65 3.233 1.709 4.392l-3.35 3.35a.644.644 0 0 0 0 .908.696.696 0 0 0 .458.183.632.632 0 0 0 .45-.191L18.143 2.758a.62.62 0 0 0 0-.9Z"
        fill="#fff"
      />
    </svg>
  );
};

const Memo = memo(SvgMicrophoneSlashBold);
export default Memo;
