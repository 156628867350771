import { parseCookies } from 'nookies';

export const getTokenFromCookies = () => {
  const cookies = parseCookies();

  const tokenFromCookies =
    cookies['auth.accessToken'] && JSON.parse(cookies['auth.accessToken']);

  return tokenFromCookies;
};
