import { usePusher } from '@app/libs/pusher/hooks/usePusher';
import { ROUTES } from '@app/routers';

import { actions, selectors } from '@app/store';
import { useGetMeQuery } from '@app/store/api/users';
import { useAppDispatch, useAppSelector } from '@app/store/hooks';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useApp = () => {
  const token = useAppSelector(selectors.auth.selectToken);
  const isUnauthorized = useAppSelector(selectors.auth.selectUnauthorized);
  const dispatch = useAppDispatch();
  const { push } = useRouter();
  useGetMeQuery(undefined, { skip: !token });

  usePusher();

  useEffect(() => {
    if (isUnauthorized) {
      dispatch(actions.auth.setUnauthorized(false));
      push(ROUTES['/logout']);
    }
  }, [isUnauthorized, push]);
};
