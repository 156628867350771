import { API_URL } from '@app/constants';
import {
  AvailabilitiesCreateResponseDto,
  CreateAvailabilitiesRequestDto,
  DeleteAvailabilityRequestDto,
  MessageSuccessResponseDto,
  TherapistScheduleResponseDto,
  UpdateAvailabilityRequestDto,
} from '@app/api';
import { HttpMethods } from '@app/types';
import { baseApi } from '../baseApi';

interface GetScheduleQueryParams {
  from?: string;
  to?: string;
}

export const scheduleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query<
      TherapistScheduleResponseDto,
      GetScheduleQueryParams
    >({
      query: (params) => ({
        url: API_URL.getSchedule,
        method: HttpMethods.get,
        params,
      }),
      providesTags: ['schedule'],
    }),
    createAvailabilities: builder.mutation<
      AvailabilitiesCreateResponseDto,
      CreateAvailabilitiesRequestDto
    >({
      query: (body) => ({
        url: API_URL.createAvailabilities,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['schedule'],
    }),
    updateAvailabilities: builder.mutation<
      MessageSuccessResponseDto,
      UpdateAvailabilityRequestDto & { availabilityId: string }
    >({
      query: (body) => ({
        url: API_URL.updateAvailabilities(body.availabilityId),
        method: HttpMethods.patch,
        body,
      }),
      invalidatesTags: ['schedule'],
    }),
    deleteAvailabilities: builder.mutation<
      MessageSuccessResponseDto,
      DeleteAvailabilityRequestDto & { availabilityId: string }
    >({
      query: (body) => ({
        url: API_URL.deleteAvailabilities(body.availabilityId),
        method: HttpMethods.delete,
        body,
      }),
      invalidatesTags: ['schedule'],
    }),
  }),
});

export const {
  useCreateAvailabilitiesMutation,
  useDeleteAvailabilitiesMutation,
  useUpdateAvailabilitiesMutation,
  useGetScheduleQuery,
} = scheduleApi;
