import { COLORS } from '@app/constants';
import Toast from '@app/ui/components/Toast';
import { CloseCircle, Spinner, TickCircle } from '@app/ui/iconComponents';
import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

interface ContentParams {
  title?: string;
  content?: string;
  icon?: ReactNode;
  withIcon?: boolean;
}

class ToastService {
  success = (
    {
      title,
      content,
      icon = <TickCircle color={COLORS.systemSuccess} />,
      withIcon = true,
    }: ContentParams,
    options?: ToastOptions,
  ) =>
    toast.success(
      ({ toastProps, closeToast }) => (
        <Toast
          toastProps={toastProps}
          closeToast={closeToast}
          data={{
            title,
            content,
            icon: icon && withIcon && icon,
          }}
        />
      ),
      { toastId: content, ...options },
    );
  error = (
    {
      title,
      content,
      icon = <CloseCircle color={COLORS.systemError} />,
      withIcon = true,
    }: ContentParams,
    options?: ToastOptions,
  ) =>
    toast.error(
      ({ toastProps, closeToast }) => (
        <Toast
          toastProps={toastProps}
          closeToast={closeToast}
          data={{
            title,
            content,
            icon: icon && withIcon && icon,
          }}
        />
      ),
      { toastId: content, ...options },
    );
  default = (
    {
      title,
      content,
      icon = <Spinner color={COLORS.gray1} />,
      withIcon = true,
    }: ContentParams,
    options?: ToastOptions,
  ) =>
    toast.error(
      ({ toastProps, closeToast }) => (
        <Toast
          toastProps={toastProps}
          closeToast={closeToast}
          data={{
            title,
            content,
            icon: icon && withIcon && icon,
          }}
        />
      ),
      { toastId: content, ...options },
    );
}
export const toastService = new ToastService();
