import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  emails?: Array<string>;
  quantity?: number;
}

const initialState = {} as InitialState;

const submitPromoCodesSlice = createSlice({
  name: 'submitPromoCodes',
  initialState,
  reducers: {
    setState(
      state,
      { payload: { emails, quantity } }: PayloadAction<Required<InitialState>>,
    ) {
      state.emails = emails;
      state.quantity = quantity;
    },
    reset(state) {
      state.emails = undefined;
      state.quantity = undefined;
    },
  },
});

export const { actions, reducer } = submitPromoCodesSlice;
