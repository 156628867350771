import { AppProps } from 'next/app';
import { wrapper } from '@app/store';
import '@app/libs';
import '@app/assets/styles/normalize.min.css';
import '@app/assets/styles/global.css';
import '@app/assets/styles/swiper.css';
import '@landing/assets/styles/react.modal.video.scss';
import ReactModal from 'react-modal';
import { useApp } from '@app/hooks/useApp';
import { CustomToasterContainer } from '@app/components/CustomToasterContainer';
import Head from 'next/head';
import Script from 'next/script';
import { useUtmSaveToCookie } from '@app/hooks/useUtmSaveToCookie';
import { DefaultSeo } from 'next-seo';
import { defaultSeoProps } from '../../next-seo.config';


ReactModal.setAppElement('#__next');

const MyAppApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <script
          id="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5HD38T5Q');`,
          }}
        />
      </Head>
      <style>
        {'.closeBox_f3be, .mobileBack_f0c7 { left: auto; right: 0; } * { -webkit-tap-highlight-color: transparent; }'}
      </style>
      <DefaultSeo {...defaultSeoProps} />
      <Component {...pageProps} />
      <CustomToasterContainer />
      <Script
        id="yandex-metrika"
        dangerouslySetInnerHTML={{
          __html: `
              (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();
                for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                ym(90937796, "init", {
                      clickmap:true,
                      trackLinks:true,
                      accurateTrackBounce:true,
                      webvisor:true
                });
                `,
        }}
      />
      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/90937796"
            style={{
              position: 'absolute',
              left: '-9999px',
            }}
            alt=""
          />
        </div>
      </noscript>
    </>
  );
};

const MyAppLanding = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <script
          id="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5HD38T5Q');`,
          }}
        />
      </Head>
      <DefaultSeo {...defaultSeoProps} />
      <Component {...pageProps} />
    </>
  );
};

function MyApp(props: AppProps) {
  useUtmSaveToCookie();
  useApp();

  return MyAppApp(props);
}

export default wrapper.withRedux(MyApp);
