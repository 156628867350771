import { actions, apiMiddleware, appReducer, selectors } from './ducks';
import { AnyAction, ThunkAction, configureStore } from '@reduxjs/toolkit';
import {
  nextReduxCookieMiddleware,
  wrapMakeStore,
} from 'next-redux-cookie-wrapper';
import { createWrapper } from 'next-redux-wrapper';
import { authMiddleware } from './middlewares/authMiddlewares';
import { errorMiddleware } from './middlewares/errorMiddleware';
import { addYears } from 'date-fns';

const makeStore = wrapMakeStore(() =>
  configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(
          nextReduxCookieMiddleware({
            subtrees: [
              {
                subtree: 'auth.accessToken',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'auth.user',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'auth.loginRole',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'auth.metadata',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'auth.passwordRecoveryContext',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'allTherapistsModalAfterSignUp.hasShown',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
              {
                subtree: 'schedule.isVisited',
                compress: false,
                secure: true,
                expires: addYears(new Date(), 1),
              },
            ],
          }),
        )
        .concat([authMiddleware, errorMiddleware, ...apiMiddleware]),
  }),
);

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkAction<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
export { actions, selectors };
