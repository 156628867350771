import { FC, memo } from 'react';
import { IconComponent } from '@app/types';

const IslamismBannerImage: FC<IconComponent> = (props) => {
  return (
    <svg
      width="208"
      height="387"
      viewBox="0 0 208 387"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M161.12 268.661C161.12 268.661 134.639 254.017 117.335 250.384C100.03 246.752 79.8215 244.937 68.9062 234.451C57.9909 223.964 51.1807 208.322 51.1807 208.322C51.1807 208.322 86.854 228.793 106.187 231.82C125.52 234.847 156.211 255.841 161.181 259.944C166.151 264.048 170.052 273.329 161.12 268.661Z"
        fill="url(#paint0_linear_301_2)"
      />
      <path
        d="M161.766 283.208C161.766 283.208 173.294 262.199 179.201 253.359C185.108 244.518 195.541 217.657 198.944 206.589C202.347 195.521 206.373 178.033 206.373 178.033C199.245 182.52 193.524 188.922 189.865 196.508C183.391 209.496 168.452 242.708 166.957 255.752C165.773 264.999 164.04 274.167 161.766 283.208Z"
        fill="url(#paint1_linear_301_2)"
      />
      <path
        d="M154.055 247.812C158.015 240.87 160.686 233.268 161.937 225.374C163.402 213.714 173.517 196.868 177.756 187.458C181.994 178.048 192.072 163.412 193.81 154.253C195.021 148.147 196.636 142.127 198.645 136.234C198.645 136.234 185.521 149.197 182.134 154.998C178.748 160.799 168.076 180.45 164.679 190.692C161.281 200.934 155.381 211.716 155.566 223.677C155.624 231.747 155.119 239.812 154.055 247.812Z"
        fill="url(#paint2_linear_301_2)"
      />
      <path
        d="M145.926 212.062C145.926 212.062 115.217 193.465 106.236 190.056C97.2538 186.647 71.3018 164.281 66.6568 155.078C62.0117 145.874 60.358 143.116 66.713 147.043C73.068 150.971 86.0458 161.885 96.2818 166.129C106.518 170.373 124.498 180.785 130.839 186.634C137.181 192.482 145.926 212.062 145.926 212.062Z"
        fill="url(#paint3_linear_301_2)"
      />
      <path
        d="M154.115 238.553C154.115 238.553 113.262 223.412 101.78 221.406C90.2973 219.4 54.2433 198.673 46.5945 188.758C38.9458 178.843 36.3417 175.931 44.8143 179.159C53.287 182.386 71.2544 192.429 84.4226 195.143C97.5907 197.857 121.401 206.153 130.299 211.675C139.198 217.198 154.115 238.553 154.115 238.553Z"
        fill="url(#paint4_linear_301_2)"
      />
      <path
        d="M140.543 189.74C140.543 189.74 133.687 177.753 122.874 173.237C112.062 168.72 92.1681 160.257 81.9519 150.229C71.7357 140.202 66.2763 129.299 63.2615 126.221C60.2467 123.143 69.9237 124.615 77.3863 129.666C84.8488 134.717 89.2584 139.189 98.6683 143.406C108.078 147.624 124.685 153.585 131.575 163.631C138.466 173.676 140.543 189.74 140.543 189.74Z"
        fill="url(#paint5_linear_301_2)"
      />
      <path
        d="M127.705 149.764C127.705 149.764 117.463 137.465 111.156 135.479C104.85 133.493 88.1676 129.493 80.9814 123.329C73.7952 117.165 61.1427 104.043 56.7195 101.513C52.2963 98.9824 72.0024 98.8516 83.0551 104.465C94.1077 110.077 110.997 119.904 116.229 124.959C121.46 130.015 127.705 149.764 127.705 149.764Z"
        fill="url(#paint6_linear_301_2)"
      />
      <path
        d="M123.508 109.745C123.508 109.745 132.774 93.1619 135.08 82.3547C137.387 71.5474 144.701 53.5046 145.355 39.6702C146.01 25.8357 145.474 22.7336 145.474 22.7336C145.474 22.7336 143.431 37.178 138.112 44.6181C132.793 52.0581 125.267 62.0233 125.5 75.9053C125.733 89.7872 118.865 106.119 119.357 115.562C119.848 125.005 123.508 109.745 123.508 109.745Z"
        fill="url(#paint7_linear_301_2)"
      />
      <path
        d="M130.233 145.773C132.817 140.393 134.365 134.574 134.794 128.62C135.124 119.761 133.555 107.811 136.12 98.3899C138.686 88.9683 147.203 61.2878 147.812 54.0627C148.42 46.8376 146.859 30.7363 149.345 32.6953C151.831 34.6543 157.307 46.0736 157.42 59.397C157.267 72.4322 155.405 85.3911 151.884 97.9425C149.324 106.538 141.722 121.46 139.991 129.792C138.259 138.125 130.233 145.773 130.233 145.773Z"
        fill="url(#paint8_linear_301_2)"
      />
      <path
        d="M147.538 217.756C147.538 217.756 148.629 185.852 155.104 172.574C161.579 159.297 171.394 141.044 177.261 134.971C183.129 128.898 191.821 120.614 191.821 120.614C191.821 120.614 189.511 134.768 184.156 144.438C178.801 154.109 169.818 171.541 163.925 181.228C158.033 190.916 152.367 218.327 151.657 222.453C150.946 226.579 147.538 217.756 147.538 217.756Z"
        fill="url(#paint9_linear_301_2)"
      />
      <path
        d="M142.189 178.946C142.189 178.946 154.76 153.484 159.984 136.046C165.207 118.608 167.738 96.4212 167.533 87.2694C167.329 78.1177 167.671 67.5653 167.671 67.5653C164.205 72.7344 161.882 78.5842 160.859 84.7234C159.7 93.8656 154.294 110.765 150.893 121.565C147.492 132.364 141.833 147.052 140.92 159.542C140.221 166.035 140.65 172.6 142.189 178.946Z"
        fill="url(#paint10_linear_301_2)"
      />
      <path
        d="M156.686 385.686C156.686 385.686 155.429 355.727 157.023 337.478C158.617 319.23 156.698 280.488 152.453 261.456C148.207 242.423 143.945 222.667 143.945 222.667C143.945 222.667 139.164 212.306 139.292 193.986C139.42 175.666 129.033 160.473 124.757 145.819C120.481 131.166 115.527 107.047 115.527 107.047C115.527 107.047 99.5804 89.3989 95.2988 75.4886C91.0171 61.5783 83.1873 35.2087 83.1873 35.2087C83.1873 35.2087 90.5039 34.537 100.631 48.4881C110.758 62.4393 115.598 88.0651 118.826 104.88C122.055 121.696 121.992 121.757 127.751 134.211C133.509 146.665 144.013 171.629 144.727 181.837C145.44 192.046 147 199.307 154.113 224.928C161.225 250.549 165.512 266.649 166.871 282.005C168.23 297.362 173.656 357.3 170.652 367.503C167.647 377.707 156.686 385.686 156.686 385.686Z"
        fill="url(#paint11_linear_301_2)"
      />
      <path
        d="M97.3039 108.456C97.3039 108.456 108.43 171.26 117.704 187.855C126.977 204.449 139.576 213.695 149.22 239.356C158.865 265.016 159.652 286.651 165.208 297.27C166.411 299.561 173.294 297.396 175.332 296.223C177.682 294.873 177.067 290.829 176.306 288.237C172.525 274.804 163.958 241.622 152.334 221.381C146.812 211.764 132.142 191.882 127.172 185.731C123.11 180.703 120.006 174.534 113.541 148.086C107.268 122.429 104.363 106.372 104.363 106.372L97.3039 108.456Z"
        fill="url(#paint12_linear_301_2)"
      />
      <path
        d="M101.554 33.8223C101.554 33.8223 102.312 29.4151 106.43 19.6438C108.796 14.1165 110.718 8.40871 112.178 2.5748C112.178 2.5748 118.521 19.9942 120.987 23.879C123.454 27.7639 125.938 36.8188 125.938 36.8188C125.938 36.8188 121.385 46.718 119.17 53.8351C117.849 58.3338 117.589 63.0787 118.411 67.6962L113.571 66.6685L105.736 59.0036L98.4582 49.0796L101.687 34.494L101.554 33.8223Z"
        fill="url(#paint13_linear_301_2)"
      />
      <path
        d="M49.0115 51.6934C49.0115 51.6934 48.3179 38.0304 47.9196 34.944C47.5213 31.8576 42.641 16.9505 41.8391 15.7453C41.0372 14.5402 43.2984 13.7048 48.2321 16.7936C53.5166 20.0516 58.4162 23.8668 62.8444 28.1721C67.8473 33.025 74.0708 42.3641 75.8301 44.3776C77.0304 45.8625 78.0221 47.4993 78.7795 49.2453L79.0511 55.3571L75.3598 71.3599L68.3217 72.6722L49.0115 51.6934Z"
        fill="url(#paint14_linear_301_2)"
      />
      <path
        d="M77.8626 48.1569C77.8626 48.1569 76.3265 37.6028 80.6513 22.1872C84.9761 6.77152 87.9478 1.53084 87.9478 1.53084C87.9478 1.53084 88.1291 -0.78929 90.873 8.56398C93.6169 17.9172 100.126 28.8633 100.126 28.8633C100.126 28.8633 105.789 42.2671 105.327 46.5922C104.865 50.9173 98.1103 68.2726 98.1103 68.2726L84.2792 61.2393L77.8626 48.1569Z"
        fill="url(#paint15_linear_301_2)"
      />
      <path
        d="M25.163 62.7985C25.163 62.7985 22.4299 57.0271 21.4225 52.4762C20.4151 47.9254 19.7822 45.695 17.4006 43.6222C15.019 41.5495 12.197 37.7087 16.8291 38.105C21.4611 38.5013 34.6492 41.5254 38.5264 43.5005C42.4036 45.4755 48.1912 46.8972 55.2974 53.9794C62.4037 61.0616 65.7353 65.9113 65.7353 65.9113L56.4757 76.3024L43.7321 71.0303L25.6025 63.7141L25.163 62.7985Z"
        fill="url(#paint16_linear_301_2)"
      />
      <path
        d="M8.28641 53.3774C8.28641 53.3774 16.5969 58.7854 21.0892 60.09C25.5814 61.3946 37.9185 61.3007 43.9316 64.2443C49.9447 67.1879 53.6688 67.2776 57.4957 71.7797C59.5978 74.153 61.3502 76.8111 62.7008 79.6751L69.1234 91.7203L28.5615 83.9366C28.5615 83.9366 19.2618 76.0673 16.5608 69.5439C13.8598 63.0205 6.62887 55.8269 8.28641 53.3774Z"
        fill="url(#paint17_linear_301_2)"
      />
      <path
        d="M73.1328 65.1751C73.111 62.0736 73.7631 59.0065 75.0436 56.1878C77.1726 51.5195 78.3453 48.3665 78.3453 48.3665C78.3453 48.3665 81.0565 49.8457 86.6928 53.5574C92.329 57.2691 95.8124 64.5151 95.8124 64.5151C95.8124 64.5151 91.6896 74.1657 91.9655 76.8937C92.0202 77.9561 92.1711 79.0115 92.4164 80.0469L89.4185 80.4065L84.3292 82.4772C84.3292 82.4772 79.888 73.5336 75.724 71.412C71.5599 69.2903 73.3259 65.8333 73.3259 65.8333L73.1328 65.1751Z"
        fill="url(#paint18_linear_301_2)"
      />
      <path
        d="M62.3004 88.3376C62.3004 88.3376 56.8909 81.0052 55.8474 74.4961C55.2273 71.0269 54.9954 67.5008 55.156 63.9841C55.156 63.9841 61.6644 64.5596 65.9591 66.1076C68.7559 67.1095 71.4801 68.3093 74.1116 69.6982C76.9715 71.2435 79.5374 73.0689 80.5258 74.9621C81.8392 77.5437 83.021 80.1908 84.0664 82.8931C84.0664 82.8931 80.3102 85.4313 79.0375 89.3451C77.7649 93.2589 76.5103 97.4762 76.5103 97.4762L62.3004 88.3376Z"
        fill="url(#paint19_linear_301_2)"
      />
      <path
        d="M97.3086 58.4953C97.3086 58.4953 102.517 51.053 104.198 47.3969C104.944 45.6085 105.887 43.9146 107.01 42.3477C107.01 42.3477 110.033 47.96 110.53 50.8109C111.028 53.6618 114.52 61.0427 114.52 61.0427L113.589 71.1296C113.589 71.1296 107.827 82.1717 107.2 85.7639C106.574 89.3562 102.836 81.4263 98.099 81.2127C93.3624 80.9991 91.6221 80.7947 91.6221 80.7947C91.6221 80.7947 89.8714 67.1257 97.3086 58.4953Z"
        fill="url(#paint20_linear_301_2)"
      />
      <path
        d="M113.646 70.2898C113.646 70.2898 112.75 60.6888 119.123 47.4355C125.495 34.1823 148.003 13.2565 150.223 11.9098C152.443 10.5631 153.639 11.6603 152.207 15.1057C150.774 18.5512 147.7 21.7254 149.617 30.0666C151.533 38.4077 144.821 49.9899 144.821 49.9899L113.646 70.2898Z"
        fill="url(#paint21_linear_301_2)"
      />
      <path
        d="M74.8056 96.8041C74.8056 96.8041 76.6272 82.878 85.335 79.816C94.0428 76.754 105.538 80.7753 106.568 82.9541C107.598 85.1329 106.72 92.9464 106.72 92.9464L94.628 106.953L80.2468 103.038L75.0788 97.76"
        fill="url(#paint22_linear_301_2)"
      />
      <path
        d="M110.953 75.6253C110.953 75.6253 111.95 68.8552 122.332 60.0154C132.715 51.1756 141.778 45.5513 150.974 41.6301C160.17 37.7088 175.472 33.0398 176.483 35.8958C177.494 38.7517 163.968 41.6687 160.266 45.2091C156.564 48.7495 157.171 56.7621 151.209 62.7649C145.246 68.7678 124.504 86.5487 117.679 91.9697C113.906 95.0164 109.887 97.7458 105.663 100.13C105.663 100.13 105.995 88.099 107.147 84.2632C108.17 81.2822 109.443 78.3927 110.953 75.6253Z"
        fill="url(#paint23_linear_301_2)"
      />
      <path
        d="M92.6532 108.762C92.6532 108.762 96.0206 99.366 103.959 94.7583C109.871 91.3294 116.412 84.9323 120.688 81.2908C132.492 71.2441 148.526 67.997 159.518 67.527C170.51 67.0571 190.975 63.152 191.269 65.9502C191.563 68.7484 178.573 68.4778 168.467 75.3366C158.361 82.1954 149.074 89.1891 145.168 92.8156C141.262 96.4422 106.913 107.853 100.444 110.018C93.9739 112.182 92.9811 108.479 92.9811 108.479L92.6532 108.762Z"
        fill="url(#paint24_linear_301_2)"
      />
      <path
        d="M1.58903 87.6953C1.58903 87.6953 18.656 89.9532 31.3474 101.779C36.4003 106.478 53.2904 108.231 63.2024 111.298C70.2268 113.458 80.2503 113.36 88.6044 112.707C92.7254 112.384 93.3913 106.625 89.697 104.77C86.288 103.059 82.7988 101.054 79.8908 98.8523C70.7224 91.9103 56.0897 85.2525 46.5221 84.1738C36.9546 83.0951 0.992862 82.951 1.58903 87.6953Z"
        fill="url(#paint25_linear_301_2)"
      />
      <path
        d="M65.148 125.209C65.148 125.209 77.9056 124.059 82.0318 120.256C86.1581 116.452 94.6236 111.403 94.6236 111.403C94.6236 111.403 84.009 111.888 77.2839 109.449C70.5588 107.01 54.1241 99.3401 37.479 103.2C20.8338 107.06 16.9463 109.991 13.3583 110.276C9.77028 110.561 3.58141 111.759 2.41366 110.467C1.24592 109.176 -1.76357 114.073 4.23308 116.669C10.2297 119.265 24.6079 118.678 28.6693 118.027C32.7307 117.375 39.2714 116.472 42.2485 117.674C45.2256 118.875 54.3224 125.805 59.7381 125.517L65.148 125.209Z"
        fill="url(#paint26_linear_301_2)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_301_2"
          x1="121.429"
          y1="208.379"
          x2="97.9441"
          y2="264.48"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.52" stopColor="#697238" />
          <stop offset="0.68" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_301_2"
          x1="213.074"
          y1="238.889"
          x2="145.213"
          y2="219.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#858C49" />
          <stop offset="0.59" stopColor="#1D1F15" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_301_2"
          x1="182.544"
          y1="193.812"
          x2="163.909"
          y2="188.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#858C49" />
          <stop offset="0.97" stopColor="#3C421D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_301_2"
          x1="111.734"
          y1="165.783"
          x2="96.9507"
          y2="191.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_301_2"
          x1="102.749"
          y1="191.183"
          x2="91.0171"
          y2="225.316"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_301_2"
          x1="107.859"
          y1="146.527"
          x2="97.2992"
          y2="164.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_301_2"
          x1="98.8371"
          y1="113.126"
          x2="89.8248"
          y2="128.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_301_2"
          x1="152.951"
          y1="70.0246"
          x2="54.7337"
          y2="73.1795"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.19" stopColor="#697238" />
          <stop offset="0.68" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_301_2"
          x1="157.643"
          y1="92.5917"
          x2="128.166"
          y2="86.9739"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.39" stopColor="#697238" />
          <stop offset="0.95" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_301_2"
          x1="177.176"
          y1="173.761"
          x2="156.047"
          y2="167.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#858C49" />
          <stop offset="0.97" stopColor="#3C421D" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_301_2"
          x1="162.99"
          y1="125.574"
          x2="149.838"
          y2="121.785"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.38" stopColor="#858C49" />
          <stop offset="0.97" stopColor="#3C421D" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_301_2"
          x1="159.163"
          y1="199.785"
          x2="114.856"
          y2="211.826"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.59" stopColor="#1D1F15" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_301_2"
          x1="145.611"
          y1="197.151"
          x2="113.025"
          y2="215.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#C1B681" />
          <stop offset="0.21" stopColor="#868552" />
          <stop offset="0.25" stopColor="#7A7A4C" />
          <stop offset="0.4" stopColor="#545838" />
          <stop offset="0.41" stopColor="#515536" />
          <stop offset="0.59" stopColor="#2C2A1A" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_301_2"
          x1="119.721"
          y1="-5.46673"
          x2="98.6325"
          y2="129.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DEBCA0" />
          <stop offset="0.3" stopColor="#B39278" />
          <stop offset="0.36" stopColor="#7F5F47" />
          <stop offset="0.47" stopColor="#60494C" />
          <stop offset="0.55" stopColor="#60494C" />
          <stop offset="0.59" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_301_2"
          x1="86.5168"
          y1="-20.6084"
          x2="25.012"
          y2="129.767"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.33" stopColor="#E3BE97" />
          <stop offset="0.35" stopColor="#D1AC8A" />
          <stop offset="0.41" stopColor="#9D7763" />
          <stop offset="0.48" stopColor="#775745" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_301_2"
          x1="128.647"
          y1="-50.2023"
          x2="45.7791"
          y2="143.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#E4C5A6" />
          <stop offset="0.39" stopColor="#C6A585" />
          <stop offset="0.47" stopColor="#967251" />
          <stop offset="0.52" stopColor="#755842" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_301_2"
          x1="54.9351"
          y1="-42.4795"
          x2="3.51028"
          y2="290.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#DEBCA0" />
          <stop offset="0.28" stopColor="#B39278" />
          <stop offset="0.31" stopColor="#7F5F47" />
          <stop offset="0.36" stopColor="#775745" />
          <stop offset="0.58" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_301_2"
          x1="35.3183"
          y1="-32.0311"
          x2="47.3217"
          y2="311.983"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#DEBCA0" />
          <stop offset="0.3" stopColor="#B39278" />
          <stop offset="0.32" stopColor="#7F5F47" />
          <stop offset="0.36" stopColor="#775745" />
          <stop offset="0.58" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_301_2"
          x1="82.841"
          y1="20.8864"
          x2="86.6382"
          y2="140.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.24" stopColor="#DEBCA0" />
          <stop offset="0.31" stopColor="#B39278" />
          <stop offset="0.4" stopColor="#7F5F47" />
          <stop offset="0.48" stopColor="#68432C" />
          <stop offset="1" stopColor="#775745" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_301_2"
          x1="34.0031"
          y1="21.3053"
          x2="120.7"
          y2="159.477"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.31" stopColor="#DEBCA0" />
          <stop offset="0.35" stopColor="#B39278" />
          <stop offset="0.41" stopColor="#7F5F47" />
          <stop offset="0.48" stopColor="#724A33" />
          <stop offset="0.58" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_301_2"
          x1="100.587"
          y1="7.19387"
          x2="104.834"
          y2="161.956"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#DEBCA0" />
          <stop offset="0.33" stopColor="#B39278" />
          <stop offset="0.4" stopColor="#7F5F47" />
          <stop offset="0.48" stopColor="#603B25" />
          <stop offset="1" stopColor="#775745" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_301_2"
          x1="139.633"
          y1="0.113004"
          x2="118.327"
          y2="137.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stopColor="#DEBCA0" />
          <stop offset="0.2" stopColor="#B39278" />
          <stop offset="0.29" stopColor="#7F5F47" />
          <stop offset="0.47" stopColor="#60494C" />
          <stop offset="0.59" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_301_2"
          x1="94.1296"
          y1="74.607"
          x2="88.8793"
          y2="110.813"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.26" stopColor="#662C19" />
          <stop offset="0.57" stopColor="#7F3926" />
          <stop offset="0.76" stopColor="#964722" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_301_2"
          x1="94.3545"
          y1="5.85721"
          x2="224.061"
          y2="178.521"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.27" stopColor="#CBA088" />
          <stop offset="0.3" stopColor="#A97D65" />
          <stop offset="0.33" stopColor="#86573E" />
          <stop offset="0.36" stopColor="#6A472A" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_301_2"
          x1="122.18"
          y1="41.8975"
          x2="177.893"
          y2="169.853"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stopColor="#DEBCA0" />
          <stop offset="0.24" stopColor="#C3A28D" />
          <stop offset="0.32" stopColor="#8F7068" />
          <stop offset="0.36" stopColor="#876659" />
          <stop offset="0.47" stopColor="#724C33" />
          <stop offset="0.58" stopColor="#60494C" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_301_2"
          x1="57.7649"
          y1="82.3086"
          x2="46.2148"
          y2="123.385"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#DEBCA0" />
          <stop offset="0.16" stopColor="#CDAA90" />
          <stop offset="0.31" stopColor="#9E7662" />
          <stop offset="0.39" stopColor="#7E5D46" />
          <stop offset="0.59" stopColor="#815C41" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_301_2"
          x1="49.4079"
          y1="98.1019"
          x2="44.571"
          y2="137.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#F1EAB4" />
          <stop offset="0.22" stopColor="#C3AA87" />
          <stop offset="0.31" stopColor="#9E7662" />
          <stop offset="0.48" stopColor="#835E49" />
          <stop offset="0.8" stopColor="#8E7565" />
          <stop offset="1" stopColor="#937F71" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Memo = memo(IslamismBannerImage);
export default Memo;
