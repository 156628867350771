import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgMicrophoneEnabled: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.933 7.6a.58.58 0 0 0-.583.583V9.5c0 2.95-2.4 5.35-5.35 5.35-2.95 0-5.35-2.4-5.35-5.35V8.175a.58.58 0 0 0-.583-.583.58.58 0 0 0-.584.583v1.317c0 3.391 2.609 6.183 5.934 6.483v1.775a.58.58 0 0 0 .583.584.58.58 0 0 0 .583-.584v-1.775c3.317-.291 5.934-3.091 5.934-6.483V8.175a.589.589 0 0 0-.584-.575Z"
        fill={color}
      />
      <path
        d="M10 1.667A3.685 3.685 0 0 0 6.316 5.35v4.267a3.685 3.685 0 0 0 7.367 0V5.35A3.685 3.685 0 0 0 10 1.667Zm1.091 5.792a.48.48 0 0 1-.591.341 1.822 1.822 0 0 0-.992 0 .473.473 0 0 1-.592-.341.477.477 0 0 1 .342-.592 2.863 2.863 0 0 1 1.5 0c.25.067.4.333.333.592Zm.442-1.617a.483.483 0 0 1-.458.317.577.577 0 0 1-.167-.025 2.62 2.62 0 0 0-1.817 0 .492.492 0 0 1-.625-.292.48.48 0 0 1 .292-.617 3.633 3.633 0 0 1 2.483 0c.25.092.384.367.292.617Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgMicrophoneEnabled);
export default Memo;
