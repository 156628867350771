import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgLogoOnlineTherapist: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={254}
      height={73}
      viewBox="0 0 254 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            d="M242.944 5.297a5.297 5.297 0 0 1-10.593 0c0-2.917 2.364-5.297 5.281-5.297 2.932 0 5.312 2.364 5.312 5.297Z"
            fill="#B8362D"
          />
          <path
            d="M34.452 29.708V63.33h-5.773V34.897H5.757v28.434H0V29.708h34.452ZM34.943 50.91c0-8.766 5.036-14.247 14.662-14.247 9.673 0 14.647 5.496 14.647 14.247 0 8.72-4.99 14.156-14.647 14.156-9.626 0-14.646-5.436-14.662-14.156Zm24.135 0c0-6.402-3.577-9.534-9.473-9.534-5.864 0-9.442 3.132-9.442 9.534 0 6.31 3.578 9.442 9.442 9.442 5.896 0 9.473-3.132 9.473-9.442ZM80.065 50.91c0-8.766 5.02-14.247 14.662-14.247 9.673 0 14.647 5.496 14.647 14.247 0 8.72-4.99 14.156-14.647 14.156-9.641 0-14.662-5.436-14.662-14.156Zm24.12 0c0-6.402-3.577-9.534-9.473-9.534-5.865 0-9.427 3.132-9.427 9.534 0 6.31 3.578 9.442 9.427 9.442 5.896 0 9.473-3.132 9.473-9.442ZM134.568 50.91c0-8.766 5.021-14.247 14.647-14.247 9.672 0 14.647 5.496 14.647 14.247 0 8.72-4.975 14.156-14.647 14.156-9.626 0-14.647-5.436-14.647-14.156Zm24.135 0c0-6.402-3.577-9.534-9.473-9.534-5.864 0-9.426 3.132-9.426 9.534 0 6.31 3.577 9.442 9.426 9.442 5.896 0 9.473-3.132 9.473-9.442ZM134.429 56.652c0 4.606-4.053 7.032-10.087 7.032H110.11V38.06h13.173c5.635 0 9.688 2.303 9.688 6.51 0 2.932-2.073 5.557-5.834 5.818 3.976.261 7.292 2.088 7.292 6.264Zm-19.16-8.075h6.755c3.439 0 5.742-.492 5.742-3.301 0-2.81-2.303-2.994-5.742-2.994h-6.755v6.295Zm13.817 7.63c0-2.886-2.302-3.531-6.156-3.531h-7.676v7.062h7.676c3.838-.015 6.156-.645 6.156-3.531Z"
            fill="#1E1E1C"
          />
          <path
            d="M64.928 63.668V38.06H81.985v4.206H70.086v21.402h-5.158ZM169.956 58.817v13.879h-5.45v-34.82h2.855l2.595 4.56c2.196-3.21 5.174-5.497 10.624-5.497 8.291 0 13.787 5.404 13.787 13.695 0 8.244-5.496 13.695-13.787 13.695-5.45-.016-8.444-2.288-10.624-5.512Zm0-9.243v2.15c.584 5.865 4.99 7.937 9.227 7.937 5.589 0 9.872-3.209 9.872-9.027 0-5.819-4.683-9.074-9.872-9.074-4.237-.015-8.567 2.718-9.227 8.014ZM223.737 38.183v25.5h-3.393l-2.042-4.928c-2.241 3.624-6.417 5.865-11.637 5.865-7.769 0-11.638-4.974-11.638-11.868v-14.57h5.405V52.37c0 5.02 2.563 7.722 7.63 7.722 4.375 0 8.045-2.042 10.24-6.233V38.183h5.435Z"
            fill="#1E1E1C"
          />
          <path
            d="M252.862 38.152V63.73h-4.944V45.49l-12.16 17.518h-.245l-12.175-17.794V63.73h-2.733l-2.057-4.96V38.153h5.312l11.868 17.41 11.867-17.41h5.267ZM236.097 14.631h2.533v7.953h11.837v-7.953H253v19.376h-2.533V24.78H238.63v9.227h-2.533V14.63ZM163.83 14.631h2.533v7.953h11.822v-7.953h2.549v19.376h-2.549V24.78h-11.822v9.227h-2.533V14.63ZM221.02 33.807c-1.152.322-1.658.4-2.334.4-1.658 0-2.533-1.106-2.533-3.286-2.533 2.733-4.683 3.654-8.49 3.654-4.683 0-7.569-2.18-7.569-5.666 0-2.302 1.028-3.884 3.178-4.836.998-.43 2.333-.752 4.836-1.074l4.038-.553c3.055-.43 3.961-1.029 3.961-2.656 0-2.257-2.027-3.454-5.742-3.454-4.514 0-6.587 1.35-6.894 4.56h-2.579c.323-2.104.706-3.133 1.658-4.161 1.628-1.904 3.961-2.656 7.922-2.656 3.531 0 5.788.798 7.063 2.533.875 1.151 1.028 1.98 1.028 4.99v8.72c0 1.151.399 1.704 1.275 1.704.276 0 .629-.077 1.182-.23v2.011Zm-4.883-8.95c0-.676.047-.953.077-1.659-1.028.706-1.827.998-4.513 1.428l-3.608.553c-4.207.675-5.466 1.504-5.466 3.562 0 2.333 1.98 3.684 5.389 3.684 2.809 0 5.312-1.028 7.016-2.932.829-.998 1.075-1.505 1.075-2.61l.03-2.027ZM163.047 24.503c0 5.788-4.406 10.072-10.394 10.072-6.11 0-10.347-4.16-10.347-10.149 0-6.14 4.283-10.347 10.47-10.347 6.08-.016 10.271 4.237 10.271 10.424Zm-18.193-.2c0 4.683 3.286 8.015 7.922 8.015 4.407 0 7.692-3.362 7.692-7.892 0-4.713-3.209-8.09-7.738-8.09-4.544 0-7.876 3.362-7.876 7.968Z"
            fill="#1E1E1C"
          />
          <path
            d="m192.141 14.631 8.874 19.391h-2.779l-5.634-12.451c-.43-.998-.798-1.827-1.858-4.56-.875 2.334-1.305 3.454-1.858 4.713l-5.465 12.283h-2.702l8.766-19.391h2.656v.015ZM236.296 34.007v-4.084c-.905 1.382-1.504 2.026-2.502 2.702-1.581 1.105-3.838 1.75-6.111 1.75-4.959 0-7.446-2.303-7.446-6.863v-12.88h2.58V26.96c0 3.608 1.704 5.235 5.465 5.235 3.209 0 5.988-1.428 7.216-3.685.399-.752.599-1.704.599-2.932V14.63h2.533v19.391h-2.334v-.015Z"
            fill="#1E1E1C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h253.65v72.88H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h253v72.696H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgLogoOnlineTherapist);
export default Memo;
