import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgSpinner: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        opacity={0.5}
        cx={10}
        cy={10}
        r={8}
        stroke={color}
        strokeWidth={2.5}
      />
      <path
        d="M10 19.25a1.25 1.25 0 1 0 0-2.5v2.5ZM3.25 10a1.25 1.25 0 1 0-2.5 0h2.5ZM10 16.75A6.75 6.75 0 0 1 3.25 10H.75A9.25 9.25 0 0 0 10 19.25v-2.5Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgSpinner);
export default Memo;
