import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

interface InitialState {
  code: string;
  expireDate: string | null;
  amount: number | null;
  percentage: number | null;
}

const initialState = {} as InitialState;

const sessionPromocode = createSlice({
  name: 'sessionPromocode',
  initialState,
  reducers: {
    setState(state, { payload }: PayloadAction<Required<InitialState>>) {
      return payload;
    },
    reset(state) {
      return initialState;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, { payload }) => {
      return {
        ...state,
        ...payload.sessionPromocode,
      };
    },
  },
});

export const { actions, reducer } = sessionPromocode;
