import { useCallback } from 'react';

const useNewTabOpen = () => {
  const openNewTab = useCallback((url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
    a.remove();
  }, []);

  return [openNewTab] as const;
};

export default useNewTabOpen;
