export const REGEX = {
  fullPassword: /^(\S){8,}$/,
  spaces: /\s/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /[0-9]/,
  onlyNumbers: /^\d+$/,
  onlyPositiveNumbers: /^[1-9]\d*$/,
  symbols: /[@$!%*#?&\.]/,
  age: /[0-9]{2}/,
  phone:
    /^(\+7|7|8)?[\s\-]?\(?[4789][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
  anyPhone:
    /^\+(\d{1,3})[-.\s]?(\(\d{1,3}\)|\d{1,3})[-.\s]?\d{1,3}[-.\s]?\d{1,2}[-.\s]?\d{1,2}$/,
  time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  russianLetters:
    /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/,
  russianLettersWithDash: /^[А-Яа-яЁё]+([А-Яа-яЁё\-]+[А-Яа-яЁё]+)?$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  maxNumber50: /\b([0-9]|[1-4][0-9]|50)\b/,
  maxNumber20k: /^([1-9]\d{0,3}|1\d{4}|20000)$/,
  maxNumber100k: /^([1-9]\d{0,3}|1\d{4}|100000)$/,
};
