import { API_URL } from '@app/constants';
import { ClientsOrderTerms, HttpMethods, PaginationParams } from '@app/types';
import {
  MessageSuccessResponseDto,
  PublicPatientProfileResponseDto,
  RejectPatientRequestDto,
  TherapistPatientsResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

interface GetClientsParams extends PaginationParams {
  orderTerms?: ClientsOrderTerms;
  archivedByTherapist?: boolean;
}

interface GetClientByIdArgs {
  patientId: string;
}

export const therapistClientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<TherapistPatientsResponseDto, GetClientsParams>({
      query: ({ limit = 10, ...rest }) => ({
        url: API_URL.getClients,
        method: HttpMethods.get,
        params: { limit, ...rest },
      }),
      providesTags: ['therapistClients'],
    }),
    getClientById: builder.query<
      PublicPatientProfileResponseDto,
      GetClientByIdArgs
    >({
      query: ({ patientId }) => ({
        url: API_URL.getClientById(patientId),
        method: HttpMethods.get,
      }),
    }),
    archivePatient: builder.mutation<
      MessageSuccessResponseDto,
      GetClientByIdArgs
    >({
      query: ({ patientId }) => ({
        url: API_URL.archivePatient(patientId),
        method: HttpMethods.patch,
      }),
      invalidatesTags: ['therapistClients'],
    }),
    rejectPatient: builder.mutation<
      MessageSuccessResponseDto,
      RejectPatientRequestDto & GetClientByIdArgs
    >({
      query: ({ patientId, rejectReason }) => ({
        url: API_URL.rejectPatient(patientId),
        method: HttpMethods.delete,
        body: {
          rejectReason,
        },
      }),
      invalidatesTags: ['therapistClients'],
    }),
    unarchivePatient: builder.mutation<
      MessageSuccessResponseDto,
      GetClientByIdArgs
    >({
      query: ({ patientId }) => ({
        url: API_URL.unarchivePatient(patientId),
        method: HttpMethods.patch,
      }),
      invalidatesTags: ['therapistClients'],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useArchivePatientMutation,
  useRejectPatientMutation,
  useUnarchivePatientMutation,
} = therapistClientsApi;
