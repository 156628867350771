import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgCheck: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.429 17.677-.57.488a.75.75 0 0 0 1.139 0l-.57-.488Zm9.14-9.512a.75.75 0 1 0-1.138-.976l1.138.976Zm-12 5.024a.75.75 0 1 0-1.138.976l1.138-.976Zm3.429 4.976 8.571-10-1.138-.976-8.572 10 1.139.976Zm0-.976-3.429-4-1.138.976 3.428 4 1.139-.976Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgCheck);
export default Memo;
