import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgImport: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.7 7.417c3 .258 4.226 1.8 4.226 5.175v.108c0 3.725-1.492 5.217-5.217 5.217H7.284c-3.725 0-5.217-1.492-5.217-5.217v-.108c0-3.35 1.209-4.892 4.159-5.167M10 1.667V12.4"
        stroke={color}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.792 10.542-2.791 2.792-2.792-2.792"
        stroke={color}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Memo = memo(SvgImport);
export default Memo;
