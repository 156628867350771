import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgWhatsApp: FC<IconComponent> = ({
  color = COLORS.primary300,
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6655 9.58813C11.4664 9.48897 10.4878 9.0097 10.3053 8.9436C10.1228 8.87751 9.99015 8.84444 9.85744 9.04276C9.72473 9.24108 9.34326 9.68729 9.22712 9.81952C9.11102 9.95171 8.99492 9.96828 8.79586 9.86908C8.5968 9.76996 7.95542 9.56074 7.19512 8.88581C6.60337 8.36056 6.20387 7.71176 6.08773 7.51344C5.97164 7.31512 6.07537 7.20792 6.17506 7.10914C6.26456 7.02041 6.37408 6.87779 6.47361 6.76209C6.57314 6.6464 6.60632 6.56377 6.67267 6.43158C6.73902 6.29935 6.70585 6.18366 6.65606 6.0845C6.60632 5.98534 6.20823 5.01026 6.04235 4.61361C5.88079 4.22733 5.71667 4.2796 5.59448 4.27354C5.4785 4.26779 5.34568 4.26657 5.21297 4.26657C5.08027 4.26657 4.86464 4.31617 4.68215 4.51445C4.49969 4.71277 3.98547 5.19209 3.98547 6.16713C3.98547 7.1422 4.69875 8.08422 4.79828 8.21644C4.89781 8.34867 6.20192 10.3496 8.19872 11.2077C8.67364 11.4118 9.04444 11.5337 9.3335 11.6251C9.81037 11.7758 10.2443 11.7546 10.5873 11.7035C10.9697 11.6467 11.765 11.2243 11.9309 10.7615C12.0967 10.2988 12.0967 9.90215 12.047 9.81952C11.9972 9.73689 11.8645 9.68729 11.6655 9.58813ZM8.03346 14.5235H8.03079C6.84262 14.5231 5.67727 14.2054 4.66065 13.605L4.41885 13.4621L1.91277 14.1164L2.5817 11.6847L2.42423 11.4354C1.76143 10.3862 1.41137 9.17353 1.41187 7.92839C1.41332 4.29505 4.38372 1.33907 8.03611 1.33907C9.80469 1.33976 11.4672 2.02611 12.7173 3.27167C13.9675 4.51726 14.6556 6.17294 14.6549 7.93373C14.6534 11.5673 11.6831 14.5235 8.03346 14.5235ZM13.6688 2.32516C12.1647 0.826547 10.1645 0.00083828 8.03339 0C3.64236 0 0.0685728 3.55643 0.0668126 7.92787C0.0662386 9.32523 0.433057 10.6893 1.13019 11.8915L0 16L4.2232 14.8975C5.38679 15.5291 6.69687 15.862 8.03021 15.8626H8.03346H8.0335C12.4241 15.8626 15.9982 12.3057 16 7.93419C16.0008 5.81573 15.173 3.82374 13.6688 2.32516Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgWhatsApp);
export default Memo;
