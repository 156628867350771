import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import * as actions from './actions';

interface InitialState {
  hasShown: boolean;
}

const initialState: InitialState = {
  hasShown: false,
};

export const allTherapistsModalAfterSignUpReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        actions.setHasShown,
        (state, { payload }: PayloadAction<boolean>) => {
          state.hasShown = payload;
        },
      )
      .addCase(HYDRATE, (state, { payload }: any) => {
        return {
          ...state,
          ...payload.allTherapistsModalAfterSignUp,
        };
      });
  },
);
