import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import {
  UserWithMetadataResponseDto,
  UpdateNotificationTimeRequestDto,
} from '@app/api';
import { actions } from '@app/store/ducks/auth';
import { baseApi } from '../baseApi';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<UserWithMetadataResponseDto, void>({
      query: () => ({
        url: API_URL.getMe,
        method: HttpMethods.get,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((res) => {
          dispatch(actions.setUserWithMetadata(res.data));
        });
      },
      providesTags: ['users'],
    }),
    updateNotificationTime: builder.mutation({
      query: (body: UpdateNotificationTimeRequestDto) => ({
        url: API_URL.updateNotificationTime,
        method: HttpMethods.patch,
        body,
      }),
      invalidatesTags: ['users'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateNotificationTimeMutation,
  useLazyGetMeQuery,
} = usersApi;
