import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgMyPaymentsEmptyState: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={274}
      height={136}
      viewBox="0 0 274 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.6}>
        <path
          d="M93.823 43.437s-3.49-1.952-5.964-7.362-4.624-7.79-4.68-9.436a14.027 14.027 0 0 0-.73-3.552s2.297 3.651 3.61 4.459c1.312.808 5.62 3.645 6.788 6.139 1.167 2.494 2.983 9.232.976 9.752Z"
          fill="url(#a)"
        />
        <path
          d="M121.403 58.179c7.236 3.134 13.951 4.61 17.895 5.848l-.46-3.511c-3.038-.678-4.774-2.212-8.944-2.693-4.112-1.511-17.805-2.196-22.982-8.084-5.177-5.887-12.383-7.75-12.383-7.75l-.545 1.652c11.914 2.886 10.759 7.054 15.402 9.425 4.644 2.372 4.782 1.98 12.017 5.113Z"
          fill="#28221F"
        />
        <path
          d="M130.541 58.077s-4.582-1.587-8.683-7.52c-4.101-5.934-7.18-8.305-7.6-10.273a16.962 16.962 0 0 0-1.634-4.087s3.531 3.861 5.267 4.555c1.736.695 7.488 3.156 9.416 5.89 1.928 2.734 5.511 10.38 3.234 11.435Z"
          fill="url(#b)"
        />
        <path
          d="M144.934 69.793s-4.64 13.01-13.071 16.05c-8.431 3.04-12.943 5.667-17.463 6.049-4.52.381 2.536-.792 4.584-3.786 2.048-2.995 6.908-10.568 11.274-14.666 4.367-4.098 13.085-6.394 14.676-3.647Z"
          fill="url(#c)"
        />
        <path
          d="M107.238 49.41s-4.998-3.665-7.772-12.455-5.632-12.852-5.404-15.414a21.66 21.66 0 0 0-.45-5.604s2.852 6.05 4.71 7.546c1.858 1.495 7.955 6.678 9.278 10.744 1.324 4.065 2.831 14.769-.362 15.184Z"
          fill="url(#d)"
        />
        <path
          d="M124.316 84.329c-5.769-4.078-7.14-12.061-3.062-17.83 4.078-5.77 12.061-7.141 17.831-3.063 5.769 4.078 7.14 12.061 3.062 17.83-4.078 5.77-12.061 7.141-17.831 3.063Z"
          fill="#BE7C55"
        />
        <path
          d="M93.628 43.798S80.581 48.94 72.05 45.74s-13.868-4.243-17.464-6.789c-3.597-2.546 2.472 1.034 6.298.345 3.826-.688 13.274-2.653 19.653-2.647 6.378.007 14.071 4.382 13.092 7.15Z"
          fill="url(#e)"
        />
        <path
          d="M132.037 61.117s-7.754 12.444-16.556 14.787c-8.801 2.343-13.768 4.582-18.166 4.61-4.399.029 2.615-.584 5.332-3.373 2.717-2.789 9.284-9.867 14.491-13.563 5.206-3.697 14.09-5.286 14.899-2.461Z"
          fill="url(#f)"
        />
        <path
          d="M117.751 56.952s-7.639 11.763-16.444 14.102c-8.806 2.34-13.769 4.582-18.167 4.61-4.398.029 2.619-.58 5.336-3.369 2.717-2.788 9.284-9.867 14.491-13.563 5.206-3.697 13.975-4.604 14.784-1.78Z"
          fill="url(#g)"
        />
        <path
          d="M106.47 50.315s-7.638 11.762-16.444 14.109c-8.805 2.346-13.768 4.582-18.163 4.606-4.395.025 2.612-.58 5.333-3.372 2.72-2.793 9.28-9.864 14.487-13.56 5.206-3.697 13.985-4.615 14.787-1.783Z"
          fill="url(#h)"
        />
        <path
          d="M93.942 44.168s-6.478 9.973-13.938 11.96c-7.461 1.986-11.671 3.883-15.402 3.904-3.732.021 2.22-.492 4.522-2.856 2.303-2.364 7.87-8.364 12.284-11.497 4.413-3.133 11.854-3.893 12.534-1.511Z"
          fill="url(#i)"
        />
      </g>
      <path
        d="M97.457 78.421s-4.27 1.622-11.96-5.654c-7.69-7.275-11.68-8.72-13.631-12.098a42.11 42.11 0 0 0-4.539-6.24s5.55 3.864 7.245 3.427c1.694-.437 7.46-1.568 11.025 1.748 3.565 3.316 12.412 14.44 11.86 18.817Z"
        fill="url(#j)"
      />
      <path
        d="M80.678 85.566s-4.27 1.621-11.96-5.654c-7.69-7.276-11.68-8.721-13.635-12.098a41.776 41.776 0 0 0-4.538-6.24s5.554 3.86 7.249 3.422c1.694-.437 7.46-1.567 11.024 1.748 3.565 3.316 12.412 14.448 11.86 18.822Z"
        fill="url(#k)"
      />
      <path
        d="M124.592 73.422s-4.554.33-9.832-8.85c-5.279-9.181-8.683-11.708-9.583-15.503a42.092 42.092 0 0 0-2.553-7.283s4.216 5.294 5.957 5.367c1.74.073 7.593.64 10.054 4.837 2.461 4.198 7.744 17.4 5.957 21.432Z"
        fill="url(#l)"
      />
      <path
        d="M33.068 92.49s-4.887.766-11.461-3.236c-6.575-4.002-10.492-4.845-11.826-6.666-1-1.271-2.16-2.409-3.45-3.385 0 0 5.052 2.154 6.946 1.959 1.894-.196 8.264-.667 11.323 1.15 3.06 1.817 10.017 7.885 8.468 10.177Z"
        fill="url(#m)"
      />
      <path
        d="M30.266 94.326s-3.833 3.139-11.5 3.032c-7.666-.108-11.5 1.15-13.573.257A18.877 18.877 0 0 0 .5 96.465s5.443-.71 6.98-1.84c1.537-1.131 6.781-4.765 10.35-4.75 3.569.015 12.596 1.69 12.435 4.45Z"
        fill="url(#n)"
      />
      <path
        d="M61.53 88.001s-4.474 1.392-11.546-3.519c-7.072-4.91-10.96-5.75-12.608-8.119a25.497 25.497 0 0 0-3.986-4.312s5.24 2.53 7 2.131c1.759-.399 7.693-1.514 10.974.72 3.281 2.236 11.155 9.898 10.166 13.1Z"
        fill="url(#o)"
      />
      <path
        d="M97.48 79.222s5.014 9.564 1.445 16.556c-3.569 6.992-4.796 11.255-7.548 14.352-2.752 3.097 1.15-2.036.533-4.937-.617-2.902-2.4-10.032-2.216-14.996.184-4.964 4.922-11.42 7.786-10.975Z"
        fill="url(#p)"
      />
      <path
        d="M76.81 87.344s1.87 10.634-3.653 16.211c-5.524 5.578-7.996 9.269-11.557 11.374-3.562 2.104 1.72-1.587 2.012-4.539.291-2.952.767-10.289 2.446-14.95 1.679-4.661 8.172-9.391 10.752-8.096Z"
        fill="url(#q)"
      />
      <path
        d="M61.535 88.598s1.867 10.63-3.657 16.211c-5.524 5.582-7.992 9.269-11.553 11.374-3.562 2.104 1.72-1.587 2.008-4.543.288-2.955.767-10.284 2.45-14.95 1.683-4.665 8.16-9.387 10.752-8.092Z"
        fill="url(#r)"
      />
      <path
        d="M33.494 93.233s1.87 10.63-3.657 16.211c-5.527 5.581-7.992 9.269-11.553 11.373-3.562 2.105 1.72-1.587 2.008-4.538.288-2.952.767-10.289 2.45-14.95 1.682-4.661 8.16-9.4 10.752-8.096Z"
        fill="url(#s)"
      />
      <path
        d="M125.788 73.131s1.867 10.634-3.657 16.215c-5.524 5.581-7.992 9.27-11.553 11.374-3.562 2.104 1.721-1.591 2.008-4.543.288-2.952.767-10.285 2.446-14.95 1.679-4.665 8.161-9.388 10.756-8.096Z"
        fill="url(#t)"
      />
      <path
        d="M150.881 71.644s-7.241-1.181-10.641 0c-3.401 1.18-11.558 1.15-17.335 2.004-5.776.855-12.017 2.576-20.282 5.126-8.264 2.549-14.045 6.34-19.994 8.352-5.95 2.013-20.834 1.534-20.834 1.534s-17.403-2.032-21.467.916c-4.063 2.948-13.416 4.308-13.416 4.308s9.066-2.468 11.588-5.224c2.522-2.757 14.992-1.534 14.992-1.534S69.76 86.59 75.02 86.08c5.26-.51 8.652-3.067 19.868-7.479s27.155-7.483 27.155-7.483l15.118-1.299 3.933-1.867 9.787 3.692Z"
        fill="url(#u)"
      />
      <g clipPath="url(#v)">
        <path
          d="M388.669 136.369s-14.367-25.12-48.261-37.007c-33.894-11.887-60.566-4.557-74.366-2.277-13.8 2.281-30.283 7.41-55.135-2.3-24.851-9.71-68.301-25.196-82.232-31.1-13.93-5.902-33.35-17.682-38.494-19.357-5.144-1.675-22.455-2.001-22.455-2.001l1.679-3.098s23.479-1.326 27.791 2.4c4.313 3.726 25.404 12.65 40.787 16.959 15.383 4.308 32.506 8.364 44.202 15.114 11.695 6.75 37.156 14.333 46.708 15.127 9.553.793 35.957-3.24 50.171-5.942 14.213-2.703 41.622-6.084 72.487 4.791 30.866 10.876 37.95 23.311 37.95 23.311l-.832 25.38Z"
          fill="url(#w)"
        />
      </g>
      <path
        d="M128.22 21.773s-2.875 10.933-.042 18.232c2.833 7.298 15.644 16.563 27.684 22.26 12.04 5.696 14.026 9.2 14.026 9.2l-4.082-15.633-26.761-34.239-7.387-2.533-3.438 2.713Z"
        fill="url(#x)"
      />
      <path
        d="M204.894 127.362s-16.725-52.447-23.648-64.545c-6.922-12.098-15.666-26.38-20.949-32.13-9.79-10.65-16.46-10.393-16.889-15.058-.429-4.665 1.411-11.757-7.724-14.793-9.135-3.036-8.311 2.979-13.9 5.428-5.589 2.45-3.783 3.477-3.783 3.477s6.988-.353 7.778.433c.789.786 4.005 3.067 3.285 6.133-.721 3.067-1.1 8.269-1.1 8.269s2.07-7.069 4.412-3.416c2.342 3.654 4.082.767 4.082.767s-.962 8.257 1.445 12.62c2.408 4.362 13.034 15.42 18.04 20.116 5.006 4.696 8.659 3.792 12.374 13.034 3.714 9.242 11.208 67.876 11.208 67.876s17.522.951 25.369-8.211Z"
        fill="url(#y)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={123.472}
          y1={42.223}
          x2={62.321}
          y2={33.293}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={167.902}
          y1={37.559}
          x2={88.923}
          y2={38.166}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={174.148}
          y1={116.208}
          x2={88.409}
          y2={52.758}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={160.606}
          y1={38.447}
          x2={63.925}
          y2={9.403}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={23.95}
          y1={128.867}
          x2={-7.417}
          y2={-9.075}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={147.92}
          y1={109.03}
          x2={81.954}
          y2={40.666}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={134.077}
          y1={104.832}
          x2={67.35}
          y2={35.599}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={122.791}
          y1={98.186}
          x2={56.08}
          y2={28.971}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={107.785}
          y1={84.761}
          x2={51.212}
          y2={26.064}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={87.713}
          y1={-24.5}
          x2={19.909}
          y2={8.205}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={70.82}
          y1={-17.454}
          x2={3.015}
          y2={15.252}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={144.847}
          y1={-27.482}
          x2={70.583}
          y2={-15.615}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={46.377}
          y1={37.232}
          x2={-21.275}
          y2={73.736}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={12.315}
          y1={42.568}
          x2={-27.06}
          y2={108.893}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={61.855}
          y1={14.629}
          x2={-4.16}
          y2={51.363}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.49} stopColor="#1F2F31" />
          <stop offset={0.56} stopColor="#2F4727" />
          <stop offset={0.62} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={155.083}
          y1={77.877}
          x2={38.926}
          y2={109.537}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={132.959}
          y1={103.713}
          x2={12.554}
          y2={99.09}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={117.709}
          y1={104.953}
          x2={-2.696}
          y2={100.329}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={89.732}
          y1={109.555}
          x2={-30.672}
          y2={104.932}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="t"
          x1={181.798}
          y1={89.541}
          x2={61.394}
          y2={84.918}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.48} stopColor="#1F2F31" />
          <stop offset={0.52} stopColor="#2F4727" />
          <stop offset={0.55} stopColor="#446B40" />
        </linearGradient>
        <linearGradient
          id="u"
          x1={150.881}
          y1={80.924}
          x2={26.889}
          y2={80.924}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.1} stopColor="#28221F" />
          <stop offset={1} stopColor="#261E1D" />
        </linearGradient>
        <linearGradient
          id="w"
          x1={220.464}
          y1={129.699}
          x2={280.996}
          y2={-186.326}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.1} stopColor="#28221F" />
          <stop offset={0.17} stopColor="#261E1D" />
          <stop offset={0.18} stopColor="#2C2725" />
          <stop offset={0.2} stopColor="#383834" />
          <stop offset={0.22} stopColor="#41433E" />
          <stop offset={0.26} stopColor="#464A44" />
          <stop offset={0.36} stopColor="#474C46" />
          <stop offset={0.72} stopColor="#2E3E35" />
        </linearGradient>
        <linearGradient
          id="x"
          x1={166.874}
          y1={31.966}
          x2={98.569}
          y2={94.842}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.15} stopColor="#7A5716" />
          <stop offset={0.25} stopColor="#7E5614" />
          <stop offset={0.3} stopColor="#876815" />
          <stop offset={0.35} stopColor="#8B7016" />
        </linearGradient>
        <linearGradient
          id="y"
          x1={199.743}
          y1={65.398}
          x2={123.744}
          y2={72.238}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.13} stopColor="#121410" />
          <stop offset={0.26} stopColor="#0B100E" />
          <stop offset={0.42} stopColor="#0F1410" />
          <stop offset={0.58} stopColor="#211F16" />
          <stop offset={0.76} stopColor="#182017" />
          <stop offset={1} stopColor="#39461D" />
        </linearGradient>
        <clipPath id="v">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 273.501 38)"
            d="M0 0h206v98H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(SvgMyPaymentsEmptyState);
export default Memo;
