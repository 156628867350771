import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgTooltipTriangle: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={14}
      height={7}
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.293.707a1 1 0 0 1 1.414 0L14 7H0L6.293.707Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgTooltipTriangle);
export default Memo;
