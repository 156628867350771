import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgTooltipTriangleHorizontal: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={7}
      height={14}
      viewBox="0 0 7 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.293 7.707a1 1 0 0 0 0-1.414L0 0v14l6.293-6.293Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgTooltipTriangleHorizontal);
export default Memo;
