import { FC, memo } from 'react';
import { IconComponent } from '@app/types';

const TipIcon: FC<IconComponent> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_151_2040)">
        <path d="M8 0C3.58333 0 0 3.58333 0 8C0 12.4167 3.58333 16 8 16C12.4167 16 16 12.4167 16 8C16 3.58333 12.4167 0 8 0ZM8.435 12.5833H7.17625C7.14792 12.5832 7.12079 12.5719 7.1008 12.5518C7.08081 12.5318 7.06958 12.5046 7.06958 12.4762V11.2179C7.06958 11.1896 7.08081 11.1624 7.1008 11.1423C7.12079 11.1223 7.14792 11.1109 7.17625 11.1108H8.435C8.46333 11.1109 8.49046 11.1223 8.51045 11.1423C8.53044 11.1624 8.54167 11.1896 8.54167 11.2179V12.4762C8.54167 12.5046 8.53044 12.5318 8.51045 12.5518C8.49046 12.5719 8.46333 12.5832 8.435 12.5833ZM9.14333 8.45833C8.46792 8.91167 8.375 9.32708 8.375 9.70833V10.1667C8.375 10.1998 8.36183 10.2316 8.33839 10.2551C8.31495 10.2785 8.28315 10.2917 8.25 10.2917H7.33333C7.30018 10.2917 7.26839 10.2785 7.24495 10.2551C7.2215 10.2316 7.20833 10.1998 7.20833 10.1667V9.70833C7.20833 8.79542 7.62833 8.06958 8.4925 7.48917C9.29583 6.95 9.75 6.60833 9.75 5.85708C9.75 5.34625 9.45833 4.95833 8.85458 4.67125C8.7125 4.60375 8.39625 4.53792 8.00708 4.5425C7.51875 4.54875 7.13958 4.66542 6.8475 4.90042C6.29667 5.34375 6.25 5.82667 6.25 5.83333C6.22713 5.97497 6.21542 6.11819 6.215 6.26167C6.215 6.29482 6.20183 6.32661 6.17839 6.35006C6.15495 6.3735 6.12315 6.38667 6.09 6.38667H5.20458C5.17355 6.38683 5.14357 6.37545 5.12047 6.35473C5.09736 6.33402 5.08279 6.30545 5.07958 6.27458C5.06458 6.10819 5.06584 5.94073 5.08333 5.77458C5.0925 5.67333 5.15833 4.76125 6.11542 3.99125C6.61167 3.59208 7.24292 3.38458 7.99042 3.37542C8.51958 3.36917 9.01667 3.45875 9.35375 3.61792C10.3625 4.0975 10.9167 4.89292 10.9167 5.85708C10.9167 7.26667 9.97458 7.89958 9.14333 8.45833Z" fill="#3DAE9F"/>
      </g>
      <defs>
        <clipPath id="clip0_151_2040">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

const Memo = memo(TipIcon);
export default Memo;
