import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';
const SvgAskonaBannerFlowerBig: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width="506"
      height="569"
      viewBox="0 0 506 569"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13502_193756)">
        <g filter="url(#filter0_f_13502_193756)">
          <path
            d="M350.771 340.686C352.895 333.572 353.509 326.088 352.575 318.714C348.877 290.501 341.377 252.627 343.177 231.357C344.956 210.169 347.929 181.125 339.725 167.523C333.76 157.634 341.061 146.984 338.135 147.656C335.694 148.213 331.576 156.854 333.901 166.659C336.227 176.464 320.563 185.064 314.403 220.689C308.807 253.058 317.736 299.85 317.995 325.776C318.022 328.385 350.771 340.686 350.771 340.686Z"
            fill="url(#paint0_linear_13502_193756)"
          />
          <path
            d="M350.771 340.686C352.895 333.572 353.509 326.088 352.575 318.714C348.877 290.501 341.377 252.627 343.177 231.357C344.956 210.169 347.929 181.125 339.725 167.523C333.76 157.634 341.061 146.984 338.135 147.656C335.694 148.213 331.576 156.854 333.901 166.659C336.227 176.464 320.563 185.064 314.403 220.689C308.807 253.058 317.736 299.85 317.995 325.776C318.022 328.385 350.771 340.686 350.771 340.686Z"
            fill="url(#paint1_linear_13502_193756)"
          />
          <path
            d="M350.771 340.686C352.895 333.572 353.509 326.088 352.575 318.714C348.877 290.501 341.377 252.627 343.177 231.357C344.956 210.169 347.929 181.125 339.725 167.523C333.76 157.634 341.061 146.984 338.135 147.656C335.694 148.213 331.576 156.854 333.901 166.659C336.227 176.464 320.563 185.064 314.403 220.689C308.807 253.058 317.736 299.85 317.995 325.776C318.022 328.385 350.771 340.686 350.771 340.686Z"
            fill="url(#paint2_linear_13502_193756)"
          />
          <path
            d="M350.771 340.686C352.895 333.572 353.509 326.088 352.575 318.714C348.877 290.501 341.377 252.627 343.177 231.357C344.956 210.169 347.929 181.125 339.725 167.523C333.76 157.634 341.061 146.984 338.135 147.656C335.694 148.213 331.576 156.854 333.901 166.659C336.227 176.464 320.563 185.064 314.403 220.689C308.807 253.058 317.736 299.85 317.995 325.776C318.022 328.385 350.771 340.686 350.771 340.686Z"
            fill="url(#paint3_linear_13502_193756)"
          />
        </g>
        <path
          d="M383.196 332.917C392.128 304.765 411.814 221.66 415.255 216.549C419.237 210.637 417.905 200.765 417.386 194.357C417.053 190.269 418.365 187.989 418.61 177.45C418.661 175.279 415.601 186.501 409.393 191.092C403.185 195.683 395.72 196.735 386.581 212.566C379.772 224.361 377.578 220.226 369.412 237.442C361.247 254.657 352.563 272.372 350.067 287.574C349.595 290.46 349.296 293.373 349.171 296.297L383.196 332.917Z"
          fill="url(#paint4_linear_13502_193756)"
        />
        <path
          d="M383.196 332.917C392.128 304.765 411.814 221.66 415.255 216.549C419.237 210.637 417.905 200.765 417.386 194.357C417.053 190.269 418.365 187.989 418.61 177.45C418.661 175.279 415.601 186.501 409.393 191.092C403.185 195.683 395.72 196.735 386.581 212.566C379.772 224.361 377.578 220.226 369.412 237.442C361.247 254.657 352.563 272.372 350.067 287.574C349.595 290.46 349.296 293.373 349.171 296.297L383.196 332.917Z"
          fill="url(#paint5_linear_13502_193756)"
        />
        <path
          d="M383.196 332.917C392.128 304.765 411.814 221.66 415.255 216.549C419.237 210.637 417.905 200.765 417.386 194.357C417.053 190.269 418.365 187.989 418.61 177.45C418.661 175.279 415.601 186.501 409.393 191.092C403.185 195.683 395.72 196.735 386.581 212.566C379.772 224.361 377.578 220.226 369.412 237.442C361.247 254.657 352.563 272.372 350.067 287.574C349.595 290.46 349.296 293.373 349.171 296.297L383.196 332.917Z"
          fill="url(#paint6_linear_13502_193756)"
        />
      </g>
      <path
        d="M320.635 370.439C326.087 344.743 336.559 339.428 329.775 286.561C322.991 233.694 296.036 180.658 292.147 171.803C288.257 162.949 294.78 205.318 294.78 205.318C294.78 205.318 294.408 266.069 309.117 295.95C323.826 325.831 315.183 396.135 320.635 370.439Z"
        fill="#7BB190"
      />
      <path
        d="M320.635 370.439C326.087 344.743 336.559 339.428 329.775 286.561C322.991 233.694 296.036 180.658 292.147 171.803C288.257 162.949 294.78 205.318 294.78 205.318C294.78 205.318 294.408 266.069 309.117 295.95C323.826 325.831 315.183 396.135 320.635 370.439Z"
        fill="url(#paint7_linear_13502_193756)"
      />
      <path
        d="M218.139 89.9318C218.139 89.9318 219.536 84.2189 220.26 81.777C220.983 79.3351 222.454 84.4859 224.951 88.4209C226.372 90.6039 227.555 92.9471 228.48 95.4068C228.48 95.4068 229.917 87.935 232.333 83.9731C234.748 80.0112 234.915 81.1631 236.573 88.9466C237.673 93.9777 239.53 95.3575 239.53 95.3575C239.53 95.3575 243.476 82.6887 244.716 83.698C245.955 84.7074 247.833 90.6282 249.009 92.638C250.186 94.6479 254.455 86.0594 255.048 86.8455C255.887 87.9698 256.864 92.5927 258.144 93.0864C259.423 93.58 260.906 87.3036 261.545 86.6074C262.184 85.9111 263.849 93.5968 265.864 96.3138C270.684 102.755 269.188 109.522 268.078 111.753C266.969 113.984 246.808 123.368 246.808 123.368L235.623 122.865L219.879 100.995C219.879 100.995 217.728 95.0137 218.139 89.9318Z"
        fill="url(#paint8_linear_13502_193756)"
      />
      <path
        d="M267.517 99.6737C267.517 99.6737 268.981 90.8726 269.154 87.9021C269.326 84.9316 275.162 94.8028 276.113 101.172C277.064 107.541 274.562 125.003 271.518 125.877C268.474 126.75 261.849 126.686 261.849 126.686C261.742 117.346 263.681 108.104 267.517 99.6737V99.6737Z"
        fill="url(#paint9_linear_13502_193756)"
      />
      <path
        d="M208.762 96.422C208.762 96.422 205.888 86.0313 207.076 80.9784C207.662 78.5152 207.335 80.7649 210.464 83.31C213.593 85.8551 222.194 93.7159 223.98 98.7069C225.765 103.698 230.818 120.149 230.848 125.139C230.877 130.13 219.824 130.041 219.824 130.041C219.824 130.041 209.216 115.697 208.872 111.857C208.529 108.017 209.412 100.165 208.762 96.422Z"
        fill="url(#paint10_linear_13502_193756)"
      />
      <path
        d="M193.539 101.9C193.756 100.244 193.774 98.5651 193.591 96.904C193.119 92.3794 192.456 84.2496 193.899 85.3398C195.938 86.8151 195.559 89.2105 200.375 92.6102C205.192 96.01 212.172 100.658 213.783 103.065C215.393 105.472 218.015 112.501 219.12 118.922C220.225 125.342 210.668 134.613 210.668 134.613C210.668 134.613 194.962 113.907 193.801 109.441C193.249 106.969 193.16 104.408 193.539 101.9Z"
        fill="url(#paint11_linear_13502_193756)"
      />
      <path
        d="M186.029 107.29C186.029 107.29 183.34 95.1253 184.073 95.428C184.806 95.7307 188.01 101.019 193.129 103.158C198.247 105.298 203.913 110.593 206.071 113.833C208.23 117.074 211.853 124.833 211.529 128.273C211.206 131.713 193.655 139.826 193.655 139.826C193.655 139.826 187.984 117.267 186.029 107.29Z"
        fill="url(#paint12_linear_13502_193756)"
      />
      <path
        d="M177.942 113.958C177.942 113.958 178.562 104.999 178.783 102.693C179.003 100.386 180.232 105.754 182.895 108.416C185.557 111.077 190.561 113.606 192.671 119.365C194.78 125.124 199.666 140.423 198.414 141.469C197.162 142.515 180.228 128.429 178.675 123.728C177.747 120.568 177.496 117.232 177.942 113.958V113.958Z"
        fill="url(#paint13_linear_13502_193756)"
      />
      <path
        d="M254.722 104.97C254.722 104.97 256.935 97.1368 257.009 93.8205C257.083 90.5043 258.639 99.1691 261.89 105.374C265.142 111.579 267.902 123.984 264.789 125.547C261.675 127.11 250.569 125.862 250.569 125.862C250.569 125.862 251.005 113.357 254.722 104.97Z"
        fill="url(#paint14_linear_13502_193756)"
      />
      <path
        d="M175.18 126.339C175.18 126.339 171.744 114.962 171.15 110.829C170.556 106.696 172.683 112.459 175.959 114.614C179.235 116.769 183.847 123.14 185.807 128.802C187.766 134.465 191.67 141.265 188.893 140.68C186.115 140.094 177.014 135.246 175.18 126.339Z"
        fill="url(#paint15_linear_13502_193756)"
      />
      <path
        d="M243.602 111.473C243.602 111.473 246.394 100.578 247.05 97.6861C247.706 94.794 248.985 100.608 251.887 106.392C254.79 112.175 258.555 128.422 256.665 130.164C254.774 131.906 238.115 135.486 238.115 135.486C238.115 135.486 240.365 118.809 243.602 111.473Z"
        fill="url(#paint16_linear_13502_193756)"
      />
      <path
        d="M169.268 117.228C169.268 117.228 170.235 124.584 175.268 127.573C180.302 130.563 187.868 139.955 188.181 144.795C188.495 149.634 176.006 149.375 172.577 144.273C169.148 139.171 165.642 127.623 169.268 117.228Z"
        fill="url(#paint17_linear_13502_193756)"
      />
      <path
        d="M180.172 130.322C180.172 130.322 181.504 117.282 182.551 119.399C183.598 121.515 191.047 124.594 193.697 126.442C196.347 128.29 206.172 138.121 206.622 142.565C207.073 147.008 187.349 149.181 187.349 149.181C187.349 149.181 177.742 138.21 180.172 130.322Z"
        fill="url(#paint18_linear_13502_193756)"
      />
      <path
        d="M197.979 125.962C197.979 125.962 194.373 109.375 195.944 110.897C199.523 114.059 203.264 117.011 207.152 119.74C211.187 122.521 215.313 128.232 216.255 133.134C217.198 138.036 219.077 143.07 216.289 143.175C213.5 143.279 199.32 144.635 197.951 139.368C196.583 134.101 197.951 131.274 197.979 125.962Z"
        fill="url(#paint19_linear_13502_193756)"
      />
      <path
        d="M214.619 124.508C214.619 124.508 217.307 109.705 218.034 105.271C218.762 100.837 219.68 110.711 223.453 115.288C227.226 119.866 231.707 129.908 231.847 134.05C231.987 138.191 235.116 150.265 232.683 150.015C230.25 149.766 216.191 148.34 215.336 146.42C214.481 144.5 212.184 130.371 214.619 124.508Z"
        fill="url(#paint20_linear_13502_193756)"
      />
      <path
        d="M227.576 116.053C227.576 116.053 230.903 104.509 232.848 100.265C234.792 96.0213 234.299 101.327 236.003 104.281C238.276 108.589 240.091 113.148 241.414 117.873C242.514 122.105 244.016 128.811 242.373 131.508C240.73 134.205 232.71 147.574 232.71 147.574C232.71 147.574 224.107 145.121 222.657 135.939C221.207 126.757 227.576 116.053 227.576 116.053Z"
        fill="url(#paint21_linear_13502_193756)"
      />
      <path
        d="M229.899 139.973C229.899 139.973 227.065 127.249 244.401 121.78C261.736 116.311 282.772 115.014 290.348 112.589C297.924 110.163 290.003 116.487 286.81 118.637C283.618 120.788 245.112 161.299 242.456 158.914C239.799 156.529 237.298 142.284 229.899 139.973Z"
        fill="url(#paint22_linear_13502_193756)"
      />
      <path
        d="M151.673 144.324C151.673 144.324 157.345 150.825 173.221 146.018C189.097 141.212 227.91 134.519 234.994 140.111C242.078 145.703 244.215 156.67 242.455 158.914C240.694 161.158 224.604 157.141 213.815 158.767C203.025 160.394 167.352 167.664 159.189 157.921C151.027 148.178 151.673 144.324 151.673 144.324Z"
        fill="url(#paint23_linear_13502_193756)"
      />
      <path
        d="M113.076 125.88C113.076 125.88 126.738 157.756 142.103 165.012C157.469 172.268 164.214 174.032 183.365 171.656C202.516 169.279 232.438 154.865 240.326 164.586C248.213 174.308 260.211 198.434 271.996 221.462C283.782 244.491 279.504 270.172 293.802 305.385C305.489 327.884 301.83 335.456 319.066 402.62C324.959 425.535 364.149 471.413 364.149 471.413L373.845 468.631L383.54 465.85C383.54 465.85 345.621 424.623 333.094 376.412C318.531 337.453 310.417 314.004 302.273 297.693C294.128 281.383 287.541 234.693 281.283 222.218C275.025 209.744 250.033 173.102 251.97 164.205C253.908 155.308 255.208 150.046 276.125 145.922C297.043 141.797 305.299 127.264 318.756 117.444C332.213 107.624 338.42 95.6319 346.979 94.0629C355.539 92.4939 352.801 89.6751 346.574 90.448C340.346 91.2209 329.079 99.7622 322.165 102.52C315.252 105.279 303.621 124.293 284.726 127.583C265.83 130.874 249.21 138.308 244.548 153.109C239.886 167.909 236.437 143.263 195.708 151.189C154.979 159.115 138.127 139.823 129.864 129.98C123.68 122.614 113.086 120.803 113.076 125.88Z"
        fill="#7BB190"
      />
      <path
        d="M113.076 125.88C113.076 125.88 126.738 157.756 142.103 165.012C157.469 172.268 164.214 174.032 183.365 171.656C202.516 169.279 232.438 154.865 240.326 164.586C248.213 174.308 260.211 198.434 271.996 221.462C283.782 244.491 279.504 270.172 293.802 305.385C305.489 327.884 301.83 335.456 319.066 402.62C324.959 425.535 364.149 471.413 364.149 471.413L373.845 468.631L383.54 465.85C383.54 465.85 345.621 424.623 333.094 376.412C318.531 337.453 310.417 314.004 302.273 297.693C294.128 281.383 287.541 234.693 281.283 222.218C275.025 209.744 250.033 173.102 251.97 164.205C253.908 155.308 255.208 150.046 276.125 145.922C297.043 141.797 305.299 127.264 318.756 117.444C332.213 107.624 338.42 95.6319 346.979 94.0629C355.539 92.4939 352.801 89.6751 346.574 90.448C340.346 91.2209 329.079 99.7622 322.165 102.52C315.252 105.279 303.621 124.293 284.726 127.583C265.83 130.874 249.21 138.308 244.548 153.109C239.886 167.909 236.437 143.263 195.708 151.189C154.979 159.115 138.127 139.823 129.864 129.98C123.68 122.614 113.086 120.803 113.076 125.88Z"
        fill="url(#paint24_linear_13502_193756)"
      />
      <path
        d="M174.664 224.546C208.293 239.99 223.328 231.582 276.955 285.71C330.583 339.838 366.962 419.158 373.564 431.642C380.166 444.126 338.126 399.398 338.126 399.398C338.126 399.398 269.44 347.341 248.531 303.244C227.622 259.148 141.035 209.101 174.664 224.546Z"
        fill="#7BB190"
      />
      <path
        d="M174.664 224.546C208.293 239.99 223.328 231.582 276.955 285.71C330.583 339.838 366.962 419.158 373.564 431.642C380.166 444.126 338.126 399.398 338.126 399.398C338.126 399.398 269.44 347.341 248.531 303.244C227.622 259.148 141.035 209.101 174.664 224.546Z"
        fill="url(#paint25_linear_13502_193756)"
      />
      <defs>
        <filter
          id="filter0_f_13502_193756"
          x="301.03"
          y="136.092"
          width="63.502"
          height="216.127"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.76672"
            result="effect1_foregroundBlur_13502_193756"
          />
        </filter>
        <linearGradient
          id="paint0_linear_13502_193756"
          x1="351.795"
          y1="262.133"
          x2="328.503"
          y2="241.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13502_193756"
          x1="357.814"
          y1="270.139"
          x2="326.419"
          y2="236.933"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13502_193756"
          x1="394.478"
          y1="185.406"
          x2="376.695"
          y2="348.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#91ADAF" />
          <stop offset="0.71" stopColor="#324849" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_13502_193756"
          x1="394.478"
          y1="185.406"
          x2="376.695"
          y2="348.512"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#C2E0E2" />
          <stop offset="0.71" stopColor="#4F8D92" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_13502_193756"
          x1="403.388"
          y1="272.944"
          x2="393.681"
          y2="243.942"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_13502_193756"
          x1="406.644"
          y1="282.063"
          x2="397.511"
          y2="238.958"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_13502_193756"
          x1="458.986"
          y1="235.113"
          x2="386.841"
          y2="339.818"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#C2E0E2" />
          <stop offset="0.71" stopColor="#4F8D92" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_13502_193756"
          x1="305.173"
          y1="170.496"
          x2="316.715"
          y2="262.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C5658" />
          <stop offset="1" stopColor="#4D7A71" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_13502_193756"
          x1="256.1"
          y1="68.7723"
          x2="261.293"
          y2="118.106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_13502_193756"
          x1="270.401"
          y1="85.3378"
          x2="269.169"
          y2="123.176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_13502_193756"
          x1="216.894"
          y1="74.7746"
          x2="207.96"
          y2="124.074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_13502_193756"
          x1="207.065"
          y1="78.9673"
          x2="201.53"
          y2="129.862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_13502_193756"
          x1="199.972"
          y1="88.7521"
          x2="196.08"
          y2="133.428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_13502_193756"
          x1="186.645"
          y1="97.9289"
          x2="179.461"
          y2="138.521"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_13502_193756"
          x1="257.743"
          y1="91.3695"
          x2="256.054"
          y2="124.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_13502_193756"
          x1="180.032"
          y1="105.207"
          x2="172.691"
          y2="137.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_13502_193756"
          x1="247.97"
          y1="95.15"
          x2="248.045"
          y2="130.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_13502_193756"
          x1="178.652"
          y1="112.967"
          x2="176.45"
          y2="146.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_13502_193756"
          x1="197.101"
          y1="113.46"
          x2="198.906"
          y2="145.794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_13502_193756"
          x1="207.546"
          y1="105.708"
          x2="206.007"
          y2="142.669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_13502_193756"
          x1="221.872"
          y1="100.941"
          x2="215.832"
          y2="147.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DC8CC" />
          <stop offset="1" stopColor="#143E41" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_13502_193756"
          x1="233.964"
          y1="96.513"
          x2="230.999"
          y2="142.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_13502_193756"
          x1="286.664"
          y1="107.978"
          x2="293.675"
          y2="144.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_13502_193756"
          x1="231.842"
          y1="106.957"
          x2="255.645"
          y2="138.758"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75AAAE" />
          <stop offset="0.33778" stopColor="#538386" />
          <stop offset="1" stopColor="#113537" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_13502_193756"
          x1="338.403"
          y1="98.9403"
          x2="221.6"
          y2="255.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#376062" />
          <stop offset="1" stopColor="#608880" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_13502_193756"
          x1="260.794"
          y1="282.107"
          x2="196.801"
          y2="242.761"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A4D4F" />
          <stop offset="1" stopColor="#4F7C73" />
        </linearGradient>
        <clipPath id="clip0_13502_193756">
          <rect
            width="153.665"
            height="182.477"
            fill="white"
            transform="translate(483.814 221.995) rotate(141.59)"
          />
        </clipPath>
        <clipPath id="clip1_13502_193756">
          <rect
            width="264.798"
            height="252.06"
            fill="white"
            transform="matrix(-0.999993 -0.00375399 -0.00375399 0.999993 291.838 0.994019)"
          />
        </clipPath>
        <clipPath id="clip2_13502_193756">
          <rect
            width="264.798"
            height="252.06"
            fill="white"
            transform="matrix(-0.999993 -0.00375399 -0.00375399 0.999993 291.838 0.994019)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const Memo = memo(SvgAskonaBannerFlowerBig);
export default Memo;
