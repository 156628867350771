import { Dispatch, SetStateAction } from 'react';
import { Props } from 'react-select';
import { GroupBase } from 'react-select';
import { AsyncProps } from 'react-select/async';

export type ChangeTypeOfKeys<
  T extends object,
  Keys extends keyof T,
  NewType,
> = {
  [key in keyof T]: key extends Keys ? NewType : T[key];
};

type PartiallyRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

export type Option = {
  label: string;
  value: string;
};

export type ModifiedGroup = {
  label: string;
  options: Option[];
};

export type SelectProps = Props<unknown, boolean, GroupBase<unknown>>;

export type AsyncSelectProps = AsyncProps<unknown, boolean, GroupBase<unknown>>;

export type SelectPropsWithRequiredOption = PartiallyRequired<
  SelectProps,
  'options'
>;

export type AsyncSelectPropsWithRequiredOption = PartiallyRequired<
  AsyncSelectProps,
  'options'
>;

export type ModifiedSelectProps = ChangeTypeOfKeys<
  SelectPropsWithRequiredOption,
  'options',
  Option[] | ModifiedGroup[]
>;

export type ModifiedAsyncSelectProps = ChangeTypeOfKeys<
  AsyncSelectProps,
  'options',
  Option[] | ModifiedGroup[]
>;

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    isGroupSelect?: boolean;
    groups?: ModifiedGroup[];
    currentGroup?: ModifiedGroup;
    setCurrentGroup?: Dispatch<SetStateAction<ModifiedGroup>>;
    error?: string;
    isWithoutArrow?: boolean;
  }
}

export enum AvailabilityRecurringTypes {
  ONCE = 'once',
  EVERY_DAY = 'every_day',
  EVERY_WEEKDAY = 'every_weekday',
  EVERY_MONDAY = 'every_monday',
  EVERY_TUESDAY = 'every_tuesday',
  EVERY_WEDNESDAY = 'every_wednesday',
  EVERY_THURSDAY = 'every_thursday',
  EVERY_FRIDAY = 'every_friday',
  EVERY_SATURDAY = 'every_saturday',
  EVERY_SUNDAY = 'every_sunday',
}
