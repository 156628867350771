import { RootState } from '@app/store';
// import { createSelector } from '@reduxjs/toolkit';
// import { NotificationResponseDtoEventEnum } from '../../../api/generated/api';
// import { NotificationResponseDto } from '@app/api';

export const selectNotifications = (state: RootState) =>
  state.notifications.data;

export const selectUnviewedNotifications = (state: RootState) =>
  state.notifications.notViewedCount;

// const selectItemsByCategory = createSelector(
//   [
//     // Usual first input - extract value from `state`
//     state => state.items,
//     // Take the second arg, `category`, and forward to the output selector
//     (state, category) => category
//   ],
//   // Output selector gets (`items, category)` as args
//   (items, category) => items.filter(item => item.category === category)
// )

export const selectTherapistsNotesCounter = (state: RootState) =>
  state.notifications.therapistsNoteNotificationsCounter;
// export const selectNotificationsByTypeEvent = createSelector(
//   [
//     selectNotifications,
//     (
//       state: NotificationResponseDto[],
//       event: NotificationResponseDtoEventEnum,
//     ) => event,
//   ],
//   (notifications, event) =>
//     notifications.filter((notification) => notification.event === event),
// );
