import { CalendarTabs } from '@app/components/ScheduleCalendar/ScheduleCalendar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

interface InitialState {
  hasAvailabilities: boolean;
  isVisited: boolean;
  lastDate?: string;
  lastTab?: CalendarTabs;
}

const initialState: InitialState = {
  hasAvailabilities: false,
  isVisited: false,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setHasAvailabilities(state, { payload }: PayloadAction<boolean>) {
      state.hasAvailabilities = payload;
    },
    setIsVisited(state, { payload }: PayloadAction<boolean>) {
      state.isVisited = payload;
    },
    setLastDate(state, { payload }: PayloadAction<string>) {
      state.lastDate = payload;
    },
    setLastTab(state, { payload }: PayloadAction<CalendarTabs>) {
      state.lastTab = payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, { payload }) => {
      return {
        ...state,
        ...payload.schedule,
      };
    },
  },
});

export const { actions, reducer } = scheduleSlice;
