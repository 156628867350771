import { CreateRecommendedFilterRequestDto } from '@app/api';
import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import { baseApi } from '../baseApi';

export const clientOnboardingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createOnboarding: builder.mutation<any, any>({
      query: (body) => ({
        url: API_URL.postClientOnboarding,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['users'],
    }),
    createRecommendationFilter: builder.mutation<
      void,
      CreateRecommendedFilterRequestDto
    >({
      query: (body) => ({
        url: API_URL.postClientRecommendationFilter,
        method: HttpMethods.post,
        body,
      }),
      invalidatesTags: ['allTherapists'],
    }),
  }),
});

export const {
  useCreateOnboardingMutation,
  useCreateRecommendationFilterMutation,
} = clientOnboardingApi;
