import { combineReducers } from 'redux';
import * as auth from './ducks/auth';
import * as allTherapistsFilters from './ducks/allTherapistsFilters';
import * as schedule from './ducks/schedule';
import * as notifications from './ducks/notifications';
import * as allTherapistsModalAfterSignUp from './ducks/allTherapistsModalAfterSignUp';
import { scheduleApi } from './api/schedule';
import { therapistProfileApi } from './api/therapistProfile';
import { therapistClientsApi } from './api/therapistClients';
import { patientNotesApi } from './api/patientNotes';
import { bankCardApi } from './api/bankCard';
import { allTherapistsApi } from './api/allTherapists';
import { clientProfileApi } from './api/clientProfile';
import { authApi } from './api/auth';
import { usersApi } from './api/users';
import { clientOnboardingApi } from './api/clientOnboarding';
import { videoCallApi } from './api/videoCall';
import { sessionsApi } from './api/sessions';
import * as submitPromoCodes from './ducks/submitPromoCodes';
import { companyApi } from './api/company';
import { commonApi } from './api/common';
import { sessionPaymentApi } from './api/session-payment';
import { promocodesApi } from './api/promocodes';
import { clientTherapistsApi } from './api/clientTherapists';
import { notificationsApi } from './api/notifications';
import { baseApi } from './api/baseApi';
import { paymentsApi } from './api/payments';
import * as sessionPromocode from './ducks/sessionPromocode';

export const appReducer = combineReducers({
  auth: auth.authReducer,
  allTherapistsFilters: allTherapistsFilters.reducer,
  schedule: schedule.reducer,
  [therapistClientsApi.reducerPath]: therapistClientsApi.reducer,
  [scheduleApi.reducerPath]: scheduleApi.reducer,
  [therapistProfileApi.reducerPath]: therapistProfileApi.reducer,
  [patientNotesApi.reducerPath]: patientNotesApi.reducer,
  [bankCardApi.reducerPath]: bankCardApi.reducer,
  [allTherapistsApi.reducerPath]: allTherapistsApi.reducer,
  [clientProfileApi.reducerPath]: clientProfileApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [clientOnboardingApi.reducerPath]: clientOnboardingApi.reducer,
  [videoCallApi.reducerPath]: videoCallApi.reducer,
  [sessionsApi.reducerPath]: sessionsApi.reducer,
  [sessionPaymentApi.reducerPath]: sessionPaymentApi.reducer,
  [promocodesApi.reducerPath]: promocodesApi.reducer,
  allTherapistsModalAfterSignUp:
    allTherapistsModalAfterSignUp.allTherapistsModalAfterSignUpReducer,
  submitPromoCodes: submitPromoCodes.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [clientTherapistsApi.reducerPath]: clientTherapistsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  notifications: notifications.notificationsReducer,
  sessionPromocode: sessionPromocode.reducer,
});

export const actions = {
  auth: auth.actions,
  allTherapistsFilters: allTherapistsFilters.actions,
  schedule: schedule.actions,
  allTherapistsModalAfterSignUp: allTherapistsModalAfterSignUp.actions,
  submitPromoCodes: submitPromoCodes.actions,
  notifications: notifications.actions,
  sessionPromocode: sessionPromocode.actions,
};

export const selectors = {
  auth: auth.selectors,
  allTherapistsFilters: allTherapistsFilters.selectors,
  schedule: schedule.selectors,
  allTherapistsModalAfterSignUp: allTherapistsModalAfterSignUp.selectors,
  submitPromoCodes: submitPromoCodes.selectors,
  notifications: notifications.selectors,
  sessionPromocode: sessionPromocode.selectors,
};

export const apiMiddleware = [baseApi.middleware];
