import { NotificationsResponseDto } from '@app/api';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as actions from './actions';

interface InitialState extends NotificationsResponseDto {
  therapistsNoteNotificationsCounter: number;
}

const initialState: InitialState = {
  count: 1,
  total: 1,
  page: 1,
  totalPage: 1,
  notViewedCount: 0,
  data: [],
  therapistsNoteNotificationsCounter: 0,
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      actions.setNotifications,
      (state, { payload }: PayloadAction<NotificationsResponseDto>) => {
        return { ...state, ...payload };
      },
    )
    .addCase(actions.addTherapistsNote, (state) => {
      state.therapistsNoteNotificationsCounter += 1;
    });
});
