import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';
const SvgAskonaBannerFlowerSmall: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width="177"
      height="258"
      viewBox="0 0 177 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_13502_193757)">
        <g filter="url(#filter0_f_13502_193757)">
          <path
            d="M118.208 203.453C119.146 200.311 119.417 197.005 119.004 193.748C117.371 181.286 114.058 164.557 114.854 155.162C115.639 145.803 116.952 132.974 113.329 126.966C110.694 122.598 113.919 117.893 112.626 118.19C111.548 118.436 109.729 122.253 110.756 126.584C111.783 130.915 104.865 134.714 102.144 150.45C99.6719 164.747 103.616 185.416 103.73 196.868C103.742 198.02 118.208 203.453 118.208 203.453Z"
            fill="url(#paint0_linear_13502_193757)"
          />
          <path
            d="M118.208 203.453C119.146 200.311 119.417 197.005 119.004 193.748C117.371 181.286 114.058 164.557 114.854 155.162C115.639 145.803 116.952 132.974 113.329 126.966C110.694 122.598 113.919 117.893 112.626 118.19C111.548 118.436 109.729 122.253 110.756 126.584C111.783 130.915 104.865 134.714 102.144 150.45C99.6719 164.747 103.616 185.416 103.73 196.868C103.742 198.02 118.208 203.453 118.208 203.453Z"
            fill="url(#paint1_linear_13502_193757)"
          />
          <path
            d="M118.208 203.453C119.146 200.311 119.417 197.005 119.004 193.748C117.371 181.286 114.058 164.557 114.854 155.162C115.639 145.803 116.952 132.974 113.329 126.966C110.694 122.598 113.919 117.893 112.626 118.19C111.548 118.436 109.729 122.253 110.756 126.584C111.783 130.915 104.865 134.714 102.144 150.45C99.6719 164.747 103.616 185.416 103.73 196.868C103.742 198.02 118.208 203.453 118.208 203.453Z"
            fill="url(#paint2_linear_13502_193757)"
          />
          <path
            d="M118.208 203.453C119.146 200.311 119.417 197.005 119.004 193.748C117.371 181.286 114.058 164.557 114.854 155.162C115.639 145.803 116.952 132.974 113.329 126.966C110.694 122.598 113.919 117.893 112.626 118.19C111.548 118.436 109.729 122.253 110.756 126.584C111.783 130.915 104.865 134.714 102.144 150.45C99.6719 164.747 103.616 185.416 103.73 196.868C103.742 198.02 118.208 203.453 118.208 203.453Z"
            fill="url(#paint3_linear_13502_193757)"
          />
        </g>
        <path
          d="M132.53 200.022C136.475 187.587 145.171 150.879 146.691 148.621C148.449 146.01 147.861 141.649 147.632 138.819C147.485 137.013 148.064 136.006 148.173 131.351C148.195 130.392 146.843 135.349 144.101 137.377C141.359 139.404 138.062 139.869 134.025 146.862C131.017 152.072 130.048 150.245 126.442 157.85C122.835 165.454 118.999 173.279 117.896 179.993C117.688 181.268 117.556 182.555 117.501 183.846L132.53 200.022Z"
          fill="url(#paint4_linear_13502_193757)"
        />
        <path
          d="M132.53 200.022C136.475 187.587 145.171 150.879 146.691 148.621C148.449 146.01 147.861 141.649 147.632 138.819C147.485 137.013 148.064 136.006 148.173 131.351C148.195 130.392 146.843 135.349 144.101 137.377C141.359 139.404 138.062 139.869 134.025 146.862C131.017 152.072 130.048 150.245 126.442 157.85C122.835 165.454 118.999 173.279 117.896 179.993C117.688 181.268 117.556 182.555 117.501 183.846L132.53 200.022Z"
          fill="url(#paint5_linear_13502_193757)"
        />
        <path
          d="M132.53 200.022C136.475 187.587 145.171 150.879 146.691 148.621C148.449 146.01 147.861 141.649 147.632 138.819C147.485 137.013 148.064 136.006 148.173 131.351C148.195 130.392 146.843 135.349 144.101 137.377C141.359 139.404 138.062 139.869 134.025 146.862C131.017 152.072 130.048 150.245 126.442 157.85C122.835 165.454 118.999 173.279 117.896 179.993C117.688 181.268 117.556 182.555 117.501 183.846L132.53 200.022Z"
          fill="url(#paint6_linear_13502_193757)"
        />
      </g>
      <path
        d="M108.362 233.81C110.08 222.335 114.556 219.712 110.153 196.584C105.751 173.456 92.4513 150.791 90.5002 146.991C88.5492 143.191 92.5559 161.697 92.5559 161.697C92.5559 161.697 94.013 188.493 101.296 201.275C108.578 214.056 106.644 245.285 108.362 233.81Z"
        fill="#7BB190"
      />
      <path
        d="M108.362 233.81C110.08 222.335 114.556 219.712 110.153 196.584C105.751 173.456 92.4513 150.791 90.5002 146.991C88.5492 143.191 92.5559 161.697 92.5559 161.697C92.5559 161.697 94.013 188.493 101.296 201.275C108.578 214.056 106.644 245.285 108.362 233.81Z"
        fill="url(#paint7_linear_13502_193757)"
      />
      <path
        d="M44.0842 157.452C59.1393 162.001 64.9804 157.587 90.7018 177.26C116.423 196.932 136.347 228.213 139.851 233.085C143.356 237.957 123.059 221.569 123.059 221.569C123.059 221.569 91.118 203.643 79.7537 186.293C68.3894 168.943 29.0291 152.903 44.0842 157.452Z"
        fill="#7BB190"
      />
      <path
        d="M44.0842 157.452C59.1393 162.001 64.9804 157.587 90.7018 177.26C116.423 196.932 136.347 228.213 139.851 233.085C143.356 237.957 123.059 221.569 123.059 221.569C123.059 221.569 91.118 203.643 79.7537 186.293C68.3894 168.943 29.0291 152.903 44.0842 157.452Z"
        fill="url(#paint8_linear_13502_193757)"
      />
      <defs>
        <filter
          id="filter0_f_13502_193757"
          x="96.2366"
          y="113.082"
          width="28.0494"
          height="95.4653"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2.54721"
            result="effect1_foregroundBlur_13502_193757"
          />
        </filter>
        <linearGradient
          id="paint0_linear_13502_193757"
          x1="118.66"
          y1="168.756"
          x2="108.372"
          y2="159.742"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13502_193757"
          x1="121.319"
          y1="172.292"
          x2="107.451"
          y2="157.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13502_193757"
          x1="137.513"
          y1="134.865"
          x2="129.658"
          y2="206.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#91ADAF" />
          <stop offset="0.71" stopColor="#324849" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_13502_193757"
          x1="137.513"
          y1="134.865"
          x2="129.658"
          y2="206.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#C2E0E2" />
          <stop offset="0.71" stopColor="#4F8D92" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_13502_193757"
          x1="141.449"
          y1="173.531"
          x2="137.161"
          y2="160.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#697238" />
          <stop offset="0.71" stopColor="#33361C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_13502_193757"
          x1="142.887"
          y1="177.559"
          x2="138.853"
          y2="158.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#4F8A8E" />
          <stop offset="0.71" stopColor="#203839" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_13502_193757"
          x1="166.007"
          y1="156.821"
          x2="134.14"
          y2="203.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.207316" stopColor="#C2E0E2" />
          <stop offset="0.71" stopColor="#4F8D92" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_13502_193757"
          x1="96.2087"
          y1="146.067"
          x2="103.757"
          y2="186.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C5658" />
          <stop offset="1" stopColor="#4D7A71" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_13502_193757"
          x1="83.6887"
          y1="176.682"
          x2="54.4621"
          y2="163.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A4D4F" />
          <stop offset="1" stopColor="#4F7C73" />
        </linearGradient>
        <clipPath id="clip0_13502_193757">
          <rect
            width="86.8929"
            height="80.6018"
            fill="white"
            transform="translate(176.974 151.027) rotate(141.59)"
          />
        </clipPath>
        <clipPath id="clip1_13502_193757">
          <rect
            width="170.964"
            height="162.739"
            fill="white"
            transform="matrix(-0.999993 -0.00375399 -0.00375399 0.999993 171.573 0.641846)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const Memo = memo(SvgAskonaBannerFlowerSmall);
export default Memo;
