import {
  TherapistTherapiesInfoResponseDtoSpecialisationEnum,
  TherapistWorkingInfoResponseDtoLanguagesEnum,
  TherapistWorkingInfoResponseDtoWorkWithReligionsEnum,
} from '@app/api';
import { AvailabilityRecurringTypes } from '@app/ui/components/Dropdown/types';
import { makeFriendlyDayObj } from '@app/utils/makeFriendlyDayObj';

export const VARS = {
  today: makeFriendlyDayObj(new Date()),
  timezoneOffset: new Date().getTimezoneOffset() / 60,
  everyWeekDayText: [
    'Каждый понедельник',
    'Каждый вторник',
    'Каждую среду',
    'Каждый четверг',
    'Каждую пятницу',
    'Каждую субботу',
    'Каждое воскресенье',
  ],
  everyWeekDayTextMapper: [
    AvailabilityRecurringTypes.EVERY_MONDAY,
    AvailabilityRecurringTypes.EVERY_TUESDAY,
    AvailabilityRecurringTypes.EVERY_WEDNESDAY,
    AvailabilityRecurringTypes.EVERY_THURSDAY,
    AvailabilityRecurringTypes.EVERY_FRIDAY,
    AvailabilityRecurringTypes.EVERY_SATURDAY,
    AvailabilityRecurringTypes.EVERY_SUNDAY,
  ],
  russianTranslateLanguage: {
    [TherapistWorkingInfoResponseDtoLanguagesEnum.English]: 'Английский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Russian]: 'Русский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Kazakh]: 'Казахский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.German]: 'Немецкий',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Tatar]: 'Татарский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Armenian]: 'Армянский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.French]: 'Французский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Spanish]: 'Испанский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Italian]: 'Итальянский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Chinese]: 'Китайский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Arab]: 'Арабский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Azerbaijani]:
      'Азербайджанский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Georgian]: 'Грузинский',
    [TherapistWorkingInfoResponseDtoLanguagesEnum.Uzbek]: 'Узбекский',
  },
  russianTranslateReligions: {
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Christianity]:
      'Христианство',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Islamism]: 'Ислам',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Judaism]: 'Иудаизм',
    [TherapistWorkingInfoResponseDtoWorkWithReligionsEnum.Buddhism]: 'Буддизм',
  },
  russianTranslateeSpecializations: {
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychologist]:
      'Психолог',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychotherapist]:
      'Психотерапевт',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Psychoanalyst]:
      'Психиатр',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Coach]: 'Коуч',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.Supervisor]:
      'Супервизор',
    [TherapistTherapiesInfoResponseDtoSpecialisationEnum.ClinicalPsychologist]:
      'Клинический психолог',
  },
  isServer: typeof window === 'undefined',
  avatarMaxSizeInBytes: 5000000,
  videoMaxSizInBytes: 100000000,
  minPriceForTherapist: 2000,
  maxPriceForTherapist: 100000,
  defaultAvatar:
    'https://storage.yandexcloud.net/staging-pogovorim.ru/common/avatars/therapist_1.jpeg',
  defaultTotalItemCount: 10,
  defaultTotalSessionItemCount: 5,
  fileNameStatistic: 'PO_statistic.csv',
  fileNameTemplate: 'PO_template.csv',
  months: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ],
  availabilityRecurringLocalizedTypes: {
    [AvailabilityRecurringTypes.EVERY_DAY]: 'каждый день',
    [AvailabilityRecurringTypes.EVERY_WEEKDAY]: 'каждый будний день',
    [AvailabilityRecurringTypes.EVERY_MONDAY]: 'каждый понедельник',
    [AvailabilityRecurringTypes.EVERY_TUESDAY]: 'каждый вторник',
    [AvailabilityRecurringTypes.EVERY_WEDNESDAY]: 'каждую среду',
    [AvailabilityRecurringTypes.EVERY_THURSDAY]: 'каждый четверг',
    [AvailabilityRecurringTypes.EVERY_FRIDAY]: 'каждую пятницу',
    [AvailabilityRecurringTypes.EVERY_SATURDAY]: 'кажду субботу',
    [AvailabilityRecurringTypes.EVERY_SUNDAY]: 'каждое воскресенье',
  },
  appBaseUrl: process.env.NEXT_PUBLIC_APP_BASE_URL || 'localhost',
  approvedTherapistLabel: 'Поговорим Плюс',
  approvedTherapistLabelDescription:
    'Специалисты, соответствующие максимальным требованиям к квалификации, которые прошли тестовые сессии под надзором экспертов платформы Поговорим онлайн',
  minSessionPrice: 2500,
  maxSessionPrice: 20000,
  alphaTestCode: '123qwerty123',
  domain: '.pogovorim.online',
};
