import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFilter } from '@app/types';
import {
  DiseaseGroupResponseDto,
  TherapistSearchRequestDto,
  TherapistSearchRequestDtoOrderEnum,
  TherapistSearchRequestDtoOrderTermsEnum,
  TherapyMethodResponseDto,
} from '@app/api';
import { AllTherapistsTab } from '@app/pageComponents/AllTherapistsPage/AllTherapists';

interface InitialState {
  sorting: {
    order?: TherapistSearchRequestDtoOrderEnum;
    orderTerms?: TherapistSearchRequestDtoOrderTermsEnum;
  };
  activeTab?: AllTherapistsTab;
  selectedFilters?: IFilter[];
  selectedFiltersValues?: any;
  selectedTherapyMethods?: any;
  queryFilters?: Partial<TherapistSearchRequestDto>;
  filters?: IFilter[];
  diseasesGroups?: DiseaseGroupResponseDto[];
  therapyMethods?: TherapyMethodResponseDto[];
  searchValue?: string;
}

const initialState: InitialState = {
  sorting: {},
};

const allTherapistsFiltersSlice = createSlice({
  name: 'allTherapistsFilters',
  initialState,
  reducers: {
    setSelectedFilters(
      state,
      {
        payload: { selectedFilters },
      }: PayloadAction<{ selectedFilters: IFilter[] }>,
    ) {
      state.selectedFilters = selectedFilters;
    },
    setSelectedFiltersValues(
      state,
      {
        payload: { selectedFiltersValues },
      }: PayloadAction<{ selectedFiltersValues: any }>,
    ) {
      state.selectedFiltersValues = selectedFiltersValues;
    },
    resetSelectedFiltersValues(state) {
      state.selectedFiltersValues = {};
    },
    resetSelectedFilters() {
      return initialState;
    },
    setQueryFilters(
      state,
      {
        payload: { queryFilters },
      }: PayloadAction<{ queryFilters: Partial<TherapistSearchRequestDto> }>,
    ) {
      state.queryFilters = queryFilters;
    },
    resetQueryFilters() {
      return initialState;
    },
    setFilters(state, action: PayloadAction<{ filters: IFilter[] }>) {
      state.filters = action.payload.filters;
    },
    setDiseasesGroups(
      state,
      action: PayloadAction<{ diseasesGroups: DiseaseGroupResponseDto[] }>,
    ) {
      state.diseasesGroups = action.payload.diseasesGroups;
    },
    setTherapyMethods(
      state,
      action: PayloadAction<{ therapyMethods: TherapyMethodResponseDto[] }>,
    ) {
      state.therapyMethods = action.payload.therapyMethods;
    },
    setSortingParams(
      state,
      action: PayloadAction<{
        sortingPayload: {
          order: TherapistSearchRequestDtoOrderEnum;
          orderTerms: TherapistSearchRequestDtoOrderTermsEnum;
        };
      }>,
    ) {
      state.sorting = action.payload.sortingPayload;
    },
    setActiveTab(
      state,
      action: PayloadAction<{ tabPayload: AllTherapistsTab }>,
    ) {
      state.activeTab = action.payload.tabPayload;
    },

    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
  },
});

export const { actions, reducer } = allTherapistsFiltersSlice;
