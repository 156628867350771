import {
  PaymentsListResponseDto,
  PaymentStatisticsResponseDto,
  TherapistSearchRequestDtoOrderEnum,
} from '@app/api';
import { API_URL } from '@app/constants';
import { HttpMethods, PaymentOrderTerms } from '@app/types';
import { baseApi } from '../baseApi';

interface GetPaymentsParams {
  limit?: number;
  page: number;
  order?: TherapistSearchRequestDtoOrderEnum;
  orderTerms?: PaymentOrderTerms;
  isSender: boolean;
}

interface GetPaymentsStatisticsParams {
  year: number;
}

export const paymentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query<PaymentsListResponseDto, GetPaymentsParams>({
      query: ({ limit = 9, ...params }) => ({
        url: API_URL.getPayments,
        method: HttpMethods.get,
        params: { limit, ...params },
      }),
    }),
    getPaymentsStatistics: builder.query<
      PaymentStatisticsResponseDto,
      GetPaymentsStatisticsParams
    >({
      query: (params) => ({
        url: API_URL.getPaymentStatistic,
        method: HttpMethods.get,
        params,
      }),
    }),
  }),
});

export const { useGetPaymentsQuery, useGetPaymentsStatisticsQuery } =
  paymentsApi;
