import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgInfoCircle2: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18.334c4.584 0 8.334-3.75 8.334-8.334 0-4.583-3.75-8.333-8.334-8.333-4.583 0-8.333 3.75-8.333 8.333 0 4.584 3.75 8.334 8.333 8.334ZM10 6.667v4.167"
        stroke={color}
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.995 13.333h.008"
        stroke={color}
        strokeWidth={1.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Memo = memo(SvgInfoCircle2);
export default Memo;
