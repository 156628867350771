import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgVideoEnabled: FC<IconComponent> = ({
  color = COLORS.grayscale600,
  ...props
}) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.625 5.141c-.342-.183-1.059-.375-2.034.309l-1.225.866c-.091-2.591-1.216-3.608-3.95-3.608h-5c-2.85 0-3.958 1.108-3.958 3.958v6.667c0 1.917 1.042 3.958 3.958 3.958h5c2.734 0 3.859-1.016 3.95-3.608l1.225.867c.517.366.967.483 1.325.483.309 0 .55-.092.709-.175.341-.175.916-.65.916-1.842V6.983c0-1.192-.575-1.667-.916-1.842ZM9.166 9.483A1.57 1.57 0 0 1 7.6 7.916 1.57 1.57 0 0 1 9.166 6.35c.859 0 1.567.7 1.567 1.566a1.57 1.57 0 0 1-1.567 1.567Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgVideoEnabled);
export default Memo;
