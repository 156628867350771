import { COLORS, TYPOGRAPHY } from '@app/constants';
import { Close } from '@app/ui/iconComponents';
import { FC, ReactNode } from 'react';
import { ToastContentProps, TypeOptions } from 'react-toastify';
import styled from 'styled-components';

const Toast: FC<
  ToastContentProps<{
    title?: string;
    content?: string;
    icon?: ReactNode;
  }>
> = ({ toastProps, data }) => {
  const { closeToast, type } = toastProps;

  return (
    <Root role="log" $type={type}>
      <Wrapper>
        {data?.icon && <IconWrapper>{data.icon}</IconWrapper>}
        <div>
          {data?.title?.length && (
            <Title $isText={!!data?.content}>{data?.title}</Title>
          )}
          {data?.content?.length && <Text>{data?.content}</Text>}
          <CloseBtn onClick={closeToast}>
            <Close />
          </CloseBtn>
        </div>
      </Wrapper>
    </Root>
  );
};

export default Toast;

const Root = styled.div<{ $type: TypeOptions }>`
  position: relative;
  border-radius: 16px;
  padding: 16px 44px 16px 20px;
  width: 100%;
  max-width: 460px;
  background-color: ${({ $type }) => {
    switch ($type) {
      case 'default':
        return COLORS.primary50;
      case 'success':
        return COLORS.lightGreen;
      case 'error':
        return COLORS.systemErrorExtraLightRed;
      default:
        return COLORS.lightGreen;
    }
  }};
`;

const Wrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  align-items: flex-start;
  margin-right: 18px;
`;

const Title = styled.h5<{ $isText: boolean }>`
  ${TYPOGRAPHY.headline1Semibold18};
  color: ${COLORS.grayscaleBlack};
  margin: ${({ $isText }) => ($isText ? '0 0 8px' : '0')};
  max-width: 32ch;
  word-break: break-word;
`;

const Text = styled.p`
  ${TYPOGRAPHY.body1Regular14};
  color: ${COLORS.grayscale700};
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
`;
