import { RootState } from '@app/store';

export const selectHasAvailabilities = (state: RootState) =>
  state.schedule.hasAvailabilities;

export const selectIsVisited = (state: RootState) => state.schedule.isVisited;

export const selectLastDate = (state: RootState) => state.schedule.lastDate;

export const selectLastTab = (state: RootState) => state.schedule.lastTab;
