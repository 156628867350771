import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import { PromocodeResponseDto } from '@app/api';
import { baseApi } from '../baseApi';

interface CheckPromocodeParams {
  code: string;
}

export const promocodesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkPromocode: builder.mutation<
      PromocodeResponseDto,
      CheckPromocodeParams
    >({
      query: ({ code }) => ({
        url: API_URL.checkPromocode,
        method: HttpMethods.post,
        params: { code },
      }),
    }),
  }),
});

export const { useCheckPromocodeMutation } = promocodesApi;
