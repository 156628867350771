import { FC, memo } from 'react';
import { IconComponent } from '@app/types';

const BannerCircles: FC<IconComponent> = (props) => {
  return (
    <svg
      width="265"
      height="253"
      viewBox="0 0 265 253"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.4" clipPath="url(#clip0_42_577)">
        <path
          d="M143.64 -0.334509L143.682 -0.334352C154.793 -0.277201 163.793 8.60275 163.736 19.4609C163.675 30.2726 154.593 39.0487 143.498 39.007C132.366 38.9446 123.334 30.0646 123.391 19.2219C123.452 8.39983 132.539 -0.376181 143.64 -0.334509Z"
          fill="white"
          fillOpacity="0.19"
        />
        <path
          d="M142.856 208.637L142.898 208.637C154.01 208.694 163.009 217.569 162.953 228.427C162.891 239.244 153.809 248.02 142.714 247.978C131.582 247.916 122.55 239.041 122.607 228.188C122.668 217.372 131.756 208.595 142.856 208.637Z"
          fill="white"
          fillOpacity="0.29"
        />
        <path
          d="M67.4316 24.0087L67.4951 24.0089C82.4529 24.0908 94.5694 36.0393 94.4882 50.6539C94.4071 65.2375 82.1852 77.0483 67.2436 76.9922C52.2378 76.9104 40.0896 64.9618 40.1661 50.3471C40.2417 35.7684 52.4846 23.9525 67.4316 24.0087Z"
          fill="white"
          fillOpacity="0.19"
        />
        <path
          d="M66.8829 170.125L66.9464 170.126C81.9042 170.203 94.0207 182.161 93.9395 196.776C93.8584 211.354 81.6365 223.165 66.6949 223.109C51.6891 223.033 39.5409 211.073 39.6174 196.464C39.693 181.885 51.9359 170.069 66.8829 170.125Z"
          fill="white"
          fillOpacity="0.35"
        />
        <path
          d="M229.483 150.922C229.459 157.339 234.768 162.562 241.352 162.587C247.937 162.611 253.296 157.429 253.32 151.012C253.344 144.59 248.024 139.367 241.44 139.342C234.855 139.317 229.507 144.5 229.483 150.922Z"
          fill="white"
          fillOpacity="0.19"
        />
        <path
          d="M205.765 51.0258C205.735 58.8632 212.227 65.2481 220.268 65.2783C228.314 65.3085 234.859 58.9726 234.889 51.1352C234.918 43.2926 228.421 36.9128 220.375 36.8826C212.334 36.8524 205.794 43.1833 205.765 51.0258Z"
          fill="white"
          fillOpacity="0.19"
        />
        <path
          d="M197.951 198.018C197.922 205.855 204.414 212.24 212.455 212.271C220.501 212.301 227.046 205.965 227.075 198.127C227.105 190.29 220.607 183.905 212.561 183.875C204.52 183.844 197.981 190.18 197.951 198.018Z"
          fill="white"
          fillOpacity="0.19"
        />
        <path
          d="M36.3389 88.1314L36.4186 88.1316C56.0818 88.2417 72.0042 103.956 71.9112 123.166C71.7975 142.324 55.7315 157.862 36.0882 157.788C16.3608 157.673 0.38579 141.959 0.484227 122.748C0.593325 103.584 16.6802 88.0576 36.3389 88.1314Z"
          fill="white"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <clipPath id="clip0_42_577">
          <rect
            width="264.798"
            height="252.06"
            fill="white"
            transform="matrix(-0.999993 -0.00375399 -0.00375399 0.999993 264.743 -0.00585938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const Memo = memo(BannerCircles);
export default Memo;
