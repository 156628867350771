import { FC, memo } from 'react';
import { IconComponent } from '@app/types';
import { COLORS } from '@app/constants';

const SvgVerified: FC<IconComponent> = ({
  color = COLORS.grayscaleWhite,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    >
      <path
        d="M16.5654 2.45153L8.69971 0L0.833994 2.45153V9.03253C0.831588 10.4851 1.29045 11.9056 2.15241 13.114C3.01438 14.3225 4.24064 15.2644 5.67578 15.8205L8.69971 17L11.7236 15.8205C13.1588 15.2644 14.3851 14.3225 15.247 13.114C16.109 11.9056 16.5678 10.4851 16.5654 9.03253V2.45153ZM14.8175 9.03253C14.8196 10.162 14.463 11.2667 13.793 12.2065C13.1229 13.1463 12.1695 13.8789 11.0536 14.3115L8.69971 15.2295L6.34582 14.3115C5.22994 13.8789 4.27654 13.1463 3.60647 12.2065C2.93639 11.2667 2.57982 10.162 2.58193 9.03253V3.62826L8.69971 1.72152L14.8175 3.62826V9.03253Z"
        fill={color}
      />
      <path
        d="M6.11216 7.09276L4.87695 8.2477L7.49886 10.6992C7.6626 10.8525 7.88475 10.9387 8.11646 10.9389C8.23363 10.9371 8.34919 10.9132 8.45629 10.8688C8.56339 10.8243 8.65984 10.7602 8.73989 10.6802L13.1097 6.32189L11.8337 5.20508L8.09607 8.94775L6.11216 7.09276Z"
        fill={color}
      />
    </svg>
  );
};

const Memo = memo(SvgVerified);
export default Memo;
